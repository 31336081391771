import React, {FC} from 'react'
import "./TabBar.scss";
import {Input1} from "@components/elements";
import {useTranslation} from "react-i18next";

const TabBarBottom: FC = () => {
    const {t} = useTranslation('global');
    const onChange = () => {

    }
    return (
        <div className="bottom">
            <div className="card-text col-lg-3">
                <div>{t('h1-text/footer')}</div>
                <div className="fw-bold">{t('h2-text/footer')}</div>
            </div>
            <div className="form col-lg-3">
                <Input1 name="email"
                        type="text"
                        label=""
                        value={""}
                        placeholder={t('h3-text/footer')}
                        onChange={onChange}
                        required={true}
                        error={""}/>
                <Input1 name="phone"
                        type="text"
                        label=""
                        value={""}
                        placeholder={t('h4-text/footer')}
                        onChange={onChange}
                        required={true}
                        error={""}/>
                <a href="/#"
                   className="uk-visible@ header__bottom__btn uk-button uk-border-pill">{t('btn/send')}</a>
            </div>
        </div>
    )
}

export default React.memo(TabBarBottom);
