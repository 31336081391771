import {useEffect, useState} from "react";
import {IItemList} from "@types";
import axiosInstance from "@utils/axiosInstance";

const useFetch = (language: string) => {
    const [items, setItems] = useState<IItemList[]>([])
    useEffect(() => {
        axiosInstance.get('/item/getAll?lang=' + language).then(response => {
            if (response.data.code === "200") {
                setItems(response.data.data)
            }
        })
    }, [language]);

    return [items];
}
export default useFetch;
