import {createReducer} from "@utils/reducer";
import {IAppAction, ILoginState} from "@types";
import immutable from "seamless-immutable";
import {LOGIN_FAILURE, LOGIN_REQUEST, LOGIN_SUCCESS} from "@redux/actions/login.actions";
import {LOGOUT} from "@redux/actions/logout.actions";

const initialState = immutable<ILoginState>({
    accessToken: "",
    isLogin: false,
    user: null,
    loading: false,
    error: "",
});

export const loginReducer = createReducer<IAppAction, typeof initialState>(initialState, {
    [LOGIN_REQUEST]: (state) => {
        if (!immutable.isImmutable(state)) {
            state = immutable(state);
        }
        return state
            .setIn(['loading'], true)
            .setIn(['error'], "");
    },

    [LOGIN_SUCCESS]: (state, action) => {
        if (!immutable.isImmutable(state)) {
            state = immutable(state);
        }
        return state
            .setIn(['isLogin'], true)
            .setIn(['accessToken'], action.payload.accessToken)
            .setIn(['loading'], false)
            .setIn(['user'], action.payload)
            .setIn(['error'], "");
    },

    [LOGIN_FAILURE]: (state, action) => {
        if (!immutable.isImmutable(state)) {
            state = immutable(state);
        }
        return state
            .setIn(['loading'], false)
            .setIn(['error'], action.payload);
    },
    [LOGOUT]: () => {
        return initialState;
    },
});
