import React from 'react';
import "./ProductPageView.scss";
import {AppFooter1} from "@components/shared";
import {Tabs} from "antd";
import {map} from 'lodash';
import {number} from '@utils/constants';
import {useTranslation} from "react-i18next";
import useFetch from "../../hooks/useFetch";

const ProductPageView = () => {
    const {t} = useTranslation('global');
    const language = localStorage.getItem("lang") ?? 'en';
    const [items] = useFetch(language);

    return (
        <>
            <div id="product" className="product">
                <div className="bg-desktop">
                    <div className="service-banner d-flex align-items-center justify-content-around">
                        <div className="banner-text w-auto">
                            <h1 className="text-3xl lg:text-4xl xl:text-5xl font-medium text-white-600">{t('product/header')}</h1>
                        </div>
                    </div>

                </div>

                <div className="container">
                    <div className="body-header flex justify-center">
                        <div className="col-12">
                            <Tabs
                                defaultActiveKey="0"
                                tabPosition={"top"}
                                style={{height: "auto"}}
                                items={map(items, (item, i) => {
                                    const id = String(i);
                                    return {
                                        label: item.categoryName,
                                        key: id,
                                        children: <>
                                            <hr style={{
                                                marginLeft: "-33.3333%",
                                                marginRight: "-33.3333%",
                                                marginBottom: "3rem"
                                            }}/>
                                            <div className="list-category content-desc-nick hide">
                                                {map(item.itemList, (product, index) => (
                                                    <div key={index} className="c-px-8 item-category c-mb-12 c-px-lg-6">
                                                        <div className="card">
                                                            <div className="card-body c-p-16 c-p-lg-12 scale-thumb">
                                                                <a>
                                                                    <div className="card-thumb c-mb-8">
                                                                        <img
                                                                            className="card-thumb-image ls-is-cached lazyloaded"
                                                                            src={`https://vdsagency.com/static/media/${product.image}`}
                                                                            alt="anh"/>
                                                                        {product.labelCode &&
                                                                        <div className="card-label">
                                                                            <span
                                                                                className={`label-text ${product.labelCode}`}>{product.labelName}</span>

                                                                        </div>
                                                                        }
                                                                    </div>
                                                                    <div className="card-attr">
                                                                        <div
                                                                            className="text-title fw-700 text-limit limit-1">
                                                                            {product.name}
                                                                        </div>
                                                                        <div className="info-attr">{product.description}
                                                                        </div>
                                                                        <div className="price">
                                                                            <div
                                                                                className="title-price w-100 c-mt-8">Đơn
                                                                                giá
                                                                            </div>
                                                                            <div
                                                                                className="price-current"
                                                                                style={{color: product.oldPrice === 0 ? "black" : ""}}>{number(product.newPrice)}VNĐ
                                                                            </div>
                                                                            {product.oldPrice !== 0 &&
                                                                            <div
                                                                                className="price-old c-ml-8">{number(product.oldPrice)}VNĐ
                                                                            </div>
                                                                            }

                                                                        </div>
                                                                    </div>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}

                                            </div>
                                        </>,
                                    };
                                })}
                            />
                        </div>
                    </div>
                </div>
                <AppFooter1/>
            </div>
        </>
    );
};

export default React.memo(ProductPageView);
