import React from "react";
import "./AppFooter.scss";
import {SupportOnline} from "../index";

const AppFooter1 = () => {


    return (
        <>
            <footer style={{background: "#262626", color: "white"}}>
                Copyright © 2023 VAN DIGITAL Sucess. All rights reserved.
            </footer>
            <SupportOnline/>
            {/*<MessengerFbChat/>*/}
        </>
    )
}
export default React.memo(AppFooter1);
