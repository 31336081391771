import React, {useEffect, useState} from 'react';
import "./Section.scss";

const Section6 = ({img, img1, className}: any) => {
    const [height, setHeight] = useState<number>(0);

    useEffect(() => {
        setTimeout(() => {
            const section1 = document.getElementById('section-1');
            const sectionVds = document.getElementById('section-vds');
            if (window.innerWidth < 992) {
                if (sectionVds) {
                    setHeight(sectionVds.clientWidth / 2 * 1.65 / 1.16 - 36.4)
                }
            } else {
                if (section1) {
                    setHeight(section1.clientHeight);
                }
            }
        }, 300)


        const handleResize = () => {

            setTimeout(() => {
                const section1 = document.getElementById('section-1');
                const sectionVds = document.getElementById('section-vds');
                if (window.innerWidth < 992) {
                    if (sectionVds) {
                        setHeight(sectionVds.clientWidth / 2 * 1.65 / 1.16 - 36.4)
                    }
                } else {
                    if (section1) {
                        setHeight(section1.clientHeight);
                    }
                }
            }, 300)
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [])
    return (
        <div className={`section-image-9 d-flex ${className}`}>
            <section className="image-canvas-2"
                     style={{height: height * 0.8, width: height * 0.8 / 1.75}}>
                <img style={{
                    borderRadius: "120px",
                    zIndex: 9999,
                    position: "relative",
                    height: "100%",
                }} src={img} alt="Partition 1"/>
            </section>
            <section className="image-canvas-2"
                     style={{height: height * 0.8, width: height * 0.8 * 1.4, marginLeft: "1px"}}>
                <img style={{
                    borderRadius: "28px",
                    zIndex: 9999,
                    position: "relative",
                    height: "100%",
                }} src={img1} alt="Partition 1"/>
            </section>
        </div>
    );
};

export default React.memo(Section6);
