export default {
    'language/header': 'Languages',
    'about/header': 'About',
    'service/header': 'Service',
    'product/header': 'Affiliate Products',
    'contact/header': 'Contact Us',
    'h1/home': 'Who are you?',
    'h1-text/home': 'VAN DIGITAL is a comprehensive agency model offering various communication solutions to enterprises across diverse fields in the Thai market.',
    'h2-text/home': 'Our solutions include',
    'hot-combo/home': 'Hot combo in month',
    'hot-combo-h1/home': 'Buy 1 get 3',
    'hot-combo-h2/home': 'Maximize your revenue',
    'hot-combo-h3/home': 'Click here for details',

    'section0-modal-h1/home': 'For customers using Facebook Ads services with budgets ranging from:',
    'section0-modal-h1-text1/home': '50.000 THB',
    'section0-modal-h1-text2/home': 'Receive immediately: management consulting package + seeding package (200 likes/post + 30 comments/post x 15 posts/month)',
    'section0-modal-h1-text3/home': '90.000 THB',
    'section0-modal-h1-text4/home': 'Receive immediately: management consulting package + seeding package (advanced seeding script + 350 likes/post + 50 comments/post x 30 posts/month) + 5,000 thb voucher for content production',
    'section0-modal-h1-text5/home': '150.000 THB',
    'section0-modal-h1-text6/home': 'Receive immediately: management consulting package + 5,000 thb voucher for seeding + 5,000 thb voucher for content production',
    'section0-modal-h1-text7/home': '600.000 THB',
    'section0-modal-h1-text8/home': 'Receive immediately: management consulting package + exclusive super seeding package + 5,000 thb voucher for content production',
    'section0-modal-h2/home': 'For customers using Tiktok Ads services with budgets ranging from:',
    'section0-modal-h2-text1/home': '85.000 THB',
    'section0-modal-h2-text2/home': 'Receive immediately: management consulting package + 15% voucher for image production + seeding package (200 likes/post + 30 comments/post x 15 posts/month)',

    'section1-text/home': 'Explore market opportunities, build a sustainable brand, and boost revenue with VAN DIGITAL\'s "Communication Planning Map".',
    'section2-text/home': 'Diversify the stories you want to communicate to customers through a range of image and video formats, consulted and executed with expertise by VAN DIGITAL.',
    'section3-text/home': 'Reach audiences across multiple platforms with standardized and optimized processes, minimizing costs while maximizing conversions.',
    'section4-text/home': 'Raise your brand\'s credibility and ignite discussions that drive sales with our multi-channel, multi-platform seeding network.',
    'section5-text/home': 'Enhance your reputation and reach millions of potential customers with Thailand\'s top-tier media partnerships.',
    'section6-text/home': 'Harness “the influential voices” of prominent KOLs/KOCs in your industry. Let VAN DIGITAL tell your brand story in the most impressive and persuasive way.',
    'section7-text/home': 'As one of the pioneering AGENCY in the field of e-commerce platform translation, VAN DIGITAL brings comprehensive, streamlined and effective marketing solutions.',
    'section8-text/home': 'Streamlined and efficient, the top choice for sellers. VAN DIGITAL is confident that the Agency operates as a miniature e-commerce platform development department for your business, with experience accompanying more than 1,000 customers, in more than 200 different products.',
    'section9-text/home': 'As a bridge between Thailand and countries outside Thailand, "If we cross rivers, we build bridges, if we cross mountains, we carve mountains" accompanying businesses to develop the Thai market',
    'h1-text/footer': 'Please, leave your details',
    'h2-text/footer': 'for consultation',
    'h3-text/footer': 'Email',
    'h4-text/footer': 'Phone number',
    'section1-modal-h1/home': 'Consulting management',
    'section1-modal-h1-text1/home': 'Brand growth strategy consultation',
    'section1-modal-h1-text2/home': 'Fanpage/ Instagram channel building and development consultation',
    'section1-modal-h1-text3/home': 'Tiktok channel building and development consultation',
    'section1-modal-h1-text4/home': '2,000 THB/package',
    'section1-modal-h1-text5/home': '3.000 THB/package',
    'section1-modal-h1-text6/home': '5.000 THB/package',
    'section1-modal-h2/home': 'Communication material production',
    'section1-modal-h2-text1/home': 'Creative Content Writer & Editor',
    'section1-modal-h2-text2/home': 'Copy Writer & Editor',
    'section1-modal-h2-text3/home': 'Script Writer',
    'section1-modal-h2-text4/home': 'Graphics Design',
    'section1-modal-h2-text5/home': 'Product photography',
    'section1-modal-h2-text6/home': 'Video shooting and editing',
    'section1-modal-h2-text7/home': '1.000 THB /post',
    'section1-modal-h2-text8/home': '600 THB /post',
    'section1-modal-h2-text9/home': '3.500 THB /script',
    'section1-modal-h2-text10/home': '700 THB /file',
    'section1-modal-h2-text11/home': '1.000 THB /file',
    'section1-modal-h2-text12/home': 'Provide a detailed quotation based on the customer\'s needs',

    'section2-modal-h1/home': 'User feedback video',
    'section2-modal-h1-text1/home': 'Review package',
    'section2-modal-h1-text2/home': 'Package includes:',
    'section2-modal-h1-text3/home': '- Video shooting location revolves around the actor\'s daily activities',
    'section2-modal-h1-text4/home': '- Visuals combined with voice-off',
    'section2-modal-h1-text5/home': '- Shooting, editing and delivering the product in Full HD',
    'section2-modal-h1-text6/home': 'Package does not include:',
    'section2-modal-h1-text7/home': '- Customized scene selection as per the partner\'s request',
    'section2-modal-h1-text8/home': '- Translation (script translation)',
    'section2-modal-h1-text9/home': '- Additional actors from the second one onwards',
    'section2-modal-h1-text10/home': '3.000THB/clip',
    'section2-modal-h1-text11/home': 'Basic package',
    'section2-modal-h1-text12/home': 'Package includes:',
    'section2-modal-h1-text13/home': '- Video shooting location revolves around the actor\'s daily activities',
    'section2-modal-h1-text14/home': '- Visuals combined with voice-off',
    'section2-modal-h1-text15/home': '- Shooting, editing and delivering the product in Full HD',
    'section2-modal-h1-text16/home': 'Package does not include:',
    'section2-modal-h1-text17/home': '- Chọn bối cảnh quay theo yêu cầu của đối tác',
    'section2-modal-h1-text18/home': '- Translation (script translation)',
    'section2-modal-h1-text19/home': '- Additional actors from the second one onwards',
    'section2-modal-h1-text20/home': '4.500THB/clip',
    'section2-modal-h1-text21/home': 'Standard package',
    'section2-modal-h1-text22/home': 'Package includes:',
    'section2-modal-h1-text23/home': '- Camera shot angle consultation',
    'section2-modal-h1-text24/home': '- Shooting location: studio equipped with basic setup',
    'section2-modal-h1-text25/home': '- Visuals combined with voice-off',
    'section2-modal-h1-text26/home': '- Shooting, editing and delivering the product in Full HD',
    'section2-modal-h1-text27/home': 'Package does not include:',
    'section2-modal-h1-text28/home': '- Customized scene selection as per the partner\'s request',
    'section2-modal-h1-text29/home': '- Translation (script translation)',
    'section2-modal-h1-text30/home': '- Additional actors from the second one onwards',
    'section2-modal-h1-text31/home': '6.000THB/clip',
    'section2-modal-h2/home': 'Product promo video',
    'section2-modal-h2-text1/home': 'Upgraded package',
    'section2-modal-h2-text2/home': 'Package includes:',
    'section2-modal-h2-text3/home': '- Script development and consultation based on script content requirements',
    'section2-modal-h2-text4/home': '- Shooting location: studio equipped with scene setup',
    'section2-modal-h2-text5/home': '- MC/actor for live voice-over introduction',
    'section2-modal-h2-text6/home': '- Shooting, editing and delivering the product in Full HD',
    'section2-modal-h2-text7/home': 'Package does not include:',
    'section2-modal-h2-text8/home': '- Cost of renting on-location sets',
    'section2-modal-h2-text9/home': '- On-Set translation service',
    'section2-modal-h2-text10/home': '- Additional actors from the third one onwards',
    'section2-modal-h2-text11/home': '9,000THB/clip',
    'section2-modal-h3/home': 'TVC',
    'section2-modal-h3-text1/home': 'Premium package',
    'section2-modal-h3-text2/home': 'Package includes:',
    'section2-modal-h3-text3/home': '- Script development and consultation',
    'section2-modal-h3-text4/home': '- Shooting location: Non-restricted and customizable set options to match your script',
    'section2-modal-h3-text5/home': '- MC for live voice-over introduction',
    'section2-modal-h3-text6/home': '- Shooting, editing and delivering the product in Full HD',
    'section2-modal-h3-text7/home': 'Provide a detailed quotation based on the customer\'s needs',

    'section3-modal-h1/home': 'Multi-platform advertising',
    'section3-modal-h1-text1/home': 'Facebook Ads',
    'section3-modal-h1-text2/home': 'Google Ads',
    'section3-modal-h1-text3/home': 'Tiktok Ads',
    'section3-modal-h1-text4/home': 'BUDGET RANGE (THB/Month)',
    'section3-modal-h1-text5/home': 'Below 20,000',
    'section3-modal-h1-text6/home': '20.000 - 100.000',
    'section3-modal-h1-text7/home': '100.000 - 200.000',
    'section3-modal-h1-text8/home': '200.000 - 1.000.000',
    'section3-modal-h1-text9/home': '1.000.000 - 1.500.000',
    'section3-modal-h1-text10/home': '1.500.000 - 3.000.000',
    'section3-modal-h1-text11/home': '3.000 THB',
    'section3-modal-h1-text12/home': '15% x Budget',
    'section3-modal-h1-text13/home': '13% x Budget',
    'section3-modal-h1-text14/home': '12% x Budget',
    'section3-modal-h1-text15/home': '11% x Budget',
    'section3-modal-h1-text16/home': '10% x Budget',

    'section4-modal-h1/home': 'Seeding script development',
    'section4-modal-h1-text1/home': 'Free of charge consultation',
    'section4-modal-h2/home': 'Like promotion package (Reputation Enhancement)',
    'section4-modal-h2-text1/home': 'Boost number of likes on your post',
    'section4-modal-h2-text2/home': 'Boost number of likes on your post fanpage',
    'section4-modal-h2-text3/home': 'Enhance profile follow',
    'section4-modal-h2-text4/home': '100 THB/600 LIKES',
    'section4-modal-h2-text5/home': '100 THB/ 500 LIKES',
    'section4-modal-h2-text6/home': '100 THB/ 800 FOLLOWERS',
    'section4-modal-h3/home': 'Seeding comment package (building engagement)',
    'section4-modal-h3-text1/home': '100THB /80 COMMENTS',

    'section5-modal-h1/home': 'Please provide detailed information for an accurate quotation',
    'section5-modal-h2/home': 'Brand/Product you wish to communicate:',
    'section5-modal-h3/home': 'The heading of your interest:',
    'section5-modal-h4/home': 'In case you do not have an exact answer, please state that you require consultation.',
    'section5-modal-h5/home': 'Please provide your phone number/email for us to prepare a quotation and get in touch!',

    'section6-modal-h1/home': 'Please provide detailed information for an accurate quotation',
    'section6-modal-h2/home': 'Brand/Product you wish to communicate:',
    'section6-modal-h3/home': 'KOLs/KOCs of your interest:',
    'section6-modal-h4/home': 'In case you do not have an exact answer, please state that you require consultation.',
    'section6-modal-h5/home': 'Please provide your phone number/email for us to prepare a quotation and get in touch!',

    'section7-modal-h1/home': 'BUILDING AN E-COMMERCE FLOOR',
    'section7-modal-h1-text1/home': 'Tiktok Shop',
    'section7-modal-h1-text2/home': 'Register and build a professional booth on Tiktok shop Thailand',
    'section7-modal-h1-text3/home': '20.000THB',
    'section7-modal-h1-text4/home': 'Shopee',
    'section7-modal-h1-text5/home': 'Register and build a professional store on Shopee Thailand',
    'section7-modal-h1-text6/home': '20.000THB',
    'section7-modal-h2/home': 'OPERATION OF E-COMMERCIAL FLOOR',
    'section7-modal-h2-text1/home': 'INTERIOR FLOOR ADVERTISING',
    'section7-modal-h2-text2/home': 'Product advertisements displayed in e-commerce',
    'section7-modal-h2-text3/home': '10,000THB/Month + % revenue commission',
    'section7-modal-h2-text4/home': 'OFF-FLOOR ADVERTISING',
    'section7-modal-h2-text5/home': 'Advertise products from external social networks to attract buyers to e-commerce',
    'section7-modal-h2-text6/home': '10,000THB/Month + % revenue commission',
    'section7-modal-h2-text7/home': 'AFFILIATE MARKETING',
    'section7-modal-h2-text8/home': '- Affiliate marketing offers from marketing terms creators',
    'section7-modal-h2-text9/home': '- Build a proactive affiliate marketing channel (Additional consulting + additional fee)',
    'section7-modal-h2-text10/home': '10,000THB/Month + % revenue commission',
    'section7-modal-h2-text11/home': 'LIVE STREAM SALES',
    'section7-modal-h2-text12/home': '- Live Stream products from the Agency\'s Live team (Additional consultation + additional fee)',
    'section7-modal-h2-text13/home': '- Live Stream products using the strategy of hiring KOLs and KOCs (Additional consulting + additional fees)',
    'section7-modal-h2-text14/home': '10,000THB/Month + % revenue commission',

    'section9-modal-h1-text1/home': 'Service Price',
    'section9-modal-h1-text2/home': 'Calculate general fees',
    'section9-modal-h1-text3/home': '(Revenue on successful orders - shipping costs)',
    'section9-modal-h1-text4/home': 'Do not use sale',
    'section9-modal-h1-text5/home': '10%',
    'section9-modal-h1-text6/home': 'Can use sale',
    'section9-modal-h1-text7/home': '15%',
    'section9-modal-h1-text8/home': 'Number of orders issued/month',
    'section9-modal-h1-text9/home': 'Under 5,000 applications',
    'section9-modal-h1-text10/home': '45THB/order',
    'section9-modal-h1-text11/home': 'From 5,000 - <10,000 orders',
    'section9-modal-h1-text12/home': '35THB/order',
    'section9-modal-h1-text13/home': '> 10,000 applications',
    'section9-modal-h1-text14/home': '25THB/order',

    'h1-text/about': 'VAN DIGITAL is a comprehensive agency model offering various communication solutions to enterprises across diverse fields in the Thai market.',
    'h2-text/about': '',
    'h3-text/about': 'Why should you embark on your exploration and revenue growth journey with VAN DIGITAL?',
    'h4-text/about': 'Understanding the Thai Market',
    'h5-text/about': 'With several years of operation in the Thai market, providing direct consultation and implementation for 100+ diverse businesses, VAN DIGITAL possesses an in-depth understanding of market trends and consumer behavior, … This understanding allows us to make SWIFT, ACCURATE, and EFFECTIVE communication decisions.',
    'h6-text/about': '',
    'h7-text/about': 'Dedicated and professional team of experts',
    'h8-text/about': 'VAN DIGITAL takes pride in its team of experts who EXCEL IN THEIR RESPECTIVE FIELDS AND DRIVEN BY AN UNWAVERING FIRE OF PASSION. They consistently innovate to adapt to the ever-changing technological landscape and the digital age.',
    'h9-text/about': 'Budget-friendly solutions',
    'h10-text/about': 'For each project with distinct requirements, scale, and budget considerations, VAN DIGITAL designs resource allocation strategies that are both suitable and adaptable over time. Our aim is to optimize costs and maximize profits.',
    'h11-text/about': 'Transparency – Clarity - Pellucidity',
    'h12-text/about': 'Our system shall periodically (daily, weekly, monthly) send report details, including KPIs and in-depth analysis. This ensures our clients to closely track progress, ensuring the utmost alignment with their KPI objectives and the efficacy of our efforts."',

    'h1-text/contact': 'Are you seeking marketing solutions to boost your sales?',
    'h2-text/contact': 'Feel free to get in touch with us',
    'h3-text/contact': 'Or, provide your contact information, and VAN DIGITAL will reach out to you!',
    'h4-text/contact': 'Phone number',
    'h5-text/contact': 'Email',
    'h6-text/contact': 'Service you require consultation for',

    'btn/contact': 'Contact us',
    'btn/send': 'Send information',
    'placeholder/input': 'Enter information',

    'header/modal7': 'Develop e-commerce platform',
    'header/modal9': 'Fulfillment',
    'title/modal7': 'Construction of e-commerce platform',
    'title/modal8': 'Operate e-commerce platform',
    'title/modal9': 'Fulfillment in Thailand'
};
