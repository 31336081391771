import axios, {AxiosRequestConfig} from "axios";

interface CustomAxiosHeaders {
    Authorization?: string;
}

const instance = axios.create({
    // baseURL: 'http://localhost:8080/api'
    baseURL: 'https://api.vdsagency.com/api'
});

instance.interceptors.request.use(
    (config: AxiosRequestConfig<CustomAxiosHeaders>) => {
        let accessToken: string = '';
        const localStorageData = localStorage.getItem("persist:vds-digital");
        if (localStorageData) {
            const parsedData = JSON.parse(localStorageData);
            if (parsedData.login) {
                const loginData = JSON.parse(parsedData.login);
                if (loginData && loginData.accessToken) {
                    accessToken = loginData.accessToken;
                }
            }
        }
        if (accessToken && config.headers) {
            config.headers.Authorization = `Bearer ${accessToken}`;
        }
        return config;
    },
    (error) => Promise.reject(error),
);

export default instance;
