import React from 'react';

const ModalConfirm = ({closeModal, content}: any) => {

    return (
        <>
            <div className="swal2-container swal2-center swal2-fade swal2-shown"
                 style={{overflowY: "auto"}}>
                <div aria-labelledby="swal2-title" aria-describedby="swal2-content"
                     className="swal2-popup swal2-modal swal2-show" tabIndex={-1} role="dialog" aria-live="assertive"
                     aria-modal="true" style={{display: "flex"}}>
                    <div className="swal2-header">
                        <h2 className="swal2-title" id="swal2-title" style={{display: "flex"}}>THÀNH CÔNG</h2>
                        <div className="swal2-icon swal2-success">
                            <div className="swal2-success-circular-line-left"/>
                            <span className="swal2-success-line-tip"/>
                            <span className="swal2-success-line-long"/>
                            <div className="swal2-success-ring"/>
                            <div className="swal2-success-fix"/>
                            <div className="swal2-success-circular-line-right"/>
                        </div>
                    </div>
                    <div className="swal2-content">
                        <div id="swal2-content" style={{
                            display: "block", whiteSpace: "pre-wrap",
                            wordWrap: "break-word"
                        }}>
                            <p>{content}</p>
                        </div>
                        <output/>
                    </div>
                    <div className="swal2-actions" style={{display: "flex"}}>
                        <button type="button" onClick={closeModal}
                                className="swal2-confirm btn btn-confirm mt-2 swal2-styled"
                                style={{
                                    display: "inline-block",
                                    borderLeftColor: "rgb(48, 133, 214)",
                                    borderRightColor: "rgb(48, 133, 214)"
                                }}
                                aria-label="">OK
                        </button>

                    </div>
                </div>

            </div>
        </>
    );
}

export default React.memo(ModalConfirm);
