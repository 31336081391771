import {
    TAppDispatch,
    IAppAction,
    IUserItem,
    ISearchItem,
    IUserList
} from '@types';
import axiosInstance from "@utils/axiosInstance";

export const UNLOCK_USER_RESET = 'user.UNLOCK_USER_RESET';
export const UNLOCK_USER_REQUEST = 'user.UNLOCK_USER_REQUEST';
export const UNLOCK_USER_SUCCESS = 'user.UNLOCK_USER_SUCCESS';
export const UNLOCK_USER_FAILURE = 'user.UNLOCK_USER_FAILURE';

export const LOCK_USER_RESET = 'user.LOCK_USER_RESET';
export const LOCK_USER_REQUEST = 'user.LOCK_USER_REQUEST';
export const LOCK_USER_SUCCESS = 'user.LOCK_USER_SUCCESS';
export const LOCK_USER_FAILURE = 'user.LOCK_USER_FAILURE';

export const UPDATE_USER_RESET = 'user.UPDATE_USER_RESET';
export const UPDATE_USER_REQUEST = 'user.UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'user.UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'user.UPDATE_USER_FAILURE';

export const GET_USER_INFO = 'user.GET_USER_INFO';
export const GET_LIST_USER = 'users.GET_LIST_USER';

export const REGISTER_REQUEST = 'login.REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'login.REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'login.REGISTER_FAILURE';

export const unlockReset = () => ({
    type: UNLOCK_USER_RESET,
    payload: ""
});
export const unlockRequest = () => ({
    type: UNLOCK_USER_REQUEST,
    payload: ""
});

export const unlockSuccess = (data: IUserItem) => ({
    type: UNLOCK_USER_SUCCESS,
    payload: data,
});
export const unlockFailure = (error: any) => ({
    type: UNLOCK_USER_FAILURE,
    payload: error,
});

export const lockReset = () => ({
    type: LOCK_USER_RESET,
    payload: ""
});
export const lockRequest = () => ({
    type: LOCK_USER_REQUEST,
    payload: ""
});

export const lockSuccess = (data: IUserItem) => ({
    type: LOCK_USER_SUCCESS,
    payload: data,
});
export const lockFailure = (error: any) => ({
    type: LOCK_USER_FAILURE,
    payload: error,
});
export const updateReset = () => ({
    type: UPDATE_USER_RESET,
    payload: ""
});
export const updateRequest = () => ({
    type: UPDATE_USER_REQUEST,
    payload: ""
});

export const updateSuccess = (data: IUserItem) => ({
    type: UPDATE_USER_SUCCESS,
    payload: data,
});

export const updateFailure = (error: any) => ({
    type: UPDATE_USER_FAILURE,
    payload: error,
});

export const registerRequest = () => ({
    type: REGISTER_REQUEST,
    payload: ""
});

export const registerSuccess = (data: IUserItem) => ({
    type: REGISTER_SUCCESS,
    payload: data,
});

export const registerFailure = (error: any) => ({
    type: REGISTER_FAILURE,
    payload: error,
});
export const unlockUser = (id: string) => async (dispatch: TAppDispatch) => {
    dispatch(unlockRequest());
    try {
        const response = await axiosInstance.post('/account/unlock?id=' + id, "");
        dispatch(unlockSuccess(response.data));
    } catch (error: any) {
        dispatch(unlockFailure(error.data));
    }
};
export const lockUser = (id: string) => async (dispatch: TAppDispatch) => {
    dispatch(lockRequest());
    try {
        const response = await axiosInstance.post('/account/lock?id=' + id, "");
        dispatch(lockSuccess(response.data));
    } catch (error: any) {
        dispatch(lockFailure(error.data));
    }
};
export const updateUser = (newUser: IUserItem) => async (dispatch: TAppDispatch) => {
    dispatch(updateRequest());
    try {
        const response = await axiosInstance.post('/account/update', newUser);
        dispatch(updateSuccess(response.data));
    } catch (error: any) {
        dispatch(updateFailure(error.data));
    }
};

const getAllUser = (search: ISearchItem) => async (dispatch: TAppDispatch<IAppAction<IUserList>>) => {
    try {
        // Make an API call to get all categories
        const response = await axiosInstance.post('/account/getAll', search);
        const users: IUserList = response.data.data;
        // Dispatch an action with the categories as the payload
        dispatch({
            type: GET_LIST_USER,
            payload: users,
        });
    } catch (error) {
        // Handle the error if the API call fails
        console.error(error);
    }
};
const getUserInfo = (email: string | undefined) => async (dispatch: TAppDispatch<IAppAction<IUserItem>>) => {
    try {
        // Make an API call to get all categories
        const response = await axiosInstance.get('/account/getUserInfo?email=' + email);
        const user: IUserItem = response.data.data;

        // Dispatch an action with the categories as the payload
        dispatch({
            type: GET_USER_INFO,
            payload: user,
        });
    } catch (error) {
        // Handle the error if the API call fails
        console.error(error);
    }
};

export const userActions = {
    getUserInfo,
    registerRequest,
    registerFailure,
    registerSuccess,
    getAllUser,
    updateUser, updateReset, lockUser, unlockUser, lockReset, unlockReset
};
