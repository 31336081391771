import React, {useEffect, useState} from 'react';
import "./Section.scss";

const Section3 = ({img, color, className}: any) => {
    const [width, setWidth] = useState<number>(0);

    useEffect(() => {
        setTimeout(() => {
            const section1 = document.getElementById('anchor');
            const sectionVds = document.getElementById('section-vds');
            if (window.innerWidth < 992) {
                if (sectionVds) {
                    setWidth(sectionVds.clientWidth)
                }
            } else {
                if (section1) {
                    setWidth(section1.clientWidth);
                }
            }
        }, 300)


        const handleResize = () => {

            setTimeout(() => {
                const section1 = document.getElementById('anchor');
                const sectionVds = document.getElementById('section-vds');
                if (window.innerWidth < 992) {
                    if (sectionVds) {
                        setWidth(sectionVds.clientWidth)
                    }
                } else {
                    if (section1) {
                        setWidth(section1.clientWidth);
                    }
                }
            }, 300)
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [])
    return (
        <div className={`section-image-3 ${className}`}
             style={{height: width * 0.8 / 2}}>
            <section className="image-canvas-3"
                     style={{height: width / 2 * 0.8, width: width * 0.8}}>
                <img style={{
                    borderRadius: "120px",
                    zIndex: 9999,
                    position: "relative",
                    height: "100%",
                }} src={img} alt="Partition 1"/>
                <div className="circle" style={{
                    height: width * 0.8 / 2 - 36.4,
                    width: width * 0.8 / 2 - 36.4,
                    backgroundColor: color,
                    transform: `translate(${0.6 * width + 36.4}px,-${width * 0.8 / 2 - (width * 0.8 / 2 - (width * 0.8 / 2 - 36.4)) / 2}px)`
                }}/>
            </section>
        </div>
    );
};

export default React.memo(Section3);
