export default {
    'language/header': 'Ngôn ngữ',
    'about/header': 'Giới thiệu',
    'service/header': 'Dịch vụ',
    'product/header': 'Sản phẩm liên kết',
    'contact/header': 'Liên hệ',
    'h1/home': 'Chúng tôi là ai?',
    'h1-text/home': 'VAN DIGITAL là mô hình agency cung cấp toàn diện các giải pháp truyền thông cho doanh nghiệp ở đa dạng các lĩnh vực tại thị trường Thái Lan.',
    'h2-text/home': 'Các giải pháp mà chúng tôi cung cấp gồm có',
    'hot-combo/home': 'Hot combo trong tháng',
    'hot-combo-h1/home': 'Mua 1 được 3',
    'hot-combo-h2/home': 'Tối đa doanh số',
    'hot-combo-h3/home': 'Click để xem chi tiết',
    'section1-text/home': 'Khai phá thị trường, xây dựng thương hiệu bền vững mà gia tăng doanh thu với "bản đồ quy hoạch truyền thông" từ VAN DIGITAL.',
    'section2-text/home': 'Đa dạng hóa câu chuyện mà bạn muốn kể đến khách hàng với những định dạng hình ảnh và video với sự tư vấn và thực hiện từ VAN DIGITAL.',
    'section3-text/home': 'Quảng cáo đa nền tảng với quy trình chuẩn hóa và tối ưu tối thiểu chi phí, tối đa chuyển đổi.',
    'section4-text/home': 'Mạng lưới seeding đa kênh, đa nền tảng giúp gia tăng uy tín cho thương hiệu, tạo thảo luận sôi nổi hỗ trợ bán hàng.',
    'section5-text/home': 'Mạng lưới đối tác báo chí hàng đầu Thái Lan sẽ giúp bạn gia tăng uy tín và độ phủ tiếp cận đến hàng triệu khách hàng.',
    'section6-text/home': 'Mượn "tiếng nói" từ những KOLs/KOCs nổi bật theo từng ngành hàng, VAN DIGITAL sẽ giúp bạn kể câu chuyện thương hiệu của bạn theo cách ấn tượng và thuyết phục nhất.',
    'section7-text/home': 'Là một trong những AGENCY tiên phong trong lĩnh vực dịch sàn thương mại điện tử, VAN DIGITAL mang dến các giải pháp maketing tổng thể, tinh gọn và hiệu quả',
    'section8-text/home': 'Tinh gọn và hiệu quả, là phương án hàng đầu của các nhà bán hàng. VAN DIGITAL tự tin là Agency vận hành như 1 phòng phát triển sàn TMĐT thu nhỏ của quý doanh nghiệp, với kinh nghiệp đồng hành cùng hơn 1000 khách hàng, trong hơn 200 sản phẩm khác nhau',
    'section9-text/home': 'Là cầu nối giữa Thái Lan với các quốc gia ngoài Thái Lan, "Qua sông thì ta bắc cầu, qua núi thì khoét núi" đồng hành cùng các doan nghiệp phát triển thị trường Thái Lan',
    'h1-text/footer': 'Để lại thông tin để',
    'h2-text/footer': 'nhận tư vấn',
    'h3-text/footer': 'Email',
    'h4-text/footer': 'SĐT',

    'section0-modal-h1/home': 'Khi khách hàng sử dụng dịch vụ facebook ads vớI ngân sách từ:',
    'section0-modal-h1-text1/home': '50.000 THB',
    'section0-modal-h1-text2/home': 'Tặng ngay: gói tư vấn quản trị + gói seeding (200 like/post + 30 comment/post x 15 post/month)',
    'section0-modal-h1-text3/home': '90.000 THB',
    'section0-modal-h1-text4/home': 'Tặng ngay: gói tư vấn quản trị + gói seeding (kịch bản seeding chuyên sâu + 350 like/post + 50 comment/post x 30 post/month) + voucher 5.000 thb cho sản xuất nội dung',
    'section0-modal-h1-text5/home': '150.000 THB',
    'section0-modal-h1-text6/home': 'Tặng ngay: gói tư vấn quản trị + voucher 5.000 thb cho seeding + voucher 5.000 thb cho sản xuất nội dung',
    'section0-modal-h1-text7/home': '600.000 THB',
    'section0-modal-h1-text8/home': 'Tặng ngay: gói tư vấn quản trị + gói supper seeding độc quyền + voucher 5.000 thb cho sản xuất nội dung',
    'section0-modal-h2/home': 'Khi khách hàng sử dụng dịch vụ tiktok ads vớI ngân sách từ:',
    'section0-modal-h2-text1/home': '150.000 THB',
    'section0-modal-h2-text2/home': 'Tặng ngay: gói tư vấn quản trị + voucher 15% cho sản xuất hình ảnh + gói seeding (200 like/post + 30 comment/post x 15 post/month)',

    'section1-modal-h1/home': 'Tư vấn quản trị',
    'section1-modal-h1-text1/home': 'Tư vấn chiến lược phát triển thương hiệu',
    'section1-modal-h1-text2/home': 'Tư vấn xây dựng và phát triển kênh Fanpage/ Instagram',
    'section1-modal-h1-text3/home': 'Tư vấn xây dựng và phát triển kênh Tiktok',
    'section1-modal-h1-text4/home': '2.000 THB/gói',
    'section1-modal-h1-text5/home': '3.000 THB/gói',
    'section1-modal-h1-text6/home': '5.000 THB/gói',
    'section1-modal-h2/home': 'Sản suất tư liệu truyền thông',
    'section1-modal-h2-text1/home': 'Creative Content Writer & Editor',
    'section1-modal-h2-text2/home': 'Copy Writer & Editor',
    'section1-modal-h2-text3/home': 'Script Writer',
    'section1-modal-h2-text4/home': 'Graphics Design',
    'section1-modal-h2-text5/home': 'Chụp ảnh sản phẩm',
    'section1-modal-h2-text6/home': 'Quay dựng video',
    'section1-modal-h2-text7/home': '1.000 THB /post',
    'section1-modal-h2-text8/home': '600 THB /post',
    'section1-modal-h2-text9/home': '3.500 THB /script',
    'section1-modal-h2-text10/home': '700 THB /file',
    'section1-modal-h2-text11/home': '1.000 THB /file',
    'section1-modal-h2-text12/home': 'Báo giá cụ thể theo yêu cầu từ khách hàng',

    'section2-modal-h1/home': 'Video Feedback người dùng',
    'section2-modal-h1-text1/home': 'Gói review',
    'section2-modal-h1-text2/home': 'Gói bao gồm:',
    'section2-modal-h1-text3/home': '- Địa điểm quay xoay quanh hoạt động thường ngày của diễn viên',
    'section2-modal-h1-text4/home': '- Hình ảnh kết hợp voice off',
    'section2-modal-h1-text5/home': '- Quay và dựng bàn giao sản phẩm Full HD',
    'section2-modal-h1-text6/home': 'Gói không bao gồm:',
    'section2-modal-h1-text7/home': '- Chọn bối cảnh quay theo yêu cầu của đối tác',
    'section2-modal-h1-text8/home': '- Phiên dịch (dịch kịch bản)',
    'section2-modal-h1-text9/home': '- Diễn viên thứ 2 trở đi',
    'section2-modal-h1-text10/home': '3.000THB/clip',
    'section2-modal-h1-text11/home': 'Gói cơ bản',
    'section2-modal-h1-text12/home': 'Gói bao gồm:',
    'section2-modal-h1-text13/home': '- Địa điểm quay xoay quanh hoạt động thường ngày của diễn viên',
    'section2-modal-h1-text14/home': '- Hình ảnh kết hợp voice off',
    'section2-modal-h1-text15/home': '- Quay và dựng bàn giao sản phẩm Full HD',
    'section2-modal-h1-text16/home': 'Gói không bao gồm:',
    'section2-modal-h1-text17/home': '- Chọn bối cảnh quay theo yêu cầu của đối tác',
    'section2-modal-h1-text18/home': '- Phiên dịch (dịch kịch bản)',
    'section2-modal-h1-text19/home': '- Diễn viên thứ 2 trở đi',
    'section2-modal-h1-text20/home': '4.500THB/clip',
    'section2-modal-h1-text21/home': 'Gói tiêu chuẩn',
    'section2-modal-h1-text22/home': 'Gói bao gồm:',
    'section2-modal-h1-text23/home': '- Tư vấn góc quay',
    'section2-modal-h1-text24/home': '- Địa điểm quay: Studio có set up cơ bản',
    'section2-modal-h1-text25/home': '- Hình ảnh kết hợp voice off',
    'section2-modal-h1-text26/home': '- Quay và dựng bàn giao sản phẩm Full HD',
    'section2-modal-h1-text27/home': 'Gói không bao gồm:',
    'section2-modal-h1-text28/home': '- Chọn bối cảnh quay theo yêu cầu của đối tác',
    'section2-modal-h1-text29/home': '- Phiên dịch (dịch kịch bản)',
    'section2-modal-h1-text30/home': '- Diễn viên thứ 2 trở đi',
    'section2-modal-h1-text31/home': '6.000THB/clip',
    'section2-modal-h2/home': 'Video giới thiệu sản phẩm',
    'section2-modal-h2-text1/home': 'Gói nâng cấp',
    'section2-modal-h2-text2/home': 'Gói bao gồm:',
    'section2-modal-h2-text3/home': '- Xây dựng và tư vấn kịch bản quay dựa trên yêu cầu kịch bản nội dung',
    'section2-modal-h2-text4/home': '- Địa điểm quay: Studio có set up bối cảnh',
    'section2-modal-h2-text5/home': '- MC/diễn viên giới thiệu thu âm trực tiếp',
    'section2-modal-h2-text6/home': '- Quay và dựng bàn giao sản phẩm Full HD',
    'section2-modal-h2-text7/home': 'Gói chưa bao gồm:',
    'section2-modal-h2-text8/home': '- Chi phí thuê bối cảnh ngoài studio',
    'section2-modal-h2-text9/home': '- Phiên dịch tại trường quay',
    'section2-modal-h2-text10/home': '- Diễn viên thứ 3 trở đi',
    'section2-modal-h2-text11/home': '9,000THB/clip',
    'section2-modal-h3/home': 'TVC',
    'section2-modal-h3-text1/home': 'Gói cao cấp',
    'section2-modal-h3-text2/home': 'Gói bao gồm:',
    'section2-modal-h3-text3/home': '- Xây dựng và tư vấn kịch bản',
    'section2-modal-h3-text4/home': '- Địa điểm quay: Bối cảnh không giới hạn phù hợp với kịch bản',
    'section2-modal-h3-text5/home': '- MC giới thiệu thu âm trực tiếp',
    'section2-modal-h3-text6/home': '- Quay và dựng bàn giao sản phẩm Full HD',
    'section2-modal-h3-text7/home': 'Báo giá cụ thể theo yêu cầu từ khách hàng',

    'section3-modal-h1/home': 'Quảng cáo đa nền tảng',
    'section3-modal-h1-text1/home': 'Facebook Ads',
    'section3-modal-h1-text2/home': 'Google Ads',
    'section3-modal-h1-text3/home': 'Tiktok Ads',
    'section3-modal-h1-text4/home': 'QUY MÔ NGÂN SÁCH (THB/Tháng)',
    'section3-modal-h1-text5/home': 'Dưới 20.000',
    'section3-modal-h1-text6/home': '20.000 - 100.000',
    'section3-modal-h1-text7/home': '100.000 - 200.000',
    'section3-modal-h1-text8/home': '200.000 - 1.000.000',
    'section3-modal-h1-text9/home': '1.000.000 - 1.500.000',
    'section3-modal-h1-text10/home': '1.500.000 - 3.000.000',
    'section3-modal-h1-text11/home': '3.000 THB',
    'section3-modal-h1-text12/home': '15% x Ngân sách',
    'section3-modal-h1-text13/home': '13% x Ngân sách',
    'section3-modal-h1-text14/home': '12% x Ngân sách',
    'section3-modal-h1-text15/home': '11% x Ngân sách',
    'section3-modal-h1-text16/home': '10% x Ngân sách',

    'section4-modal-h1/home': 'Xây dựng kịch bản Seeding',
    'section4-modal-h1-text1/home': 'Tư vấn miễn phí',
    'section4-modal-h2/home': 'Gói tăng like (Gia tăng uy tín)',
    'section4-modal-h2-text1/home': 'Tăng Like bài đăng',
    'section4-modal-h2-text2/home': 'Tăng like Fanpage',
    'section4-modal-h2-text3/home': 'Tăng follow profile',
    'section4-modal-h2-text4/home': '100 THB/600 LIKES',
    'section4-modal-h2-text5/home': '100 THB/ 500 LIKES',
    'section4-modal-h2-text6/home': '100 THB/ 800 FOLLOWERS',
    'section4-modal-h3/home': 'Gói Seeding comment (xây dựng thảo luận)',
    'section4-modal-h3-text1/home': '100THB /80 COMMENTS',

    'section5-modal-h1/home': 'Vui lòng nhập rõ thông tin để nhận báo giá chính xác',
    'section5-modal-h2/home': 'Thương hiệu/ Sản phẩm bạn muốn truyền thông:',
    'section5-modal-h3/home': 'Đầu báo bạn đang quan tâm:',
    'section5-modal-h4/home': 'Ghi rõ cần được tư vấn nếu bạn chưa có câu trả lời chính xác',
    'section5-modal-h5/home': 'Vui lòng để lại số điện thoại/email để chúng tôi xây dựng báo giá và liên hệ!',

    'section6-modal-h1/home': 'Vui lòng nhập rõ thông tin để nhận báo giá chính xác',
    'section6-modal-h2/home': 'Thương hiệu/ Sản phẩm bạn muốn truyền thông:',
    'section6-modal-h3/home': 'KOLs/ KOCs bạn đang quan tâm:',
    'section6-modal-h4/home': 'Ghi rõ cần được tư vấn nếu bạn chưa có câu trả lời chính xác',
    'section6-modal-h5/home': 'Vui lòng để lại số điện thoại/email để chúng tôi xây dựng báo giá và liên hệ!',

    'section7-modal-h1/home': 'Xây dựng sàn TMĐT',
    'section7-modal-h1-text1/home': 'Tiktok Shop',
    'section7-modal-h1-text2/home': 'Đăng ký, xây dựng gian hàng chuyên nghiệp trên Tiktok shop Thailand',
    'section7-modal-h1-text3/home': '20.000THB',
    'section7-modal-h1-text4/home': 'Shopee',
    'section7-modal-h1-text5/home': 'Đăng ký, xây dựng gian hàng chuyên nghiệp trên Shopee Thailand',
    'section7-modal-h1-text6/home': '20.000THB',
    'section7-modal-h2/home': 'Vận hành sàn TMĐT',
    'section7-modal-h2-text1/home': 'Quảng cáo nội sàn',
    'section7-modal-h2-text2/home': 'Quảng cáo sản phẩm hiển thị trong STMĐT',
    'section7-modal-h2-text3/home': '10.000THB/Tháng + %hoa hồng doanh thu',
    'section7-modal-h2-text4/home': 'Quảng cáo ngoại sàn',
    'section7-modal-h2-text5/home': 'Quảng cáo sản phẩm từ các mạng xã hội (MXH) bên ngoài, nhằm kéo khách mua hàng vào STMĐT',
    'section7-modal-h2-text6/home': '10.000THB/Tháng + %hoa hồng doanh thu',
    'section7-modal-h2-text7/home': 'Tiếp thị liên kết',
    'section7-modal-h2-text8/home': '- Mời tiếp thị liên kết từ nhà sáng tạo điều kiện tiếp thị',
    'section7-modal-h2-text9/home': '- Xây dựng kênh tiếp thị liên kết chủ động (Tư vấn thêm + thêm phí)',
    'section7-modal-h2-text10/home': '10.000THB/Tháng + %hoa hồng doanh thu',
    'section7-modal-h2-text11/home': 'Live stream bán hàng',
    'section7-modal-h2-text12/home': '- Live Stream sản phẩm từ đội ngũ Live của Agency (Tư vấn thêm + thêm phí)',
    'section7-modal-h2-text13/home': '- Live Stream sản phẩm bằng chiến lược thuê KOL, KOC (Tư vấn thêm + thêm phí)',
    'section7-modal-h2-text14/home': '10.000THB/Tháng + %hoa hồng doanh thu',

    'section9-modal-h1-text1/home': 'Giá dịch vụ',
    'section9-modal-h1-text2/home': 'Tính phí chung',
    'section9-modal-h1-text3/home': '(Doanh thu trên đơn thành công - chi phí xuất hàng)',
    'section9-modal-h1-text4/home': 'Không dùng sale',
    'section9-modal-h1-text5/home': '10%',
    'section9-modal-h1-text6/home': 'Có dùng sale',
    'section9-modal-h1-text7/home': '15%',
    'section9-modal-h1-text8/home': 'Số lượng đơn xuất/tháng',
    'section9-modal-h1-text9/home': 'Dưới 5.000 đơn',
    'section9-modal-h1-text10/home': '45THB/ đơn xuất',
    'section9-modal-h1-text11/home': 'Từ 5.000 - <10.000 đơn',
    'section9-modal-h1-text12/home': '35THB/ đơn xuất',
    'section9-modal-h1-text13/home': '> 10.000 đơn',
    'section9-modal-h1-text14/home': '25THB/đơn xuất',

    'h1-text/about': 'VAN DIGITAL là mô hình agency cung cấp toàn diện các giải pháp truyền thông cho doanh nghiệp ở đa dạng các lĩnh vực tại thị trường Thái Lan.',
    'h2-text/about': 'Tại sao bạn nên lựa chọn VAN DIGITAL ',
    'h3-text/about': 'cho hành trình khai phá trị trường và tăng trưởng doanh thu?',
    'h4-text/about': 'Am hiểu thị trường Thái Lan',
    'h5-text/about': 'Với nhiều năm hoạt động tại thị trường Thái Lan, trực tiếp tư vấn và triển khai cho 100+ doanh nghiệp đa ngành, VAN DIGITAL có sự am hiểu tường tận về xu hướng thị trường, hành vi tiêu dùng,.. để từ đó đưa ra những hoạch định truyền thông ',
    'h6-text/about': 'NHANH - ĐÚNG và TRÚNG.',
    'h7-text/about': 'Đội ngũ chuyên gia chuyên nghiệp, tận tâm',
    'h8-text/about': 'VAN DIGITAL tự hào sở hữu đội ngũ chuyên gia MẠNH VỀ CHUYÊN MÔN - CHÁY TRONG NĂNG LƯỢNG, luôn sáng tạo và đổi mới không ngừng để thích ứng với những biến đổi của môi trường công nghệ và kỷ nguyên số.',
    'h9-text/about': 'Phù hợp mọi ngân sách',
    'h10-text/about': 'Với mỗi một dự án với yêu cầu, quy mô và ngân sách riêng biệt, VAN DIGITAL sẽ thiết kế những chiến lược phân bổ nguồn lực phù hợp và thay đổi linh hoạt theo thời gian nhằm mục đích tối ưu hóa chi phí, tối đa hóa lợi nhuận.',
    'h11-text/about': 'Công khai – Minh bạch – Rõ ràng',
    'h12-text/about': 'Hệ thống báo cáo chỉ số và phân tích trọng tâm được gửi định kỳ (hàng ngày, hàng tuần, hàng tháng) giúp khách hàng theo sát tiến độ và hiệu quả công việc, đảm bảo thực hiện mục tiêu KPIs sát nhất.',

    'h1-text/contact': 'Bạn cần tư vấn các giải pháp Marketing gia tăng doanh số?',
    'h2-text/contact': 'Liên hệ với chúng tôi',
    'h3-text/contact': 'Hoặc vui lòng để lại thông tin, VAN DIGITAL sẽ liên hệ với bạn!',
    'h4-text/contact': 'Số điện thoại',
    'h5-text/contact': 'Email',
    'h6-text/contact': 'Dịch vụ cần tư vấn',

    'btn/contact': 'Liên hệ với chúng tôi',
    'btn/send': 'Gửi thông tin',
    'placeholder/input': 'Nhập thông tin',

    'header/modal7': 'Phát triển sàn TMĐT',
    'header/modal9': 'Fulfillment',
    'title/modal7': 'Xây dựng sàn TMĐT',
    'title/modal8': 'Vận hành sàn TMĐT',
    'title/modal9': 'Fulfillment tại Thailand'
};
