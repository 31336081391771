import React, {ChangeEvent, useEffect, useState} from 'react';
import "./AppHeader.scss"
import logo from "@assets/images/logo.png";
import logo_white from "@assets/images/logo-white.png";
import menu from "@assets/images/menu.png";
import close from "@assets/images/close.png";
import {Popup} from "antd-mobile";
import {useTranslation} from 'react-i18next';
import {useSelector} from "react-redux";
import {IAppState} from "@types";
import {persistor} from "../../../redux/configurestore";

const AppHeader = () => {
    const loginState = useSelector((state: IAppState) => state.login);
    const {t, i18n} = useTranslation('global');
    const [language, setLanguage] = useState<string>(localStorage.getItem("lang") ?? 'en');
    useEffect(() => {
        i18n.changeLanguage(language).then();
    }, []);

    const [visible, setVisible] = useState<boolean>(false);
    const handleChangeLanguage = (e: ChangeEvent<HTMLSelectElement>) => {
        setLanguage(e.target.value);
        localStorage.setItem("lang", e.target.value);
        i18n.changeLanguage(e.target.value).then();
    };


    return (
        <>
            <header className="header uk-sticky">
                <div className={`header__bottom ${visible ? 'uk-mobile' : ''}`}>
                    <div className="container">
                        <nav className="uk-navbar-container uk-navbar-transparent uk-navbar">
                            <div className="uk-navbar-left">
                                <a href="/" className="uk-navbar-item uk-logo uk-visible@m">
                                    <img src={logo} alt="VAN DIGITAL"/>
                                </a><a href="/" className="uk-navbar-item uk-logo uk-hidden@m">
                                <img src={visible ? logo_white : logo} alt="VAN DIGITAL"/></a>

                            </div>
                            <div className="uk-navbar-center">

                                <div className="menu-main-menu-container">
                                    <ul id="main-nav" className="uk-navbar-nav uk-visible@m">
                                        <li
                                            className="menu-item menu-item-type-post_type_archive menu-item-object-vay-tin-chap menu-item-21738">
                                            <a href="/gioi-thieu">{t('about/header')}</a></li>
                                        <li
                                            className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-21099">
                                            <a href="/dich-vu">{t('service/header')}</a>
                                            <ul className="sub-menu">
                                                <li id="menu-item-22915"
                                                    className="menu-item menu-item-type-post_type menu-item-object-tien-ich menu-item-22915">
                                                    <a href="#">Strategic Planning</a></li>
                                                <li id="menu-item-19617"
                                                    className="menu-item menu-item-type-post_type menu-item-object-tien-ich menu-item-19617">
                                                    <a href="#">Media Production</a></li>
                                                <li id="menu-item-19618"
                                                    className="menu-item menu-item-type-post_type menu-item-object-tien-ich menu-item-19618">
                                                    <a href="#">Performance Ads</a></li>
                                                <li id="menu-item-22214"
                                                    className="menu-item menu-item-type-post_type menu-item-object-tien-ich menu-item-22214">
                                                    <a href="#">Seeding</a></li>
                                                <li id="menu-item-19615"
                                                    className="menu-item menu-item-type-post_type menu-item-object-tien-ich menu-item-19615">
                                                    <a href="#">PR Booking</a>
                                                </li>
                                                <li id="menu-item-19616"
                                                    className="menu-item menu-item-type-post_type menu-item-object-tien-ich menu-item-19615">
                                                    <a href="#">KOLs/KOCs Booking</a>
                                                </li>
                                            </ul>
                                        </li>
                                        <li
                                            className="menu-item menu-item-type-custom menu-item-object-custom menu-item-21099">
                                            <a href="/san-pham-lien-ket">{t('product/header')}</a></li>
                                        <li id="menu-item-19924"
                                            className="menu-item menu-item-type-custom menu-item-object-custom menu-item-19924">
                                            <a href="/lien-he">{t('contact/header')}</a>
                                        </li>
                                        {loginState.isLogin &&
                                        <li id="menu-item-19924"
                                            className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-19924">
                                            <a href="#">Quản lý</a>
                                            <ul className="sub-menu">
                                                <li id="menu-item-19596"
                                                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-19596">
                                                    <a href="/admin/quan-ly-san-pham">Quản lý sản phẩm</a>
                                                </li>
                                                <li id="menu-item-19596"
                                                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-19596">
                                                    <a href="/admin/quan-ly-danh-muc">Quản lý danh mục</a>
                                                </li>
                                                <li id="menu-item-19596"
                                                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-19596">
                                                    <a onClick={() => persistor.purge().then(_ => window.location.replace("/"))}>Đăng
                                                        xuất</a>
                                                </li>
                                            </ul>

                                        </li>
                                        }
                                    </ul>
                                </div>
                            </div>
                            <div className="uk-navbar-right">
                                <a onClick={() => {
                                    setVisible(!visible)
                                }} className="header__bottom__menuBtn uk-navbar-toggle uk-hidden@m uk-light">
                                    <span className="uk-icon uk-navbar-toggle-icon">
                                        <img src={visible ? close : menu}
                                             alt="menu"/>
                                    </span>
                                </a>
                                <Popup
                                    visible={visible}
                                    onMaskClick={() => {
                                        setVisible(false)
                                    }}
                                    position='top'
                                    onClose={() => {
                                        setVisible(false)
                                    }}
                                    bodyStyle={{height: '40vh'}}
                                >
                                    <div className="d-grid text-center">
                                        <a href="/gioi-thieu">
                                            <div className="menu-mobile">{t('about/header')}</div>
                                        </a>
                                        <a href="/dich-vu">
                                            <div className="menu-mobile">{t('service/header')}</div>
                                        </a>
                                        <a href="/san-pham-lien-ket">
                                            <div className="menu-mobile">{t('product/header')}</div>
                                        </a>

                                        <a href="/lien-he">
                                            <div className="menu-mobile">{t('contact/header')}</div>
                                        </a>

                                        <div className="uk-light uk-form-custom">
                                            <span className="fw-bold">{t('language/header')}</span>
                                            <select className="selectLanguage" value={language}
                                                    onChange={handleChangeLanguage} aria-label="Custom controls">
                                                <option value="en">English</option>
                                                <option value="vi">Tiếng Việt</option>
                                                <option value="tl">Tiếng Thái</option>
                                            </select>
                                            <span className="uk-link header__bottom__selectLang uk-mobile">
                                        <span className={`header__bottom__selectLang__icon uk-icon ${language}`}>
                                            &nbsp;
                                            <svg width="20" height="20" viewBox="0 0 20 20"
                                                 xmlns="http://www.w3.org/2000/svg">
                                            <path fill="none" stroke="#000"
                                                  d="M17.25,6.01 L7.12,16.1 L3.82,17.2 L5.02,13.9 L15.12,3.88 C15.71,3.29 16.66,3.29 17.25,3.88 C17.83,4.47 17.83,5.42 17.25,6.01 L17.25,6.01 Z"/><path
                                                fill="none" stroke="#000" d="M15.98,7.268 L13.851,5.148">
                                            </path>
                                            </svg>
                                        </span>
                                    </span>
                                        </div>
                                    </div>
                                </Popup>
                                <div className="uk-visible@m uk-navbar-item">
                                    <div className="uk-light uk-form-custom">
                                        <span className="fw-bold">{t('language/header')}</span>
                                        <select className="selectLanguage" value={language}
                                                onChange={handleChangeLanguage} aria-label="Custom controls">
                                            <option value="en">English</option>
                                            <option value="vi">Tiếng Việt</option>
                                            <option value="tl">Tiếng Thái</option>
                                        </select>
                                        <span className="uk-link header__bottom__selectLang">
                                        <span className={`header__bottom__selectLang__icon uk-icon ${language}`}>
                                            &nbsp;
                                            <svg width="20" height="20" viewBox="0 0 20 20"
                                                 xmlns="http://www.w3.org/2000/svg">
                                            <path fill="none" stroke="#000"
                                                  d="M17.25,6.01 L7.12,16.1 L3.82,17.2 L5.02,13.9 L15.12,3.88 C15.71,3.29 16.66,3.29 17.25,3.88 C17.83,4.47 17.83,5.42 17.25,6.01 L17.25,6.01 Z"/><path
                                                fill="none" stroke="#000" d="M15.98,7.268 L13.851,5.148">
                                            </path>
                                            </svg>
                                        </span>
                                    </span>
                                    </div>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>

            </header>
        </>
    )
}

export default React.memo(AppHeader);
