import {createRoute} from '@utils/router';
import * as views from '@components/views';
import * as admin from '@components/admin/views';

export const appRoutes = {
    loginPage: createRoute('/dang-nhap')({
        component: views.LoginPageView,
    }),
    aboutPage: createRoute('/gioi-thieu')({
        component: views.AboutPageView,
    }),
    servicePage: createRoute('/dich-vu')({
        component: views.ServicePageView,
    }),
    contactPage: createRoute('/lien-he')({
        component: views.ContactPageView,
    }),
    productPage: createRoute('/san-pham-lien-ket')({
        component: views.ProductPageView,
    }),
    adminCategoryManagementPage: createRoute('/admin/quan-ly-danh-muc')({
        component: admin.CategoryManagement,
    }),
    adminItemManagementPage: createRoute('/admin/quan-ly-san-pham')({
        component: admin.ItemManagement,
    }),
    homePage: createRoute('/')({
        component: views.HomePageView,
    }),

};
