import React from "react";
import "./AppFooter.scss";
import {SupportOnline} from "../index";
// import {MessengerFbChat} from "../index";

const AppFooter = () => {


    return (
        <>
            <footer>
                Copyright © 2023 VAN DIGITAL Sucess. All rights reserved.
            </footer>
            <SupportOnline/>
            {/*<MessengerFbChat />*/}
        </>
    )
}
export default React.memo(AppFooter);
