import React, {useEffect} from 'react';
import "./ContactPageView.scss";
// import btn_min from "@assets/images/img13.png";
// import ani from "@assets/images/ani-contact.png";
// import ani2 from "@assets/images/ani-contact-2.png";
import contact from "@assets/images/contact.jpg";
import call from "@assets/images/call.png";
import email from "@assets/images/email.png";
import {Input1} from "@components/elements";
import {AppFooter1} from "@components/shared";
import {useTranslation} from "react-i18next";
import banner_about from "@assets/images/banner-contact.png";

const ContactPageView = () => {
    const {t, i18n} = useTranslation('global');
    useEffect(() => {
        i18n.changeLanguage(localStorage.getItem("lang") ?? 'vi').then();
    }, []);
    const onChange = () => {

    }

    return (
        <>
            <div id="contact" className="about">
                <div className="bg-desktop">
                    {/*<img className="dia" style={{position: "absolute", right: 0, width: "42%"}} src={ani} alt="dia"/>*/}
                    {/*<img className="dia" style={{position: "absolute", right: "15%", width: "42%"}} src={ani2}*/}
                    {/*     alt="dia"/>*/}
                    <img src={banner_about} alt="banner"/>
                    <div
                        className="grid grid-flow-row sm:grid-flow-col grid-rows-2 md:grid-rows-1 sm:grid-cols-2 gap-8 py-6 sm:py-16">
                        <div className="flex flex-col justify-center items-start row-start-2 sm:row-start-1">
                            <div className="text-banner">
                                <h1 className="text-3xl lg:text-4xl xl:text-5xl font-medium text-white-600 leading-normal">{t('contact/header')}</h1>
                                <p className="text-white-500">{t('h1-text/contact')}</p>
                            </div>
                        </div>
                        {/*<div className="flex w-full">*/}
                        {/*    <img style={{zIndex: 9}} src={btn_min} alt="bg min"/>*/}
                        {/*</div>*/}
                    </div>
                </div>

                <div className="container mt-5 mb-5">
                    <div className="contact-wrapper d-flex">
                        <div id="contact-left" className="contact-left col-lg-6">
                            <img src={contact} alt="contact"/>
                            <h3 className="fw-bold mt-4 mb-4">{t('h2-text/contact')}</h3>
                            <div className="call-wrapper d-flex">
                                <img className="w-auto" src={call} alt="call"/>
                                <div className="call-text">
                                    <div className="fs-6">Hotline</div>
                                    <div className="fs-5">+66 829 731 178</div>
                                </div>
                            </div>
                            <div className="call-wrapper d-flex">
                                <img src={email} alt="call"/>
                                <div className="call-text">
                                    <div className="fs-6">Email</div>
                                    <div className="fs-5">van.digital@gmail.com</div>
                                </div>
                            </div>
                        </div>
                        <hr className="uk-hidden@m"/>
                        <div id="contact-right" className="contact-right col-lg-6">
                            <h3 className="fw-bold mt-4 mb-5">{t('h3-text/contact')}</h3>
                            <Input1 name="phone"
                                    type="text"
                                    label={t('h4-text/contact')}
                                    value={""}
                                    placeholder={t('placeholder/input')}
                                    onChange={onChange}
                                    required={true}
                                    error={""}/>
                            <Input1 name="email"
                                    type="text"
                                    label={t('h5-text/contact')}
                                    value={""}
                                    placeholder={t('placeholder/input')}
                                    onChange={onChange}
                                    required={true}
                                    error={""}/>
                            <Input1 name="service"
                                    type="text"
                                    label={t('h6-text/contact')}
                                    value={""}
                                    placeholder={t('placeholder/input')}
                                    onChange={onChange}
                                    required={true}
                                    error={""}/>
                            <a href="/#"
                               className="uk-visible@ header__bottom__btn uk-button uk-border-pill">{t('btn/send')}</a>
                        </div>
                    </div>
                </div>
            </div>
            <AppFooter1/>
        </>
    );
};

export default React.memo(ContactPageView);
