import {createReducer} from "@utils/reducer";
import {IAppAction, ICategorySearch, ICategoryState, ICategoryUpdateState} from "@types";
import immutable from "seamless-immutable";
import {
    CREATE_CATEGORY_FAILURE,
    CREATE_CATEGORY_REQUEST,
    CREATE_CATEGORY_RESET,
    CREATE_CATEGORY_SUCCESS,
    GET_ALL_CATEGORIES
} from "@redux/actions/category.actions";
import {
    GET_LIST_CATEGORY,
    LOCK_CATEGORY_FAILURE,
    LOCK_CATEGORY_REQUEST,
    LOCK_CATEGORY_RESET,
    LOCK_CATEGORY_SUCCESS,
    UNLOCK_CATEGORY_FAILURE,
    UNLOCK_CATEGORY_REQUEST,
    UNLOCK_CATEGORY_RESET,
    UNLOCK_CATEGORY_SUCCESS,
    UPDATE_CATEGORY_FAILURE,
    UPDATE_CATEGORY_REQUEST,
    UPDATE_CATEGORY_RESET,
    UPDATE_CATEGORY_SUCCESS
} from "@redux/actions/category.actions";

const initialUpdateState = immutable<ICategoryUpdateState>(<ICategoryUpdateState>{
    category: {}, loading: false, error: "", code: ""
});
const initialState = immutable<ICategoryState>({
    categories: {},
});
const initialStateAdmin = immutable<ICategorySearch>(<ICategorySearch>{
    categoryList: {},
});
export const categoryListReducer = createReducer<IAppAction, typeof initialStateAdmin>(
    initialStateAdmin,
    {
        [GET_LIST_CATEGORY]: (state, action) => {
            // If state is not immutable, convert it to immutable
            if (!immutable.isImmutable(state)) {
                state = immutable(state);
            }
            return state
                .setIn(['categoryList'], action.payload);
        },
    }
);

export const categoryUnlockReducer = createReducer<IAppAction, typeof initialUpdateState>(
    initialUpdateState,
    {
        [UNLOCK_CATEGORY_RESET]: (state) => {
            if (!immutable.isImmutable(state)) {
                state = immutable(state);
            }
            return state
                .setIn(['loading'], false)
                .setIn(['code'], "")
                .setIn(['error'], "");
        },
        [UNLOCK_CATEGORY_REQUEST]: (state) => {
            if (!immutable.isImmutable(state)) {
                state = immutable(state);
            }
            return state
                .setIn(['loading'], true)
                .setIn(['error'], "");
        },
        [UNLOCK_CATEGORY_SUCCESS]: (state, action) => {
            if (!immutable.isImmutable(state)) {
                state = immutable(state);
            }
            return state
                .setIn(['loading'], false)
                .setIn(['code'], action.payload.code)
                .setIn(['category'], action.payload.data)
                .setIn(['error'], "");
        },

        [UNLOCK_CATEGORY_FAILURE]: (state, action) => {
            if (!immutable.isImmutable(state)) {
                state = immutable(state);
            }
            return state
                .setIn(['loading'], false)
                .setIn(['error'], action.payload);
        },
    }
);

export const categoryLockReducer = createReducer<IAppAction, typeof initialUpdateState>(
    initialUpdateState,
    {
        [LOCK_CATEGORY_RESET]: (state) => {
            if (!immutable.isImmutable(state)) {
                state = immutable(state);
            }
            return state
                .setIn(['loading'], false)
                .setIn(['code'], "")
                .setIn(['error'], "");
        },
        [LOCK_CATEGORY_REQUEST]: (state) => {
            if (!immutable.isImmutable(state)) {
                state = immutable(state);
            }
            return state
                .setIn(['loading'], true)
                .setIn(['error'], "");
        },
        [LOCK_CATEGORY_SUCCESS]: (state, action) => {
            if (!immutable.isImmutable(state)) {
                state = immutable(state);
            }
            return state
                .setIn(['loading'], false)
                .setIn(['code'], action.payload.code)
                .setIn(['category'], action.payload.data)
                .setIn(['error'], "");
        },

        [LOCK_CATEGORY_FAILURE]: (state, action) => {
            if (!immutable.isImmutable(state)) {
                state = immutable(state);
            }
            return state
                .setIn(['loading'], false)
                .setIn(['error'], action.payload);
        },
    }
);
export const categoryCreateReducer = createReducer<IAppAction, typeof initialUpdateState>(
    initialUpdateState,
    {
        [CREATE_CATEGORY_RESET]: (state) => {
            if (!immutable.isImmutable(state)) {
                state = immutable(state);
            }
            return state
                .setIn(['loading'], false)
                .setIn(['code'], "")
                .setIn(['error'], "");
        },
        [CREATE_CATEGORY_REQUEST]: (state) => {
            if (!immutable.isImmutable(state)) {
                state = immutable(state);
            }
            return state
                .setIn(['loading'], true)
                .setIn(['error'], "");
        },
        [CREATE_CATEGORY_SUCCESS]: (state, action) => {
            if (!immutable.isImmutable(state)) {
                state = immutable(state);
            }
            return state
                .setIn(['loading'], false)
                .setIn(['code'], action.payload.code)
                .setIn(['category'], action.payload.data)
                .setIn(['error'], "");
        },

        [CREATE_CATEGORY_FAILURE]: (state, action) => {
            if (!immutable.isImmutable(state)) {
                state = immutable(state);
            }
            return state
                .setIn(['loading'], false)
                .setIn(['error'], action.payload);
        },
    }
);

export const categoryUpdateReducer = createReducer<IAppAction, typeof initialUpdateState>(
    initialUpdateState,
    {
        [UPDATE_CATEGORY_RESET]: (state) => {
            if (!immutable.isImmutable(state)) {
                state = immutable(state);
            }
            return state
                .setIn(['loading'], false)
                .setIn(['code'], "")
                .setIn(['error'], "");
        },
        [UPDATE_CATEGORY_REQUEST]: (state) => {
            if (!immutable.isImmutable(state)) {
                state = immutable(state);
            }
            return state
                .setIn(['loading'], true)
                .setIn(['error'], "");
        },
        [UPDATE_CATEGORY_SUCCESS]: (state, action) => {
            if (!immutable.isImmutable(state)) {
                state = immutable(state);
            }
            return state
                .setIn(['loading'], false)
                .setIn(['code'], action.payload.code)
                .setIn(['category'], action.payload.data)
                .setIn(['error'], "");
        },

        [UPDATE_CATEGORY_FAILURE]: (state, action) => {
            if (!immutable.isImmutable(state)) {
                state = immutable(state);
            }
            return state
                .setIn(['loading'], false)
                .setIn(['error'], action.payload);
        },
    }
);
export const categoryReducer = createReducer<IAppAction, typeof initialState>(
    initialState,
    {
        [GET_ALL_CATEGORIES]: (state, action) => {
            // If state is not immutable, convert it to immutable
            if (!immutable.isImmutable(state)) {
                state = immutable(state);
            }

            return state.setIn(['categories'], action.payload);
        },
    }
);
