import React, {useEffect, useState} from 'react';
import "./AboutPageView.scss";
import img7 from "@assets/images/img14.jpg"
import img9 from "@assets/images/img9.png"
import img10 from "@assets/images/img10.png"
import img11 from "@assets/images/img11.png"
import img12 from "@assets/images/img12.png"
import light from "@assets/images/btn-light.png"
import banner_about from "@assets/images/banner-about.png"
import {Section1, Section3, Section5, TabBarBottom} from "@components/shared";
// import btn_min from "@assets/images/group.jpg";
import {useTranslation} from "react-i18next";

const AboutPageView = () => {
    const {t} = useTranslation('global');
    const [width, setWidth] = useState<number>(0);
    const [width2, setWidth2] = useState<number>(0);

    useEffect(() => {
        const divElement = document.getElementById('anchor');
        const divElement2 = document.getElementById('anchor2');
        const sectionVds = document.getElementById('section-vds');
        if (window.innerWidth < 992) {
            if (sectionVds) {
                setWidth(sectionVds.clientWidth / 2)
                setWidth2(sectionVds.clientWidth / 2)
            }
        } else {
            if (divElement) {
                setWidth(divElement.clientWidth / 2);
            }
            if (divElement2) {
                setWidth2(divElement2.clientWidth / 2);
            }
        }

        const handleResize = () => {
            if (window.innerWidth < 992) {
                if (sectionVds) {
                    setWidth(sectionVds.clientWidth / 2)
                    setWidth2(sectionVds.clientWidth / 2)
                }
            } else {
                if (divElement) {
                    setWidth((prevWidth) => (prevWidth > 1 ? divElement.clientWidth / 2 : divElement.clientWidth));
                }
                if (divElement2) {
                    setWidth2(divElement2.clientWidth / 2);
                }
            }
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [])
    return (
        <>
            <div id="about" className="about">
                <div className="bg-desktop">
                    <img src={banner_about} alt="banner"/>
                    <div
                        className="grid grid-flow-row sm:grid-flow-col grid-rows-2 md:grid-rows-1 sm:grid-cols-2 gap-8 py-6 sm:py-16">
                        <div className="flex flex-col justify-center items-start row-start-2 sm:row-start-1">
                            <div className="text-banner">
                                <h1 className="text-3xl lg:text-4xl xl:text-5xl font-medium text-white-600 leading-normal">{t('about/header')}</h1>
                                <p className="text-white-500">{t('h1-text/about')}</p>
                            </div>
                        </div>
                        {/*<div className="flex w-full">*/}
                        {/*    <img src={btn_min} alt="bg min"/>*/}
                        {/*</div>*/}
                    </div>
                </div>

                <div className="container">
                    <div className="body-header flex justify-center text-center">
                        <div className="top-about col-lg-4">
                            <img src={light} alt="logo"/>
                            <div className="vds-text "><strong>{t('h2-text/about')}</strong>{t('h3-text/about')}
                            </div>
                        </div>
                    </div>
                </div>
                <hr/>
                <div className="body-content container">
                    <div className="row justify-content-between">
                        <div id="section-about-1" className="section-wrapper col-12 col-lg-7">
                            <div id="section-vds" className="section-vds d-flex align-items-center">
                                <div id="section-title" className="section col-lg-3 text-center">
                                    <div className="index fw-bold fs-5">01</div>
                                </div>
                                <div id="anchor" className="col-lg-9 uk-visible@m">
                                    <Section1 img1={img7} img2={img7} img3={img7} radius1="24px" radius2="120px"
                                              radius3="24px" width={width}/>
                                </div>
                            </div>
                        </div>
                        <div id="anchor" className="uk-hidden@m">
                            <Section1 img1={img7} img2={img7} img3={img7} radius1="24px" radius2="120px"
                                      radius3="24px" width={width}/>
                        </div>
                        <div className="section-right col-lg-4">
                            <div>
                                <div className="fw-bold">{t('h4-text/about')}</div>
                                <div className="mt-2">
                                    {t('h5-text/about')}<strong>{t('h2-text/about')}</strong>.
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <hr/>
                <div className="body-content container">
                    <div className="row justify-content-between align-items-center">
                        <div id="section-about-2" className="section-wrapper col-lg-7">
                            <div id="section-vds" className="section-vds d-flex align-items-center">
                                <div id="section-title" className="section col-lg-3 text-center">
                                    <div className="index fw-bold fs-5">02</div>
                                </div>
                                <div className="section-right col-lg-8 uk-visible@m">
                                    <div className="fw-bold">{t('h7-text/about')}</div>
                                    <div className="mt-2">
                                        {t('h8-text/about')}
                                    </div>
                                </div>
                            </div>
                            <div className="uk-hidden@m">
                                <Section5 img1={img11} img2={img11} img3={img12} width={width2} basis={26}/>
                            </div>
                            <div className="section-right uk-hidden@m">
                                <div className="fw-bold">{t('h7-text/about')}</div>
                                <div className="mt-2">
                                    {t('h8-text/about')}
                                </div>
                            </div>
                        </div>
                        <div id="anchor2" className="col-lg-5 uk-visible@m">
                            <Section5 img1={img11} img2={img11} img3={img12} width={width2} basis={24.8}/>
                        </div>
                    </div>
                </div>
                <hr/>
                <div className="body-content container">
                    <div className="row justify-content-between">
                        <div id="section-about-1" className="section-wrapper col-12 col-lg-7">
                            <div id="section-vds" className="section-vds d-flex align-items-center">
                                <div id="section-title" className="section col-lg-3 text-center">
                                    <div className="index fw-bold fs-5">03</div>
                                </div>
                                <div id="anchor" className="col-lg-9 uk-visible@m">
                                    <Section3 img={img10} color="#FEF3B8"/>
                                </div>
                            </div>
                        </div>
                        <div id="anchor" className="uk-hidden@m">
                            <Section3 img={img10} color="#FEF3B8"/>
                        </div>
                        <div className="section-right col-lg-4">
                            <div>
                                <div className="fw-bold">{t('h9-text/about')}</div>
                                <div className="mt-2">
                                    {t('h10-text/about')}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <hr/>
                <div className="body-content container">
                    <div className="row justify-content-between align-items-center">
                        <div id="section-about-2" className="section-wrapper col-lg-7">
                            <div id="section-vds" className="section-vds d-flex align-items-center">
                                <div id="section-title" className="section col-lg-3 text-center">
                                    <div className="index fw-bold fs-5">04</div>
                                </div>
                                <div className="section-right col-lg-8 uk-visible@m">
                                    <div className="fw-bold">{t('h11-text/about')}</div>
                                    <div className="mt-2">
                                        {t('h12-text/about')}
                                    </div>
                                </div>
                            </div>
                            <div className="uk-hidden@m">
                                <Section1 img1={img9} img2={img9} img3={img9} width={width2} radius1="24px"
                                          radius2="24px" radius3="120px"/>
                            </div>
                            <div className="section-right uk-hidden@m">
                                <div className="fw-bold">{t('h11-text/about')}</div>
                                <div className="mt-2">
                                    {t('h12-text/about')}
                                </div>
                            </div>
                        </div>
                        <div id="anchor" className="col-lg-5 uk-visible@m">
                            <Section1 img1={img9} img2={img9} img3={img9} width={width2} radius1="24px" radius2="24px"
                                      radius3="120px"/>
                        </div>
                    </div>
                </div>
                <TabBarBottom/>
            </div>
        </>
    )
        ;
};

export default React.memo(AboutPageView);
