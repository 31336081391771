import {TAppDispatch, IAppAction, ICategoryItem, ISearchItem, ICategoryList} from '@types';
import axiosInstance from "@utils/axiosInstance";

export const UNLOCK_CATEGORY_RESET = 'category.UNLOCK_CATEGORY_RESET';
export const UNLOCK_CATEGORY_REQUEST = 'category.UNLOCK_CATEGORY_REQUEST';
export const UNLOCK_CATEGORY_SUCCESS = 'category.UNLOCK_CATEGORY_SUCCESS';
export const UNLOCK_CATEGORY_FAILURE = 'category.UNLOCK_CATEGORY_FAILURE';

export const LOCK_CATEGORY_RESET = 'category.LOCK_CATEGORY_RESET';
export const LOCK_CATEGORY_REQUEST = 'category.LOCK_CATEGORY_REQUEST';
export const LOCK_CATEGORY_SUCCESS = 'category.LOCK_CATEGORY_SUCCESS';
export const LOCK_CATEGORY_FAILURE = 'category.LOCK_CATEGORY_FAILURE';

export const UPDATE_CATEGORY_RESET = 'category.UPDATE_CATEGORY_RESET';
export const UPDATE_CATEGORY_REQUEST = 'category.UPDATE_CATEGORY_REQUEST';
export const UPDATE_CATEGORY_SUCCESS = 'category.UPDATE_CATEGORY_SUCCESS';
export const UPDATE_CATEGORY_FAILURE = 'category.UPDATE_CATEGORY_FAILURE';

export const CREATE_CATEGORY_RESET = 'category.CREATE_CATEGORY_RESET';
export const CREATE_CATEGORY_REQUEST = 'category.CREATE_CATEGORY_REQUEST';
export const CREATE_CATEGORY_SUCCESS = 'category.CREATE_CATEGORY_SUCCESS';
export const CREATE_CATEGORY_FAILURE = 'category.CREATE_CATEGORY_FAILURE';

export const GET_LIST_CATEGORY = 'categories.GET_LIST_CATEGORY';

export const GET_ALL_CATEGORIES = 'category.GET_ALL_CATEGORIES';
export const unlockReset = () => ({
    type: UNLOCK_CATEGORY_RESET,
    payload: ""
});
export const unlockRequest = () => ({
    type: UNLOCK_CATEGORY_REQUEST,
    payload: ""
});

export const unlockSuccess = (data: ICategoryItem) => ({
    type: UNLOCK_CATEGORY_SUCCESS,
    payload: data,
});
export const unlockFailure = (error: any) => ({
    type: UNLOCK_CATEGORY_FAILURE,
    payload: error,
});

export const lockReset = () => ({
    type: LOCK_CATEGORY_RESET,
    payload: ""
});
export const lockRequest = () => ({
    type: LOCK_CATEGORY_REQUEST,
    payload: ""
});

export const lockSuccess = (data: ICategoryItem) => ({
    type: LOCK_CATEGORY_SUCCESS,
    payload: data,
});
export const lockFailure = (error: any) => ({
    type: LOCK_CATEGORY_FAILURE,
    payload: error,
});
export const updateReset = () => ({
    type: UPDATE_CATEGORY_RESET,
    payload: ""
});
export const updateRequest = () => ({
    type: UPDATE_CATEGORY_REQUEST,
    payload: ""
});

export const updateSuccess = (data: ICategoryItem) => ({
    type: UPDATE_CATEGORY_SUCCESS,
    payload: data,
});

export const updateFailure = (error: any) => ({
    type: UPDATE_CATEGORY_FAILURE,
    payload: error,
});
export const createReset = () => ({
    type: CREATE_CATEGORY_RESET,
    payload: ""
});
export const createRequest = () => ({
    type: CREATE_CATEGORY_REQUEST,
    payload: ""
});

export const createSuccess = (data: ICategoryItem) => ({
    type: CREATE_CATEGORY_SUCCESS,
    payload: data,
});

export const createFailure = (error: any) => ({
    type: CREATE_CATEGORY_FAILURE,
    payload: error,
});
export const unlockCategory = (id: string) => async (dispatch: TAppDispatch) => {
    dispatch(unlockRequest());
    try {
        const response = await axiosInstance.post('/category/unlock?id=' + id, "");
        dispatch(unlockSuccess(response.data));
    } catch (error: any) {
        dispatch(unlockFailure(error.data));
    }
};
export const lockCategory = (id: string) => async (dispatch: TAppDispatch) => {
    dispatch(lockRequest());
    try {
        const response = await axiosInstance.post('/category/lock?id=' + id, "");
        dispatch(lockSuccess(response.data));
    } catch (error: any) {
        dispatch(lockFailure(error.data));
    }
};
export const updateCategory = (newCategory: ICategoryItem) => async (dispatch: TAppDispatch) => {
    dispatch(updateRequest());
    try {
        const response = await axiosInstance.post('/category/update', newCategory);
        dispatch(updateSuccess(response.data));
    } catch (error: any) {
        dispatch(updateFailure(error.data));
    }
};
export const createCategory = (newCategory: ICategoryItem) => async (dispatch: TAppDispatch) => {
    dispatch(createRequest());
    try {
        const response = await axiosInstance.post('/category/create', newCategory);
        dispatch(createSuccess(response.data));
    } catch (error: any) {
        dispatch(createFailure(error.response.data));
    }
};
const searchCategory = (search: ISearchItem) => async (dispatch: TAppDispatch<IAppAction<ICategoryList>>) => {
    try {
        // Make an API call to get all categories
        const response = await axiosInstance.post('/category/search', search);
        const categories: ICategoryList = response.data.data;
        // Dispatch an action with the categories as the payload
        dispatch({
            type: GET_LIST_CATEGORY,
            payload: categories,
        });
    } catch (error) {
        // Handle the error if the API call fails
        console.error(error);
    }
};
const getAllCategory = () => async (dispatch: TAppDispatch<IAppAction<ICategoryItem[]>>) => {
    try {
        const response = await axiosInstance.get('/category/getAll');
        const categories: ICategoryItem[] = response.data.data;

        // Dispatch an action with the categories as the payload
        dispatch({
            type: GET_ALL_CATEGORIES,
            payload: categories,
        });
    } catch (error) {
        // Handle the error if the API call fails
        console.error(error);
    }
};

export const categoryActions = {
    getAllCategory,
    updateCategory,
    updateReset, createCategory,
    createReset,
    lockCategory,
    unlockCategory,
    lockReset,
    unlockReset, searchCategory
};
