import React, {useEffect, useState} from 'react';
import {IAppState, ICategoryItem} from "@types";
import {useDispatch, useSelector} from "react-redux";
import {categoryActions} from "@redux/actions/category.actions";
import ModalConfirm from "../ModalConfirm/ModalConfirm";

const ModalCategory = ({closeModal, category, type}: any) => {
    const dispatch = useDispatch();
    const [newCategory, setNewCategory] = useState<ICategoryItem>(type === "update" ? category : {
        code: "",
        name: "",
        index: 0,
        description: "",
        createdDate: new Date()
    });
    const [error, setError] = useState<string>("");
    const [isVisibleModalConfirm, setIsVisibleModalConfirm] = useState<boolean>(false);

    const categoryUpdateState = useSelector((state: IAppState) => state.categoryUpdate);
    const categoryCreateState = useSelector((state: IAppState) => state.categoryCreate);
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setNewCategory(account => ({
            ...account,
            [name]: value ? value : ""
        }))
    };

    const onSubmitChange = () => {
        if (type === "update") {
            if (newCategory.name.length === 0) {
                setError("Tên danh mục không được để trống");
                return;
            } else {
                setError("");
                dispatch(categoryActions.updateCategory(newCategory));
            }
        } else {
            if (newCategory.code.length === 0) {
                setError("Mã danh mục không được để trống");
                return;
            } else if (newCategory.name.length === 0) {
                setError("Tên danh mục không được để trống");
                return;
            } else {
                setError("");
                dispatch(categoryActions.createCategory(newCategory));
            }
        }
    }
    useEffect(() => {
        if (type === "update") {
            if (categoryUpdateState.code === "200") {
                openModalConfirm();
            } else {
                setError(categoryUpdateState.error);
            }
        } else {
            if (categoryCreateState.code === "201") {
                openModalConfirm();
            } else {
                setError(categoryCreateState.error);
            }
        }
    }, [categoryUpdateState, categoryCreateState]);

    const openModalConfirm = () => {
        setIsVisibleModalConfirm(true);
    }

    const closeModalConfirm = () => {
        closeModal();
        dispatch(categoryActions.updateReset());
        dispatch(categoryActions.createReset());
        setIsVisibleModalConfirm(false);
    }
    return (
        <>
            <div className="swal2-container swal2-center swal2-fade swal2-shown"
                 style={{overflowY: "auto"}}>
                <div aria-labelledby="swal2-title" aria-describedby="swal2-content"
                     className="swal2-popup swal2-modal swal2-show" tabIndex={-1} role="dialog" aria-live="assertive"
                     aria-modal="true" style={{width: "600px", display: "flex"}}>
                    <div className="swal2-header">
                        <h2 className="swal2-title" id="swal2-title"
                            style={{display: "flex"}}>{type === "update" ? "Cập nhật thông tin danh mục!" : "Thêm mới danh mục!"}</h2>
                    </div>
                    <div className="swal2-content">
                        <div id="swal2-content" style={{display: "block"}}>
                            {(categoryUpdateState.error.trim().length !== 0 || error.trim().length !== 0)
                            && <div className="alert alert-danger alert-dismissable">
                                <i className="fa fa-fw fa-exclamation-triangle me-2"/>
                                {categoryUpdateState.error}
                                {error}
                            </div>}
                            <div className="form-floating mb-4">
                                <input disabled={type === "update"} type="text" className="form-control"
                                       id="login-categoryname"
                                       name="code"
                                       value={newCategory.code}
                                       onChange={handleInputChange}/>
                                <label className="form-label" htmlFor="login-categoryname">Mã danh mục</label>
                            </div>
                            <div className="form-floating mb-4">
                                <input type="text" className="form-control" id="login-password"
                                       name="name"
                                       value={newCategory.name}
                                       onChange={handleInputChange}/>
                                <label className="form-label" htmlFor="login-password">Tên danh mục</label>
                            </div>
                            <div className="form-floating mb-4">
                                <input type="number" maxLength={2} inputMode="numeric" className="form-control"
                                       id="login-password"
                                       name="index"
                                       value={newCategory.index}
                                       onChange={handleInputChange}/>
                                <label className="form-label" htmlFor="login-password">Thứ tự hiển thị</label>
                            </div>
                            <div className="form-floating mb-4">
                                <input type="text" className="form-control" id="login-password"
                                       name="description"
                                       value={newCategory.description}
                                       onChange={handleInputChange}/>
                                <label className="form-label" htmlFor="login-password">Mô tả</label>
                            </div>

                        </div>

                    </div>
                    <div className="swal2-actions" style={{display: "flex"}}>
                        <button type="button" onClick={onSubmitChange}
                                className="swal2-confirm btn btn-confirm mt-2 swal2-styled"
                                style={{
                                    display: "inline-block",
                                    borderLeftColor: "rgb(48, 133, 214)",
                                    borderRightColor: "rgb(48, 133, 214)"
                                }} aria-label=""><i className="mdi mdi-thumb-up-outline"/> Lưu thay đổi
                        </button>
                        &nbsp;&nbsp;
                        <button type="button" onClick={closeModal}
                                className="swal2-cancel btn btn-alt-warning mt-2 swal2-styled"
                                style={{
                                    display: "inline-block",
                                    borderLeftColor: "rgb(48, 133, 214)",
                                    borderRightColor: "rgb(48, 133, 214)"
                                }}
                                aria-label=""><i className="mdi mdi-thumb-up-outline"/> Đóng
                        </button>

                    </div>
                </div>
            </div>
            {isVisibleModalConfirm &&
            <ModalConfirm closeModal={closeModalConfirm}
                          content={type === "update" ? "Cập nhật thành công" : "Thêm mới thành công"}/>}
        </>
    );
}

export default React.memo(ModalCategory);
