import {TAppDispatch} from '@types';
import {IUserItem} from "@types";
import axiosInstance from "@utils/axiosInstance";
import {ILoginUser} from "../../types/state/login";

export const LOGIN_REQUEST = 'login.LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'login.LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'login.LOGIN_FAILURE';

export const loginRequest = () => ({
    type: LOGIN_REQUEST,
    payload: ""
});

export const loginSuccess = (data: IUserItem) => ({
    type: LOGIN_SUCCESS,
    payload: data,
});

export const loginFailure = (error: any) => ({
    type: LOGIN_FAILURE,
    payload: error,
});

export const loginUser = (loginItem: ILoginUser) => async (dispatch: TAppDispatch) => {
    dispatch(loginRequest());
    try {
        const response = await axiosInstance.post('/auth/login', loginItem);
        dispatch(loginSuccess(response.data.data));
    } catch (error) {
        dispatch(loginFailure("Tài khoản hoặc mật khẩu không đúng"));
    }
};
export const loginActions = {loginFailure, loginSuccess, loginRequest, loginUser};
