import React from 'react';
import "./Input3.scss";
import warning from "@assets/images/icon-warning.png";
import {IonIcon} from "@ionic/react";

interface InputProps {
    name: string;
    type: string;
    label: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    required: boolean;
    icon: any;
    colorIcon?: string;
    placeholder: string;
    value: string;
    error: string;
    maxLength?: number;
    minLength?: number;
}

const Input3: React.FC<InputProps> = ({
                                          name,
                                          type,
                                          label,
                                          onChange,
                                          icon,
                                          colorIcon,
                                          required,
                                          placeholder,
                                          value,
                                          error,
                                          minLength,
                                          maxLength
                                      }) => {
    return (
        <>
            <div className={`item__24 ${error ? 'mb-5' : 'mb-3'}`}>
                <label htmlFor={name} className="uk-form-label home__banner__label">{label} {required &&
                <span>(<span className="fire_engine_red">*</span>)</span>}</label>
                <div className="uk-form-controls">
                    <div className="uk-inline uk-width-1-1">
                        <IonIcon style={{color: colorIcon}}
                                 className="uk-form-icon home__banner__icon home__banner__icon--user uk-icon"
                                 icon={icon}/>
                        <input id={name}
                               type={type}
                               name={name}
                               onChange={onChange}
                               value={value}
                               placeholder={placeholder}
                               minLength={minLength}
                               maxLength={maxLength}
                               className="uk-input home__banner__input"
                               autoComplete="off"/>
                    </div>
                </div>
                {error &&
                <p className="error_show">
                    <img src={warning} alt={"warning"}/>
                    {error}
                </p>
                }
            </div>
        </>
    )
};

export default React.memo(Input3);
