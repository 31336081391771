import React from 'react';
import "./Input1.scss";
import warning from "@assets/images/icon-warning.png";
import {IonIcon} from "@ionic/react";

interface InputProps {
    name: string;
    type: string;
    label: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    required: boolean;
    icon?: any;
    inputMode?: any;
    placeholder: string;
    value: string;
    error: string;
    maxLength?: number;
    minLength?: number;
}

const Input1: React.FC<InputProps> = ({
                                          name,
                                          type,
                                          label,
                                          onChange,
                                          icon,
                                          inputMode,
                                          required,
                                          placeholder,
                                          value,
                                          error,
                                          minLength,
                                          maxLength
                                      }) => {
    return (
        <>
            <div
                className={`input-group input-group-outline mot ${error ? 'mb-5' : 'mb-2'} ${value ? 'is-focused' : ''}`}>
                <IonIcon className="icon-input-1" icon={icon}/>
                {label && <label htmlFor={name} className="form-label-1">{label} {required &&
                <span>(<span className="fire_engine_red">*</span>)</span>}
                </label>}

                <input id={name}
                       type={type}
                       inputMode={inputMode}
                       name={name}
                       onChange={onChange}
                       value={value}
                       required={true}
                       placeholder={placeholder}
                       minLength={minLength}
                       maxLength={maxLength}
                       className="form-control input-1 w-100"
                       autoComplete="off"/>
                {error &&
                <p className="error_show">
                    <img src={warning} alt={"warning"}/>
                    {error}
                </p>
                }
            </div>
        </>
    )
};

export default React.memo(Input1);
