import {combineReducers} from 'redux';
import {
    categoryCreateReducer,
    categoryListReducer,
    categoryLockReducer,
    categoryReducer,
    categoryUnlockReducer,
    categoryUpdateReducer
} from "./category.reducer";
import {loginReducer} from "./login.reducer";
import {
    itemCreateReducer,
    itemListReducer,
    itemLockReducer,
    itemReducer,
    itemUnlockReducer,
    itemUpdateReducer
} from "./item.reducer";
import {userListReducer, userLockReducer, userReducer, userUnlockReducer, userUpdateReducer} from "./user.reducer";

export default combineReducers({
    categories: categoryReducer,
    login: loginReducer,
    items: itemReducer,
    user: userReducer,
    userUpdate: userUpdateReducer,
    userLock: userLockReducer,
    userUnlock: userUnlockReducer,
    users: userListReducer,
    categoryUpdate: categoryUpdateReducer,
    categoryCreate: categoryCreateReducer,
    categoryLock: categoryLockReducer,
    categoryUnlock: categoryUnlockReducer,
    categoryList: categoryListReducer,
    itemList: itemListReducer,
    itemUpdate: itemUpdateReducer,
    itemCreate: itemCreateReducer,
    itemLock: itemLockReducer,
    itemUnlock: itemUnlockReducer,
});
