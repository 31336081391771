import React from 'react';
import "./Section.scss";

const Section5 = ({img1, img2, img3, width,basis}: any) => {

    return (
        <div id="section-image" className="section-image-5">
            <section className="image-canvas" style={{height: width}}>
                <div className="image-section one" style={{
                    borderRadius: "24px"
                }}>
                    <img style={{
                        width: width * 2,
                        left: `calc(-1 * ((${width * 2}px / (100/${basis}))) * 0)`
                    }} src={img1} alt="Partition 1"/>
                </div>
                <div className="image-section two" style={{
                    borderRadius: "24px"
                }}>
                    <img style={{
                        width: width * 2,
                        left: `calc(-1 * ((${width * 2}px / (100/${basis}))) * 1)`
                    }} src={img2} alt="Partition 2"/>
                </div>
                <div className="image-section three" style={{
                    borderRadius: "100%"
                }}>
                    <img style={{
                        width: width * 2,
                        left: `calc(-1 * ((${width * 2}px / (100/${basis}))) * 2)`
                    }} src={img3} alt="Partition 3"/>
                </div>
            </section>

            {/*<div className="circle"/>*/}
        </div>
    );
};

export default React.memo(Section5);
