import React, {PropsWithChildren} from 'react';
import './AppLayout.scss';
import {useLocation} from "react-router-dom";
import {AppFooter, AppHeader} from "../index";

const AppLayout = ({children}: PropsWithChildren<{}>) => {
    const location = useLocation();
    const showFooter = location.pathname !== '/lien-he' && location.pathname !== '/san-pham-lien-ket';
    return (
        <>
            <div id="app" className="app">
                <AppHeader/>
                {children}
            </div>
            {showFooter && <AppFooter/>}
        </>
    );
};

export default AppLayout;
