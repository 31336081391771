import React, {useEffect, useState} from 'react';
import './LoginPageView.scss';
import {IAppState, ILoginUser} from "@types";
import {useDispatch, useSelector} from "react-redux";
import logo from "@assets/images/logo.png"
import {loginActions} from "@redux/actions/login.actions";
import {persistor} from "../../../redux/configurestore";

const LoginPageView = () => {
    const dispatch = useDispatch();
    const [loginItem, setLoginItem] = useState<ILoginUser>({
        username: "",
        roles: [],
        password: ""
    });
    const [error, setError] = useState("");
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setLoginItem(account => ({
            ...account,
            [name]: value ? value : ""
        }))
    };

    const loginState = useSelector((state: IAppState) => state.login);

    useEffect(() => {
        if (loginState.error) {
            setError(loginState.error)
            persistor.purge().then(r => r);
        }
    }, [loginState])

    if (loginState.isLogin) {
        window.location.replace("/admin/quan-ly-san-pham");
        return null;
    }

    const handleLoginSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if (loginItem.username.trim() === "") {
            setError("Tài khoản không được để trống");
            return;
        } else if (loginItem.password.trim() === "") {
            setError("Mật khẩu không được để trống");
            return;
        } else {
            setError("")
            dispatch(loginActions.loginUser(loginItem));
        }
    };

    return (
        <div id="login-page">
            <div className="row mx-0 justify-content-center">
                <div className="hero-static col-lg-6 col-xl-4">
                    <div className="content content-full overflow-hidden">
                        <div className="py-4 text-center">
                            <a className="link-fx fw-bold" href="/">
                                <img src={logo} alt="logo" style={{width: "100px"}}/>
                            </a>
                        </div>
                        <form className="js-validation-signin">
                            <div className="block block-themed block-rounded block-fx-shadow">
                                <div className="block-header bg-gd-dusk">
                                    <h3 className="block-title">ĐĂNG NHẬP HỆ THỐNG</h3>
                                </div>
                                <div className="block-content">
                                    {error
                                    && <div className="alert alert-danger alert-dismissable">
                                        <i className="fa fa-fw fa-exclamation-triangle me-2"/>
                                        {error}
                                    </div>}

                                    <div className="form-floating mb-4">
                                        <input type="text" className="form-control" id="login-username" name="username"
                                               value={loginItem.username} onChange={handleInputChange}/>
                                        <label className="form-label" htmlFor="login-username">Tài khoản</label>
                                    </div>
                                    <div className="form-floating mb-4">
                                        <input type="password" className="form-control" id="login-password"
                                               name="password" value={loginItem.password} onChange={handleInputChange}/>
                                        <label className="form-label" htmlFor="login-password">Mật khẩu</label>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-12 text-center push">
                                            <button disabled={loginState.loading}
                                                    onClick={handleLoginSubmit}
                                                    type="button"
                                                    className="btn btn-lg btn-alt-primary fw-medium">
                                                 <span
                                                     style={{display: loginState.loading ? "block" : "none"}}><i
                                                     className="fa fa-spinner fa-spin"/></span>
                                                <span style={{display: !loginState.loading ? "block" : "none"}}>Đăng nhập</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default React.memo(LoginPageView);
