import {createReducer} from "@utils/reducer";
import {IAppAction, IItemSearch, IItemState, IItemUpdateState} from "@types";
import immutable from "seamless-immutable";
import {GET_ALL_ITEMS, GET_LIST_ITEM} from "@redux/actions/item.actions";
import {
    CREATE_ITEM_FAILURE,
    CREATE_ITEM_REQUEST,
    CREATE_ITEM_RESET,
    CREATE_ITEM_SUCCESS,
    LOCK_ITEM_FAILURE,
    LOCK_ITEM_REQUEST,
    LOCK_ITEM_RESET,
    LOCK_ITEM_SUCCESS,
    UNLOCK_ITEM_FAILURE,
    UNLOCK_ITEM_REQUEST,
    UNLOCK_ITEM_RESET,
    UNLOCK_ITEM_SUCCESS,
    UPDATE_ITEM_FAILURE,
    UPDATE_ITEM_REQUEST,
    UPDATE_ITEM_RESET,
    UPDATE_ITEM_SUCCESS
} from "@redux/actions/item.actions";

const initialUpdateState = immutable<IItemUpdateState>(<IItemUpdateState>{
    item: {}, loading: false, error: "", code: ""
});
const initialState = immutable<IItemState>({
    items: {}
});
const initialStateAdmin = immutable<IItemSearch>(<IItemSearch>{
    itemList: {},
});
export const itemUnlockReducer = createReducer<IAppAction, typeof initialUpdateState>(
    initialUpdateState,
    {
        [UNLOCK_ITEM_RESET]: (state) => {
            if (!immutable.isImmutable(state)) {
                state = immutable(state);
            }
            return state
                .setIn(['loading'], false)
                .setIn(['code'], "")
                .setIn(['error'], "");
        },
        [UNLOCK_ITEM_REQUEST]: (state) => {
            if (!immutable.isImmutable(state)) {
                state = immutable(state);
            }
            return state
                .setIn(['loading'], true)
                .setIn(['error'], "");
        },
        [UNLOCK_ITEM_SUCCESS]: (state, action) => {
            if (!immutable.isImmutable(state)) {
                state = immutable(state);
            }
            return state
                .setIn(['loading'], false)
                .setIn(['code'], action.payload.code)
                .setIn(['item'], action.payload.data)
                .setIn(['error'], "");
        },

        [UNLOCK_ITEM_FAILURE]: (state, action) => {
            if (!immutable.isImmutable(state)) {
                state = immutable(state);
            }
            return state
                .setIn(['loading'], false)
                .setIn(['error'], action.payload);
        },
    }
);

export const itemLockReducer = createReducer<IAppAction, typeof initialUpdateState>(
    initialUpdateState,
    {
        [LOCK_ITEM_RESET]: (state) => {
            if (!immutable.isImmutable(state)) {
                state = immutable(state);
            }
            return state
                .setIn(['loading'], false)
                .setIn(['code'], "")
                .setIn(['error'], "");
        },
        [LOCK_ITEM_REQUEST]: (state) => {
            if (!immutable.isImmutable(state)) {
                state = immutable(state);
            }
            return state
                .setIn(['loading'], true)
                .setIn(['error'], "");
        },
        [LOCK_ITEM_SUCCESS]: (state, action) => {
            if (!immutable.isImmutable(state)) {
                state = immutable(state);
            }
            return state
                .setIn(['loading'], false)
                .setIn(['code'], action.payload.code)
                .setIn(['item'], action.payload.data)
                .setIn(['error'], "");
        },

        [LOCK_ITEM_FAILURE]: (state, action) => {
            if (!immutable.isImmutable(state)) {
                state = immutable(state);
            }
            return state
                .setIn(['loading'], false)
                .setIn(['error'], action.payload);
        },
    }
);
export const itemCreateReducer = createReducer<IAppAction, typeof initialUpdateState>(
    initialUpdateState,
    {
        [CREATE_ITEM_RESET]: (state) => {
            if (!immutable.isImmutable(state)) {
                state = immutable(state);
            }
            return state
                .setIn(['loading'], false)
                .setIn(['code'], "")
                .setIn(['error'], "");
        },
        [CREATE_ITEM_REQUEST]: (state) => {
            if (!immutable.isImmutable(state)) {
                state = immutable(state);
            }
            return state
                .setIn(['loading'], true)
                .setIn(['error'], "");
        },
        [CREATE_ITEM_SUCCESS]: (state, action) => {
            if (!immutable.isImmutable(state)) {
                state = immutable(state);
            }
            return state
                .setIn(['loading'], false)
                .setIn(['code'], action.payload.code)
                .setIn(['item'], action.payload.data)
                .setIn(['error'], "");
        },

        [CREATE_ITEM_FAILURE]: (state, action) => {
            if (!immutable.isImmutable(state)) {
                state = immutable(state);
            }
            return state
                .setIn(['loading'], false)
                .setIn(['error'], action.payload);
        },
    }
);

export const itemUpdateReducer = createReducer<IAppAction, typeof initialUpdateState>(
    initialUpdateState,
    {
        [UPDATE_ITEM_RESET]: (state) => {
            if (!immutable.isImmutable(state)) {
                state = immutable(state);
            }
            return state
                .setIn(['loading'], false)
                .setIn(['code'], "")
                .setIn(['error'], "");
        },
        [UPDATE_ITEM_REQUEST]: (state) => {
            if (!immutable.isImmutable(state)) {
                state = immutable(state);
            }
            return state
                .setIn(['loading'], true)
                .setIn(['error'], "");
        },
        [UPDATE_ITEM_SUCCESS]: (state, action) => {
            if (!immutable.isImmutable(state)) {
                state = immutable(state);
            }
            return state
                .setIn(['loading'], false)
                .setIn(['code'], action.payload.code)
                .setIn(['item'], action.payload.data)
                .setIn(['error'], "");
        },

        [UPDATE_ITEM_FAILURE]: (state, action) => {
            if (!immutable.isImmutable(state)) {
                state = immutable(state);
            }
            return state
                .setIn(['loading'], false)
                .setIn(['error'], action.payload);
        },
    }
);
export const itemListReducer = createReducer<IAppAction, typeof initialStateAdmin>(
    initialStateAdmin,
    {
        [GET_LIST_ITEM]: (state, action) => {
            // If state is not immutable, convert it to immutable
            if (!immutable.isImmutable(state)) {
                state = immutable(state);
            }
            return state
                .setIn(['itemList'], action.payload);
        },
    }
);
export const itemReducer = createReducer<IAppAction, typeof initialState>(initialState, {
    [GET_ALL_ITEMS]: (state, action) => {
        if (!immutable.isImmutable(state)) {
            state = immutable(state);
        }
        return state.setIn(['items'], action.payload);
    }
});
