import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {IAppState} from "@types";
import {categoryActions} from "@redux/actions/category.actions";
import {itemActions} from "@redux/actions/item.actions";
import axiosInstance from "@utils/axiosInstance";

const ModalLockOrUnlock = ({closeModal, id, type}: any) => {
    const dispatch = useDispatch();
    const [content, setContent] = useState("");
    const [isSuccess, setIsSuccess] = useState<boolean>(false);

    const categoryLockState = useSelector((state: IAppState) => state.categoryLock);
    const categoryUnlockState = useSelector((state: IAppState) => state.categoryUnlock);
    const itemLockState = useSelector((state: IAppState) => state.itemLock);
    const itemUnlockState = useSelector((state: IAppState) => state.itemUnlock);

    useEffect(() => {
        if (type === "removeCategory") {
            setContent("Bạn có muốn xoá danh mục này không?");
        } else if (type === "removeItem") {
            setContent("Bạn có muốn xoá sản phẩm này không?");
        } else if (type === "lockCategory") {
            setContent("Bạn có khoá danh mục này?");
        } else if (type === "unlockCategory") {
            setContent("Bạn có muốn mở khoá danh mục này?");
        } else if (type === "lockItem") {
            setContent("Bạn có muốn khoá sản phẩm này?");
        } else if (type === "unlockItem") {
            setContent("Bạn có muốn mở khoá sản phẩm này?");
        }
    }, [])

    const onSubmitLockOrUnlock = () => {
        if (type === "removeCategory") {
            axiosInstance.post("/category/remove?id=" + id, "").then(response => {
                if (response.data.code === "200") {
                    setContent("Xoá danh mục thành công");
                    setIsSuccess(true);
                }
            }).catch(error => {
                console.log(error);
            })
        } else if (type === "removeItem") {
            axiosInstance.post("/item/remove?id=" + id, "").then(response => {
                if (response.data.code === "200") {
                    setContent("Xoá sản phẩm thành công");
                    setIsSuccess(true);
                }
            }).catch(error => {
                console.log(error);
            })
        } else if (type === "lockCategory") {
            dispatch(categoryActions.lockCategory(id));
        } else if (type === "unlockCategory") {
            dispatch(categoryActions.unlockCategory(id));
        } else if (type === "lockItem") {
            dispatch(itemActions.lockItem(id));
        } else if (type === "unlockItem") {
            dispatch(itemActions.unlockItem(id));
        }
    }
    useEffect(() => {
        if (categoryLockState.code === "200") {
            setContent("Khoá danh mục thành công")
            dispatch(categoryActions.lockReset());
            setIsSuccess(true);
        } else if (categoryUnlockState.code === "200") {
            setContent("Mở khoá danh mục thành công")
            dispatch(categoryActions.unlockReset());
            setIsSuccess(true);
        } else if (itemLockState.code === "200") {
            setContent("Khoá sản phẩm thành công")
            dispatch(itemActions.lockReset());
            setIsSuccess(true);
        } else if (itemUnlockState.code === "200") {
            setContent("Mở khoá sản phẩm thành công")
            dispatch(itemActions.unlockReset());
            setIsSuccess(true);
        }
    }, [categoryLockState, categoryUnlockState, itemUnlockState, itemLockState])

    return (
        <>
            <div className="swal2-container swal2-center swal2-fade swal2-shown"
                 style={{overflowY: "auto"}}>
                <div aria-labelledby="swal2-title" aria-describedby="swal2-content"
                     className="swal2-popup swal2-modal swal2-show" tabIndex={-1} role="dialog" aria-live="assertive"
                     aria-modal="true" style={{display: "flex"}}>
                    {isSuccess &&
                    <div className="swal2-header">
                        <h2 className="swal2-title" id="swal2-title" style={{display: "flex"}}>THÀNH CÔNG</h2>
                        <div className="swal2-icon swal2-success">
                            <div className="swal2-success-circular-line-left"/>
                            <span className="swal2-success-line-tip"/>
                            <span className="swal2-success-line-long"/>
                            <div className="swal2-success-ring"/>
                            <div className="swal2-success-fix"/>
                            <div className="swal2-success-circular-line-right"/>
                        </div>
                    </div>}
                    <div className="swal2-content">
                        <div id="swal2-content" style={{
                            display: "block", whiteSpace: "pre-wrap",
                            wordWrap: "break-word"
                        }}>
                            <p>{content}</p>
                        </div>
                        <output/>
                    </div>
                    <div className="swal2-actions" style={{display: "flex"}}>
                        <button type="button" onClick={isSuccess ? closeModal : onSubmitLockOrUnlock}
                                className="swal2-confirm btn btn-confirm mt-2 swal2-styled"
                                style={{
                                    display: "inline-block",
                                    borderLeftColor: "rgb(48, 133, 214)",
                                    borderRightColor: "rgb(48, 133, 214)"
                                }}
                                aria-label="">OK
                        </button>
                        &nbsp;&nbsp;
                        <button type="button" onClick={closeModal}
                                className="swal2-cancel btn btn-confirm mt-2 swal2-styled"
                                style={{
                                    display: "inline-block",
                                    borderLeftColor: "rgb(48, 133, 214)",
                                    borderRightColor: "rgb(48, 133, 214)"
                                }}
                                aria-label="">Đóng
                        </button>
                    </div>
                </div>

            </div>
        </>
    );
}

export default React.memo(ModalLockOrUnlock);
