import React  from 'react';
import "./Section.scss";

const Section1 = ({img1, img2, img3, radius1, radius2, radius3, width}: any) => {

    return (
        <div id="section-image" className={`section-image-1`}>
            <section className="image-canvas" style={{height: width}}>
                <div className="image-section one" style={{
                    borderRadius: radius1
                }}>
                    <img style={{
                        width: width * 2,
                        height: "auto",
                        left: `calc(-1 * ((${width * 2}px / 3)) * 0)`
                    }} src={img1} alt="Partition 1"/>
                </div>
                <div className="image-section two" style={{
                    borderRadius: radius2
                }}>
                    <img style={{
                        width: width * 2,
                        height: "auto",
                        left: `calc(-1 * ((${width * 2}px / 3)) * 1)`
                    }} src={img2} alt="Partition 2"/>
                </div>
                <div className="image-section three" style={{
                    borderRadius: radius3
                }}>
                    <img style={{
                        width: width * 2,
                        height: "auto",
                        left: `calc(-1 * ((${width * 2}px / 3)) * 2)`
                    }} src={img3} alt="Partition 3"/>
                </div>
            </section>
        </div>
    );
};

export default React.memo(Section1);
