export default {
    'language/header': 'Languages',
    'about/header': 'About',
    'service/header': 'Service',
    'product/header': 'Affiliate Products',
    'contact/header': 'Contact Us',
    'h1/home': 'Who are you?',
    'h1-text/home': 'VAN DIGITAL เป็นรูปแบบเอเจนซี่ให้บริการโซลูชั่นการสื่อสารแบบครบวงจรสำหรับธุรกิจในหลายด้านในตลาดประเทศไทย.',
    'h2-text/home': 'บริการโซลูชั่นที่เรานำเสนอประกอบด้วย',
    'hot-combo/home': 'คอมโบสุดฮอต',
    'hot-combo-h1/home': 'ซื้อ 1 แถม 3',
    'hot-combo-h2/home': 'เพิ่มยอดขายสูงสุด',
    'hot-combo-h3/home': 'คลิกที่นี่เพื่อดูรายละเอียด',
    'section1-text/home': 'การวางแผนเชิงกลยุทธ์: สำรวจตลาด สร้างแบรนด์ที่ยั่งยืน และเพิ่มรายได้ด้วย"แผนที่การวางแผนสื่อ" จาก VAN DIGITAL',
    'section2-text/home': 'เผยแพร่เรื่องราวที่คุณต้องการบอกลูกค้าด้วยรูปภาพและวิดีโอพร้อมคำแนะนำและการนำไปปฏิ บัติจาก VAN DIGITAL',
    'section3-text/home': 'การโฆษณาหลายแพลตฟอร์มพร้อมกระบวนการที่ได้มาตรฐานและปรับให้เหมาะสมเพื่อลดต้นทุนและเพิ่มการเข้าถึงให้มากที่สุด',
    'section4-text/home': 'กลยุทธ์การตลาดที่เน้นการกระจายสินค้าหลายช่องทางและหลายแพลตฟอร์มช่วยเพิ่มการรับรู้ของแบรนด์ สร้างการสนทนาที่มีชีวิตชีวาเพื่อสนับสนุนการขาย',
    'section5-text/home': 'เครือข่ายพันธมิตรสื่อชั้นนำของประเทศไทยจะช่วยให้คุณเพิ่มความน่าเชื่อถือและเข้าถึงลูกค้าหลายล้านคน',
    'section6-text/home': 'เป็นการยืม &quot;คำอธิบาย&quot; จาก KOL/KOC ที่มีชื่อเสียงและเชี่ยวชาญในแต่ละด้าน VAN DIGITAL จะช่วยให้การเล่าเรื่องราวแบรนด์ของคุณด้วยวิธีที่น่าสนใจและน่าเชื่อถือที่สุด',
    'section7-text/home': 'ในฐานะหนึ่งในAGENCYผู้บุกเบิกในด้านการแปลแพลตฟอร์มอีคอมเมิร์ซ VAN DIGITAL นำเสนอโซลูชั่นการตลาดที่ครอบคลุม คล่องตัว และมีประสิทธิภาพ',
    'section8-text/home': 'ความคล่องตัวและมีประสิทธิภาพ ตัวเลือกอันดับต้นๆ สำหรับผู้ขาย VAN DIGITAL มั่นใจว่าAgencyทำงานเหมือนเป็นแผนกขนาดเล็กช่วยพัฒนาแพลตฟอร์มอีคอมเมิร์ซสำหรับธุรกิจของคุณ โดยมีประสบการณ์ร่วมกับลูกค้ามากกว่า 1,000 รายในผลิตภัณฑ์ที่แตกต่างกันมากกว่า 200 รายการ',
    'section9-text/home': 'เพื่อเป็นสะพานเชื่อมระหว่างประเทศไทยกับประเทศนอกประเทศไทย “ข้ามแม่น้ำ เราก็สร้างสะพาน ข้ามภูเขา เราก็ทำอุโมงค์” ร่วมธุรกิจพัฒนาตลาดไทย…',
    'h1-text/footer': 'กรอกข้อมู',
    'h2-text/footer': 'เพื่อรับคำปรึกษา',
    'h3-text/footer': 'อีเมล',
    'h4-text/footer': 'เบอร์โทร',

    'section0-modal-h1/home': 'เมื่อลูกค้าใช้บริการ Facebook Ads ด้วยงบประมาณตั้งแต่:',
    'section0-modal-h1-text1/home': '50.000 บาท',
    'section0-modal-h1-text2/home': 'รับทันที:แพ็คเกจให้คำปรึกษาด้านการจัดการ + การ SEEDING (200 ไลค์/โพสต์ + 30 คอมเมนต์/โพสต์ X 15 โพสต์/เดือน)',
    'section0-modal-h1-text3/home': '90.000 บาท',
    'section0-modal-h1-text4/home': 'รับทันที: แพ็คเกจให้คำปรึกษาด้านการบริหารจัดการ + การ SEEDING (สคริปต์ Seeding แบบเจาะลึก + 350 ไลค์/โพสต์ + 50 คอมเมนต์/โพสต์ X 30 โพสต์/เดือน) + คูปอง 5,000 บาทสำหรับการผลิตเนื้อหา',
    'section0-modal-h1-text5/home': '150.000 บาท',
    'section0-modal-h1-text6/home': 'รับทันที:แพ็คเกจให้คำปรึกษาด้านการบริหารจัดการ +คูปอง 5,000 บาทสำหรับการ SEEDING +คูปอง 5,000 บาทสำหรับการผลิตเนื้อหา',
    'section0-modal-h1-text7/home': '600.000 บาท',
    'section0-modal-h1-text8/home': 'รับทันที: แพ็คเกจให้คำปรึกษาด้านการบริหารจัดการ + แพ็คเกจ SUPER SEEDING สุดพิเศษ + คูปอง 5,000 บาทสำหรับการผลิตเนื้อหา',
    'section0-modal-h2/home': 'เมื่อลูกค้าใช้บริการ Tiktok Ads ด้วยงบประมาณตั้งแต่:',
    'section0-modal-h2-text1/home': '85.000 บาท',
    'section0-modal-h2-text2/home': 'รับทันที: แพ็คเกจให้คำปรึกษาด้านการบริหารจัดการ + คูปอง15% สำหรับการผลิตภาพ + แพ็คเกจ Seeding (200 ไลค์/โพสต์ + 30 คอมเมนต์/โพสต์ X 15 โพสต์/เดือน)',

    'section1-modal-h1/home': 'การให้คำปรึกษาด้านการจัดการ',
    'section1-modal-h1-text1/home': 'ให้คำปรึกษาด้านกลยุทธ์การพัฒนาแบรนด์',
    'section1-modal-h1-text2/home': 'ให้คำปรึกษาการสร้างและพัฒนาช่อง Fanpage/Instagram',
    'section1-modal-h1-text3/home': 'ให้คำปรึกษาการสร้างและพัฒนาช่อง Tiktok',
    'section1-modal-h1-text4/home': '2,000 บาท/แพ็คเกจ',
    'section1-modal-h1-text5/home': '3,000 บาท/แพ็คเกจ',
    'section1-modal-h1-text6/home': '5,000 บาท/แพ็คเกจ',
    'section1-modal-h2/home': 'การผลิตสื่อสิ่งพิมพ์',
    'section1-modal-h2-text1/home': 'เขียนเนื้อหาเชิงสร้างสรรค์ &ตัดต่อ',
    'section1-modal-h2-text2/home': 'เขียนคำโฆษณาและตัดต่อ',
    'section1-modal-h2-text3/home': 'เขียนสคริปต์',
    'section1-modal-h2-text4/home': 'การออกแบบกราฟิก',
    'section1-modal-h2-text5/home': 'ถ่ายรูปภาพผลิตภัณฑ์',
    'section1-modal-h2-text6/home': 'ถ่าย- ตัดต่อวิดีโอ',
    'section1-modal-h2-text7/home': '1,000 บาท/โพสต์',
    'section1-modal-h2-text8/home': '600 บาท/โพสต์',
    'section1-modal-h2-text9/home': '3,500 บาท/สคริปต์',
    'section1-modal-h2-text10/home': '700 บาท/ไฟล์',
    'section1-modal-h2-text11/home': '1,000 บาท/ไฟล์',
    'section1-modal-h2-text12/home': 'เสนอราคาแบบละเอียดเมื่อลูกค้าต้องการ',

    'section2-modal-h1/home': 'วิดีโอความคิดเห็นของผู้ใช้',
    'section2-modal-h1-text1/home': 'แพ็คเกจรีวิว',
    'section2-modal-h1-text2/home': 'แพ็คเกจประกอบด้วย:',
    'section2-modal-h1-text3/home': '- ถ่ายทำสถานที่เกี่ยวข้องกับกิจกรรมประจำวันของนักแสดง',
    'section2-modal-h1-text4/home': '- รูปภาพรวมกับการลงเสียงประกอบภาพ',
    'section2-modal-h1-text5/home': '- ถ่ายทำ ตัดต่อ สินค้า Full HD',
    'section2-modal-h1-text6/home': 'แพ็คเกจไม่รวม:',
    'section2-modal-h1-text7/home': '- ไม่สามารถเลือกสถานที่ถ่ายทำตามคำขอของลูกค้า',
    'section2-modal-h1-text8/home': '- ล่าม (แปลภาษา)',
    'section2-modal-h1-text9/home': '- นักแสดงคนที่สองเป็นต้นไป',
    'section2-modal-h1-text10/home': '3,000บาท/คลิป',
    'section2-modal-h1-text11/home': 'แพ็คเกจพื้นฐาน',
    'section2-modal-h1-text12/home': 'แพ็คเกจประกอบด้วย:',
    'section2-modal-h1-text13/home': '- ถ่ายทำสถานที่เกี่ยวข้องกับกิจกรรมประจำวันของนักแสดง',
    'section2-modal-h1-text14/home': '- รูปภาพรวมกับการลงเสียงประกอบภาพ',
    'section2-modal-h1-text15/home': '- ถ่ายทำ ตัดต่อ สินค้า Full HD',
    'section2-modal-h1-text16/home': 'แพ็คเกจไม่รวม:',
    'section2-modal-h1-text17/home': '- ไม่สามารถเลือกสถานที่ถ่ายทำตามคำขอของลูกค้า',
    'section2-modal-h1-text18/home': '- ล่าม (แปลภาษา)',
    'section2-modal-h1-text19/home': '- นักแสดงคนที่สองเป็นต้นไป',
    'section2-modal-h1-text20/home': '4,500บาท/คลิป',
    'section2-modal-h1-text21/home': 'แพ็คเกจมาตรฐาน',
    'section2-modal-h1-text22/home': 'แพ็คเกจประกอบด้วย:',
    'section2-modal-h1-text23/home': '- ให้คำปรึกษามุมกล้องถ่าย',
    'section2-modal-h1-text24/home': '- สถานที่ถ่ายภาพ: สตูดิโอพร้อมการตั้งค่าพื้นฐาน',
    'section2-modal-h1-text25/home': '- รูปภาพรวมกับการลงเสียงประกอบภาพ',
    'section2-modal-h1-text26/home': '- ถ่ายทำ ตัดต่อ สินค้า Full HD',
    'section2-modal-h1-text27/home': 'แพ็คเกจไม่รวม:',
    'section2-modal-h1-text28/home': '- ไม่สามารถเลือกสถานที่ถ่ายทำตามคำขอของลูกค้า',
    'section2-modal-h1-text29/home': '- ล่าม (แปลภาษา)',
    'section2-modal-h1-text30/home': '- นักแสดงคนที่สองเป็นต้นไป',
    'section2-modal-h1-text31/home': '6.000บาท/คลิป',
    'section2-modal-h2/home': 'วิดีโอแนะนำผลิตภัณฑ์',
    'section2-modal-h2-text1/home': 'แพ็คเกจอัพเกรด',
    'section2-modal-h2-text2/home': 'แพ็คเกจประกอบด้วย:',
    'section2-modal-h2-text3/home': '- การสร้างและให้คำปรึกษาสคริปต์การถ่ายทำตามข้อกำหนดของสคริปต์เนื้อหา',
    'section2-modal-h2-text4/home': '- สถานที่ถ่ายทำ: สตูดิโอได้จัดเตรียมฉากไว้แล้ว',
    'section2-modal-h2-text5/home': '- พิธีกร/นักแสดง แนะนำการบันทึกเสียงสด',
    'section2-modal-h2-text6/home': '- ถ่ายทำ ตัดต่อ สินค้า Full HD',
    'section2-modal-h2-text7/home': 'แพ็คเกจไม่รวม:',
    'section2-modal-h2-text8/home': '- ค่าเช่าสถานที่นอกสตูดิโอ',
    'section2-modal-h2-text9/home': '- ล่ามที่สตูดิโอ',
    'section2-modal-h2-text10/home': '- นักแสดงคนที่ 3 เป็นต้นไป',
    'section2-modal-h2-text11/home': '9,000บาท/คลิป',
    'section2-modal-h3/home': 'TVC',
    'section2-modal-h3-text1/home': 'แพ็คเกจพรีเมี่ยม',
    'section2-modal-h3-text2/home': 'แพ็คเกจประกอบด้วย:',
    'section2-modal-h3-text3/home': '- สร้างและให้คำปรึกษาสคริปต์',
    'section2-modal-h3-text4/home': '- สถานที่ถ่ายภาพ: ไม่จำกัดบริบทที่เหมาะกับสคริปต์',
    'section2-modal-h3-text5/home': '- สถานที่ถ่ายภาพ: ไม่จำกัดบริบทที่เหมาะกับสคริปต์',
    'section2-modal-h3-text6/home': '- ถ่ายทำ ตัดต่อ สินค้า Full HD',
    'section2-modal-h3-text7/home': 'เสนอราคาแบบละเอียดเมื่อลูกค้าต้องการ',

    'section3-modal-h1/home': 'การโฆษณาหลายแพลตฟอร์ม',
    'section3-modal-h1-text1/home': 'โฆษณาเฟสบุ๊ค',
    'section3-modal-h1-text2/home': 'โฆษณา Google',
    'section3-modal-h1-text3/home': 'โฆษณา TIKTOK',
    'section3-modal-h1-text4/home': 'งบประมาณ (บาท/เดือน)',
    'section3-modal-h1-text5/home': 'ต่ำกว่า 20,000',
    'section3-modal-h1-text6/home': '20.000 - 100.000',
    'section3-modal-h1-text7/home': '100.000 - 200.000',
    'section3-modal-h1-text8/home': '200.000 - 1.000.000',
    'section3-modal-h1-text9/home': '1.000.000 - 1.500.000',
    'section3-modal-h1-text10/home': '1.500.000 - 3.000.000',
    'section3-modal-h1-text11/home': '3.000 THB',
    'section3-modal-h1-text12/home': '15% x งบประมาณ',
    'section3-modal-h1-text13/home': '13% x งบประมาณ',
    'section3-modal-h1-text14/home': '12% x งบประมาณ',
    'section3-modal-h1-text15/home': '11% x งบประมาณ',
    'section3-modal-h1-text16/home': '10% x งบประมาณ',

    'section4-modal-h1/home': 'การสร้างสคริปต์ กลยุทธ์การตลาด seeding',
    'section4-modal-h1-text1/home': 'ให้คำปรึกษาฟรี',
    'section4-modal-h2/home': 'แพ็คเกจเพิ่มยอดไลน์ (เพิ่มความน่าเชื่อถือ)',
    'section4-modal-h2-text1/home': 'เพิ่มไลค์โพสต์',
    'section4-modal-h2-text2/home': 'เพิ่มไลค์แฟนเพจ',
    'section4-modal-h2-text3/home': 'เพิ่มการติดตามโปรไฟล์',
    'section4-modal-h2-text4/home': '100 บาท/600 ไลค์',
    'section4-modal-h2-text5/home': '100 บาท/ 500 ไลค์',
    'section4-modal-h2-text6/home': '100 บาท/ 800 ผู้ติดตาม',
    'section4-modal-h3/home': 'แพ็คเกจสร้างความคิดเห็น seeding (สร้างการสนทนา)',
    'section4-modal-h3-text1/home': '100บาท /80 ความคิดเห็น',

    'section5-modal-h1/home': 'กรุณากรอกข้อมูลให้ชัดเจนเพื่อรับใบเสนอราคาที่ถูกต้อง',
    'section5-modal-h2/home': 'แบรนด์/ผลิตภัณฑ์ที่คุณต้องการโฆษณา',
    'section5-modal-h3/home': 'หนังสือพิมพ์ที่คุณสนใจ',
    'section5-modal-h4/home': 'ระบุความจำเป็นในการขอคำแนะนำ หากคุณไม่มีคำตอบที่ถูกต้อง',
    'section5-modal-h5/home': 'กรุณากรอกหมายเลขโทรศัพท์/อีเมลของคุณไว้ เพื่อให้เราสามารถส่งใบเสนอราคาและติดต่อคุณได้',

    'section6-modal-h1/home': 'กรุณากรอกข้อมูลให้ชัดเจนเพื่อรับใบเสนอราคาที่ถูกต้อง',
    'section6-modal-h2/home': 'แบรนด์/ผลิตภัณฑ์ที่คุณต้องการโฆษณา',
    'section6-modal-h3/home': 'KOL/KOC ที่คุณสนใจ:',
    'section6-modal-h4/home': 'ระบุความจำเป็นในการขอคำแนะนำ หากคุณไม่มีคำตอบที่ถูกต้อง',
    'section6-modal-h5/home': 'กรุณากรอกหมายเลขโทรศัพท์/อีเมลของคุณไว้ เพื่อให้เราสามารถส่งใบเสนอราคาและติดต่อคุณได้',

    'section7-modal-h1/home': 'สร้างแพลตฟอร์มอีคอมเมิร์ซ',
    'section7-modal-h1-text1/home': 'ร้านค้า Tiktok',
    'section7-modal-h1-text2/home': 'ลงทะเบียนและสร้างบูธมืออาชีพบน Tiktok shop Thailand',
    'section7-modal-h1-text3/home': '20.000THB',
    'section7-modal-h1-text4/home': 'Shopee',
    'section7-modal-h1-text5/home': 'ลงทะเบียนและสร้างร้านค้ามืออาชีพบน Shopee Thailand',
    'section7-modal-h1-text6/home': '20.000THB',
    'section7-modal-h2/home': 'ดำเนินการแพลตฟอร์มอีคอมเมิร์ซ',
    'section7-modal-h2-text1/home': 'การโฆษณาบนแะลตฟอร์ม',
    'section7-modal-h2-text2/home': 'โฆษณาผลิตภัณฑ์ในแพลตฟอร์มอีคอมเมิร์ซ',
    'section7-modal-h2-text3/home': '10,000บาท/เดือน + % ค่าคอมมิชชันรายได้',
    'section7-modal-h2-text4/home': 'การโฆษณานอกพื้นที่แพลตฟอร์ม',
    'section7-modal-h2-text5/home': 'โฆษณาผลิตภัณฑ์จากเครือข่ายโซเชียลภายนอกเพื่อดึงดูดลูกค้าเข้าสู่แพลตฟอร์มอีคอมเมิร์ซ',
    'section7-modal-h2-text6/home': '10,000บาท/เดือน + % ค่าคอมมิชชันรายได้',
    'section7-modal-h2-text7/home': 'การตลาดแบบพันธมิตร',
    'section7-modal-h2-text8/home': '- เสนอการตลาดแบบพันธมิตรจากผู้สร้างข้อกำหนดทางการตลาด',
    'section7-modal-h2-text9/home': '- สร้างช่องทางการตลาดพันธมิตรเชิงรุก (ให้คำปรึกษาเพิ่มเติม + ค่าธรรมเนียม)',
    'section7-modal-h2-text10/home': '10,000บาท/เดือน + % ค่าคอมมิชชันรายได้',
    'section7-modal-h2-text11/home': 'การขายแบบถ่ายทอดสด',
    'section7-modal-h2-text12/home': '- สินค้า Live Stream จากทีมงาน Live ของ Agency (ปรึกษาเพิ่มเติม + ค่าธรรมเนียม)',
    'section7-modal-h2-text13/home': '- การสตรีมไลฟ์สดสินค้าโดยใช้กลยุทธ์การจ้างงาน KOL และ KOC (ให้คำปรึกษาเพิ่มเติม + ค่าธรรมเนียม)',
    'section7-modal-h2-text14/home': '10,000บาท/เดือน + % ค่าคอมมิชชันรายได้',

    'section9-modal-h1-text1/home': 'ราคาค่าบริการ',
    'section9-modal-h1-text2/home': 'คำนวณค่าธรรมเนียมทั่วไป',
    'section9-modal-h1-text3/home': '(รายได้จากการสั่งซื้อสำเร็จ - ค่าขนส่ง)',
    'section9-modal-h1-text4/home': 'ไม่ใช้sale',
    'section9-modal-h1-text5/home': '10%',
    'section9-modal-h1-text6/home': 'ใช้sale',
    'section9-modal-h1-text7/home': '15%',
    'section9-modal-h1-text8/home': 'นวนคำสั่งซื้อที่ส่งออก/เดือน',
    'section9-modal-h1-text9/home': 'น้อยกว่า 5,000 ออเดอร์',
    'section9-modal-h1-text10/home': '45บาท/ออเดอร์ส่งออก',
    'section9-modal-h1-text11/home': 'ตั้งแต่ 5,000 - <10,000 ออเดอร์',
    'section9-modal-h1-text12/home': '35บาท/ออเดอร์ส่งออก',
    'section9-modal-h1-text13/home': '> 10,000 ออเดอร์',
    'section9-modal-h1-text14/home': '25บาท/ออเดอร์ส่งออก',

    'h1-text/about': 'VAN DIGITAL เป็นรูปแบบเอเจนซี่ให้บริการโซลูชั่นการสื่อสารแบบครบวงจรสำหรับธุรกิจในหลายด้านในตลา ดประเทศไทย.',
    'h2-text/about': 'ทำไมคุณควรเลือก VAN DIGITAL ',
    'h3-text/about': 'สำหรับการสำรวจตลาดและการเพิ่มรายได้',
    'h4-text/about': 'เข้าใจตลาดไทย: ด้วยการดำเนินงานในตลาดไทยเป็นเวลาหลายปี',
    'h5-text/about': 'การให้คำปรึกษาโดยตรงและการดำเนินการสำหรับธุรกิจหลายธุรกิจกว่า 100+ แห่ง VAN DIGITAL มีความเข้าใจอย่างถ่องแท้เกี่ยวกับแนวโน้มของตลาดและพฤติกรรมผู้บริโภคจากนั้นเพื่อวางแผนการสื่อสารอย่างรวดเร็ว - ถูกต้องและชัยชนะ',
    'h6-text/about': '',
    'h7-text/about': 'ทีมผู้เชี่ยวชาญที่เป็นมืออาชีพและทุ่มเท',
    'h8-text/about': 'VAN DIGITAL ภูมิใจที่ได้ร่วมทีมผู้เชี่ยวชาญมืออาชีพที่แข็งแกร่ง - ทำงานอย่างเต็มร้อยมีความคิดสร้างสรรค์อย่างต่อเนื่องเพื่อปรับให้เข้ากับการเปลี่ยนแปลงของสภาพแวดล้อมทางเทคโนโลยีและยุคดิจิทัล',
    'h9-text/about': 'เหมาะสำหรับทุกงบประมาณ',
    'h10-text/about': 'สำหรับแต่ละโครงการที่มีความต้องการ ขนาดและงบประมาณที่แตกต่างกัน VAN DIGITAL จะออกแบบกลยุทธ์การจัดสรรทรัพยากรที่เหมาะสมและเปลี่ยนแปลงอย่างยืดหยุ่นเมื่อเวลาผ่านไปเพื่อเพิ่มประสิทธิภาพต้นทุนและเพิ่มผลกำไรสูงสุด',
    'h11-text/about': 'คุณธรรม - โปร่งใส - ชัดเจน',
    'h12-text/about': 'ระบบการรายงานตัวชี้วัดและการวิเคราะห์หลักจะส่งเป็นระยะ (รายวัน รายสัปดาห์ รายเดือน) เพื่อช่วยให้ลูกค้าติดตามความคืบหน้าและประสิทธิภาพของงานอย่างใกล้ชิดเพื่อให้มั่นใจว่าการดำเนินการบรรลุเป้าหมาย KPI ที่ดีที่สุด',

    'h1-text/contact': 'คุณต้องการคำแนะนำเกี่ยวกับโซลูชันทางการตลาดเพื่อเพิ่มยอดขายหรือไม่',
    'h2-text/contact': 'กรุณาติดต่อเรา',
    'h3-text/contact': 'หรือกรุณาฝากข้อมูลของคุณไว้ VAN DIGITAL จะติดต่อคุณ',
    'h4-text/contact': 'หมายเลขโทรศัพท์',
    'h5-text/contact': 'อีเมล',
    'h6-text/contact': 'บริการให้คำปรึกษา',

    'btn/contact': 'ติดต่อเรา',
    'btn/send': 'ส่งข้อมูล',
    'placeholder/input': 'ป้อนข้อมูล',

    'header/modal7': 'พัฒนาแพลตฟอร์มอีคอมเมิร์ซ',
    'header/modal9': 'การปฏิบัติตาม',
    'title/modal7': 'สร้างแพลตฟอร์มอีคอมเมิร์ซ',
    'title/modal8': 'ดำเนินการแพลตฟอร์มอีคอมเมิร์ซ',
    'title/modal9': 'ความสําเร็จในประเทศไทย'
};
