import React, {useCallback, useEffect, useState} from 'react';
import {IAppState, IItemItem} from "@types";
import {useDispatch, useSelector} from "react-redux";
import {itemActions} from "@redux/actions/item.actions";
import ModalConfirm from "../ModalConfirm/ModalConfirm";
import {number} from "@utils/constants";
import {map} from "lodash";
import {categoryActions} from '@redux/actions/category.actions';
import {message, Upload} from 'antd';
import type {UploadChangeParam} from 'antd/es/upload';
import type {RcFile, UploadFile, UploadProps} from 'antd/es/upload/interface';
import {LoadingOutlined, PlusOutlined} from "@ant-design/icons";

const getBase64 = (img: RcFile, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result as string));
    reader.readAsDataURL(img);
};
const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!').then(r => console.log(r));
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('Image must smaller than 2MB!').then(r => console.log(r));
    }
    return true;
};
const ModalItem = ({closeModal, item, type}: any) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const handleGetAllCategories = useCallback(() => {
        dispatch(categoryActions.getAllCategory());
    }, [dispatch]);
    useEffect(() => {
        handleGetAllCategories();
    }, [handleGetAllCategories])
    const [newItem, setNewItem] = useState<IItemItem>(type === "update" ? item : {
        code: "",
        name: "",
        oldPrice: "",
        newPrice: "",
        labelCode: "",
        langCode: "",
        langName: "",
        labelName: "",
        categoryCode: "",
        categoryName: "",
        label: "",
        description: "",
        createdDate: new Date()
    });
    const [error, setError] = useState<string>("");
    const [isVisibleModalConfirm, setIsVisibleModalConfirm] = useState<boolean>(false);
    const [category, setCategory] = useState<string>(item.categoryCode + "/" + item.categoryName);
    const [label, setLabel] = useState<string>(item.labelCode + "/" + item.labelName);
    const [lang, setLang] = useState<string>(item.langCode + "/" + item.langName);
    const [file, setFile] = useState<any>("");
    const [urlFile, setUrlFile] = useState<string>("");
    const itemUpdateState = useSelector((state: IAppState) => state.itemUpdate);
    const itemCreateState = useSelector((state: IAppState) => state.itemCreate);
    const categoriesState = useSelector((state: IAppState) => state.categories.categories);
    const labels = [
        {code: "best_seller", name: "Best Seller"},
        {code: "hot_deal", name: "Hot Deal"},
        {code: "best_price", name: "Giá Tốt Nhất"},
    ]
    const langs = [
        {code: "vi", name: "Tiếng Việt"},
        {code: "en", name: "Tiếng Anh"},
        {code: "tl", name: "Tiếng Thái"},
    ]
    const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const {name, value} = e.target;
        setNewItem(item => ({
            ...item,
            [name]: value ? value : ""
        }))
    };
    const handleChange: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {

        if (info.file.status === 'uploading') {
            setLoading(true);
            return;
        }
        getBase64(info.file.originFileObj as RcFile, (url) => {
            console.log(url)
            setLoading(false);
            setUrlFile(url);
            setFile(info.file.originFileObj);
        });
    };
    const handleSelectLabelChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const {value} = e.target;
        setLabel(value);
        setNewItem((prevItem) => ({
            ...prevItem,
            labelCode: value.split("/")[0],
            labelName: value.split("/")[1]
        }));
    };
    const handleSelectLangChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const {value} = e.target;
        setLang(value);
        setNewItem((prevItem) => ({
            ...prevItem,
            langCode: value.split("/")[0],
            langName: value.split("/")[1],
        }));
    };
    const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const {value} = e.target;
        setCategory(value);
        setNewItem((prevItem) => ({
            ...prevItem,
            categoryCode: value.split("/")[0],
            categoryName: value.split("/")[1],
        }));
    };
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let {name, value} = e.target;
        if (name === 'oldPrice') {
            setNewItem((prevItem) => ({
                ...prevItem,
                oldPrice: parseInt(value.replace(/,/g, ''))
            }));
        } else if (name === 'newPrice') {
            setNewItem((prevItem) => ({
                ...prevItem,
                newPrice: value ? parseInt(value.replace(/,/g, '')) : 0
            }));
        } else {
            setNewItem(item => ({
                ...item,
                [name]: value ? value : ""
            }))
        }

    };

    const onSubmitChange = () => {
        if (type === "update") {
            if (newItem.name.length === 0) {
                setError("Tên sản phẩm không được để trống");
                return;
            } else if (newItem.langCode.length === 0) {
                setError("Ngôn ngữ hiển thị không được để trống");
                return;
            } else if (newItem.categoryCode.length === 0) {
                setError("Danh mục không được để trống");
                return;
            } else {
                setError("");
                const formData = new FormData();
                const item = new Blob([JSON.stringify(newItem)], {
                    type: "application/json"
                })
                formData.append("item", item);
                formData.append("avatar", file);
                dispatch(itemActions.updateItem(formData));
            }
        } else {
            if (newItem.code.length === 0) {
                setError("Mã sản phẩm không được để trống");
                return;
            } else if (newItem.name.length === 0) {
                setError("Tên sản phẩm không được để trống");
                return;
            } else if (newItem.langCode.length === 0) {
                setError("Ngôn ngữ hiển thị không được để trống");
                return;
            } else if (newItem.categoryCode.length === 0) {
                setError("Danh mục không được để trống");
                return;
            } else {
                setError("");
                const formData = new FormData();
                const item = new Blob([JSON.stringify(newItem)], {
                    type: "application/json"
                })

                formData.append("item", item);
                formData.append("avatar", file);
                dispatch(itemActions.createItem(formData));
            }
        }
    }
    useEffect(() => {
        if (type === "update") {
            if (itemUpdateState.code === "200") {
                openModalConfirm();
            } else {
                setError(itemUpdateState.error);
            }
        } else {
            if (itemCreateState.code === "201") {
                openModalConfirm();
            } else {
                setError(itemCreateState.error);
            }
        }
    }, [itemUpdateState, itemCreateState]);

    const openModalConfirm = () => {
        setIsVisibleModalConfirm(true);
    }

    const closeModalConfirm = () => {
        closeModal();
        dispatch(itemActions.updateReset());
        dispatch(itemActions.createReset());
        setIsVisibleModalConfirm(false);
    }
    const uploadButton = (
        <div style={{marginTop: "-45px"}}>
            {loading ? <LoadingOutlined/> : <PlusOutlined/>}
            <div>Upload</div>
        </div>
    );

    return (
        <>
            <div className="swal2-container swal2-center swal2-fade swal2-shown"
                 style={{overflowY: "auto"}}>
                <div aria-labelledby="swal2-title" aria-describedby="swal2-content"
                     className="swal2-popup swal2-modal swal2-show" tabIndex={-1} role="dialog" aria-live="assertive"
                     aria-modal="true" style={{width: "600px", display: "flex"}}>
                    <div className="swal2-header">
                        <h2 className="swal2-title" id="swal2-title"
                            style={{display: "flex"}}>{type === "update" ? "Cập nhật thông tin sản phẩm!" : "Thêm mới sản phẩm!"}</h2>
                    </div>
                    <div className="swal2-content">
                        <div id="swal2-content" style={{display: "block"}}>
                            {(itemUpdateState.error.trim().length !== 0 || error.trim().length !== 0)
                            && <div className="alert alert-danger alert-dismissable">
                                <i className="fa fa-fw fa-exclamation-triangle me-2"/>
                                {itemUpdateState.error}
                                {error}
                            </div>}
                            <div className="form-floating mb-4">
                                <input disabled={type === "update"} type="text" className="form-control"
                                       id="login-itemname"
                                       name="code"
                                       value={newItem.code}
                                       onChange={handleInputChange}/>
                                <label className="form-label" htmlFor="login-itemname">Mã sản phẩm</label>
                            </div>
                            <div className="form-floating mb-4">
                                <input type="text" className="form-control" id="login-password"
                                       name="name"
                                       value={newItem.name}
                                       onChange={handleInputChange}/>
                                <label className="form-label" htmlFor="login-password">Tên sản phẩm</label>
                            </div>
                            <div className="form-floating mb-4">
                                <select id="label" name="label"
                                        style={{width: "100%"}}
                                        tabIndex={-1}
                                        value={label}
                                        onChange={handleSelectLabelChange}
                                        aria-hidden="true">
                                    <option key="" value="">Chọn nhãn hiển thị...</option>
                                    {map(labels, (label, index) => (
                                        <option key={index}
                                                value={label.code + "/" + label.name}>
                                            {label.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="form-floating mb-4">
                                <select id="label" name="label"
                                        style={{width: "100%"}}
                                        tabIndex={-1}
                                        value={lang}
                                        onChange={handleSelectLangChange}
                                        aria-hidden="true">
                                    <option key="" value="">Chọn ngôn ngữ hiển thị...</option>
                                    {map(langs, (lang, index) => (
                                        <option key={index}
                                                value={lang.code + "/" + lang.name}>
                                            {lang.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="form-floating mb-4">
                                <input type="text" className="form-control" id="login-password"
                                       name="oldPrice"
                                       value={number(newItem.oldPrice)}
                                       onChange={handleInputChange}/>
                                <label className="form-label" htmlFor="login-password">Giá cũ</label>
                            </div>
                            <div className="form-floating mb-4">
                                <input type="text" className="form-control" id="login-password"
                                       name="newPrice"
                                       value={number(newItem.newPrice)}
                                       onChange={handleInputChange}/>
                                <label className="form-label" htmlFor="login-password">Giá mới</label>
                            </div>
                            <div className="form-floating mb-4">
                                <textarea className="form-control" id="login-password"
                                          name="description"
                                          value={newItem.description}
                                          style={{height: "100px"}}
                                          onChange={handleTextAreaChange}/>
                                <label className="form-label" htmlFor="login-password">Mô tả</label>
                            </div>
                            <div className="form-floating mb-4">
                                <select id="category" name="category"
                                        style={{width: "100%"}}
                                        tabIndex={-1}
                                        value={category}
                                        onChange={handleSelectChange}
                                        aria-hidden="true">
                                    <option key="" value="">Chọn danh mục...</option>
                                    {map(categoriesState, (category, index) => (
                                        <option key={index}
                                                value={category.code + "/" + category.name}>
                                            {category.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="form-floating mb-4">
                                <Upload
                                    name="image"
                                    listType="picture-circle"
                                    className="avatar-uploader"
                                    accept="image/png, image/jpeg"
                                    showUploadList={false}
                                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                    headers={{'Access-Control-Allow-Origin': '*'}}
                                    beforeUpload={beforeUpload}
                                    onChange={handleChange}
                                >
                                    {(file || item.image !== '') ?
                                        <img
                                            src={urlFile ? urlFile : `https://vdsagency.com/static/media/${item.image}`}
                                            alt="avatar"
                                            style={{width: '100%'}}/> : uploadButton}
                                </Upload>
                            </div>
                        </div>

                    </div>
                    <div className="swal2-actions" style={{display: "flex"}}>
                        <button type="button" onClick={onSubmitChange}
                                className="swal2-confirm btn btn-confirm mt-2 swal2-styled"
                                style={{
                                    display: "inline-block",
                                    borderLeftColor: "rgb(48, 133, 214)",
                                    borderRightColor: "rgb(48, 133, 214)"
                                }} aria-label=""><i className="mdi mdi-thumb-up-outline"/> Lưu thay đổi
                        </button>
                        &nbsp;&nbsp;
                        <button type="button" onClick={closeModal}
                                className="swal2-cancel btn btn-alt-warning mt-2 swal2-styled"
                                style={{
                                    display: "inline-block",
                                    borderLeftColor: "rgb(48, 133, 214)",
                                    borderRightColor: "rgb(48, 133, 214)"
                                }}
                                aria-label=""><i className="mdi mdi-thumb-up-outline"/> Đóng
                        </button>

                    </div>
                </div>
            </div>
            {isVisibleModalConfirm &&
            <ModalConfirm closeModal={closeModalConfirm}
                          content={type === "update" ? "Cập nhật thành công" : "Thêm mới thành công"}/>}
        </>
    );
}

export default React.memo(ModalItem);
