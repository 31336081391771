export const validation = {
    isNumberPhone: function (str: string) {
        const pattern = /(0[3|5|7|8|9])+([0-9]{8})\b$/;
        return pattern.test(str);
    },
    isEmail: function (str: string) {
        const pattern = /^[a-z0-9](\.?[a-z0-9]){2,}@([a-z]+\.)+([a-z]+)$/;
        return pattern.test(str);
    },
    isIdCard: function (str: string) {
        return !((str.length !== 12 && str.length !== 9) || !/^\d+$/.test(str));
    }
};

export function number(number: any) {
    let parts = number.toString().replace(/,/g, '');
    parts = parts.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    if (parts === 'NaN') {
        return 0;
    }
    return parts;
}


