import {TAppDispatch, IAppAction, IItemItem, ISearchItem, ISearchItemList} from '@types';
import axiosInstance from "@utils/axiosInstance";

export const UNLOCK_ITEM_RESET = 'item.UNLOCK_ITEM_RESET';
export const UNLOCK_ITEM_REQUEST = 'item.UNLOCK_ITEM_REQUEST';
export const UNLOCK_ITEM_SUCCESS = 'item.UNLOCK_ITEM_SUCCESS';
export const UNLOCK_ITEM_FAILURE = 'item.UNLOCK_ITEM_FAILURE';

export const LOCK_ITEM_RESET = 'item.LOCK_ITEM_RESET';
export const LOCK_ITEM_REQUEST = 'item.LOCK_ITEM_REQUEST';
export const LOCK_ITEM_SUCCESS = 'item.LOCK_ITEM_SUCCESS';
export const LOCK_ITEM_FAILURE = 'item.LOCK_ITEM_FAILURE';

export const UPDATE_ITEM_RESET = 'item.UPDATE_ITEM_RESET';
export const UPDATE_ITEM_REQUEST = 'item.UPDATE_ITEM_REQUEST';
export const UPDATE_ITEM_SUCCESS = 'item.UPDATE_ITEM_SUCCESS';
export const UPDATE_ITEM_FAILURE = 'item.UPDATE_ITEM_FAILURE';

export const CREATE_ITEM_RESET = 'item.CREATE_ITEM_RESET';
export const CREATE_ITEM_REQUEST = 'item.CREATE_ITEM_REQUEST';
export const CREATE_ITEM_SUCCESS = 'item.CREATE_ITEM_SUCCESS';
export const CREATE_ITEM_FAILURE = 'item.CREATE_ITEM_FAILURE';

export const GET_ALL_ITEMS = 'item.GET_ALL_ITEMS';
export const GET_LIST_ITEM = 'items.GET_LIST_ITEM';
export const unlockReset = () => ({
    type: UNLOCK_ITEM_RESET,
    payload: ""
});
export const unlockRequest = () => ({
    type: UNLOCK_ITEM_REQUEST,
    payload: ""
});

export const unlockSuccess = (data: IItemItem) => ({
    type: UNLOCK_ITEM_SUCCESS,
    payload: data,
});
export const unlockFailure = (error: any) => ({
    type: UNLOCK_ITEM_FAILURE,
    payload: error,
});

export const lockReset = () => ({
    type: LOCK_ITEM_RESET,
    payload: ""
});
export const lockRequest = () => ({
    type: LOCK_ITEM_REQUEST,
    payload: ""
});

export const lockSuccess = (data: IItemItem) => ({
    type: LOCK_ITEM_SUCCESS,
    payload: data,
});
export const lockFailure = (error: any) => ({
    type: LOCK_ITEM_FAILURE,
    payload: error,
});
export const updateReset = () => ({
    type: UPDATE_ITEM_RESET,
    payload: ""
});
export const updateRequest = () => ({
    type: UPDATE_ITEM_REQUEST,
    payload: ""
});

export const updateSuccess = (data: IItemItem) => ({
    type: UPDATE_ITEM_SUCCESS,
    payload: data,
});

export const updateFailure = (error: any) => ({
    type: UPDATE_ITEM_FAILURE,
    payload: error,
});
export const createReset = () => ({
    type: CREATE_ITEM_RESET,
    payload: ""
});
export const createRequest = () => ({
    type: CREATE_ITEM_REQUEST,
    payload: ""
});

export const createSuccess = (data: IItemItem) => ({
    type: CREATE_ITEM_SUCCESS,
    payload: data,
});

export const createFailure = (error: any) => ({
    type: CREATE_ITEM_FAILURE,
    payload: error,
});
export const unlockItem = (id: string) => async (dispatch: TAppDispatch) => {
    dispatch(unlockRequest());
    try {
        const response = await axiosInstance.post('/item/unlock?id=' + id, "");
        dispatch(unlockSuccess(response.data));
    } catch (error: any) {
        dispatch(unlockFailure(error.data));
    }
};

export const lockItem = (id: string) => async (dispatch: TAppDispatch) => {
    dispatch(lockRequest());
    try {
        const response = await axiosInstance.post('/item/lock?id=' + id, "");
        dispatch(lockSuccess(response.data));
    } catch (error: any) {
        dispatch(lockFailure(error.data));
    }
};
export const updateItem = (newItem: FormData) => async (dispatch: TAppDispatch) => {
    dispatch(updateRequest());
    try {
        const response = await axiosInstance.post('/item/update', newItem, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
        dispatch(updateSuccess(response.data));
    } catch (error: any) {
        dispatch(updateFailure(error.data));
    }
};
export const createItem = (newItem: FormData) => async (dispatch: TAppDispatch) => {
    dispatch(createRequest());
    try {
        const response = await axiosInstance.post('/item/create', newItem, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
        dispatch(createSuccess(response.data));
    } catch (error: any) {
        dispatch(createFailure(error.response.data));
    }
};
const getAllItem = (lang: string) => async (dispatch: TAppDispatch<IAppAction<IItemItem[]>>) => {
    try {
        // Make an API call to get all categories
        const response = await axiosInstance.get('/item/getAll?lang=' + lang);
        const items: IItemItem[] = response.data.data;
        // Dispatch an action with the categories as the payload
        dispatch({
            type: GET_ALL_ITEMS,
            payload: items,
        });
    } catch (error) {
        // Handle the error if the API call fails
        console.error(error);
    }
};

const searchItem = (search: ISearchItem) => async (dispatch: TAppDispatch<IAppAction<ISearchItemList>>) => {
    try {
        // Make an API call to get all categories
        const response = await axiosInstance.post('/item/search', search);
        const items: ISearchItemList = response.data.data;
        // Dispatch an action with the categories as the payload
        dispatch({
            type: GET_LIST_ITEM,
            payload: items,
        });
    } catch (error) {
        // Handle the error if the API call fails
        console.error(error);
    }
};
export const itemActions = {
    getAllItem, searchItem, updateItem,
    updateReset, createItem,
    createReset,
    lockItem,
    unlockItem,
    lockReset,
    unlockReset,
};
