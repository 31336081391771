import {createReducer} from "@utils/reducer";
import {IAppAction, IUsersState, IUserState, IUserUpdateState} from "@types";
import immutable from "seamless-immutable";
import {
    GET_LIST_USER,
    GET_USER_INFO,
    LOCK_USER_FAILURE,
    LOCK_USER_REQUEST,
    LOCK_USER_RESET,
    LOCK_USER_SUCCESS,
    UNLOCK_USER_FAILURE,
    UNLOCK_USER_REQUEST,
    UNLOCK_USER_RESET,
    UNLOCK_USER_SUCCESS,
    UPDATE_USER_FAILURE,
    UPDATE_USER_REQUEST,
    UPDATE_USER_RESET,
    UPDATE_USER_SUCCESS
} from "@redux/actions/user.actions";
import {LOGOUT} from "@redux/actions/logout.actions";

const initialUpdateState = immutable<IUserUpdateState>(<IUserUpdateState>{
    user: {}, loading: false, error: "", code: ""
});
const initialState = immutable<IUserState>(<IUserState>{
    user: {}
});
const initialStateAdmin = immutable<IUsersState>(<IUsersState>{
    users: {}
});
export const userListReducer = createReducer<IAppAction, typeof initialStateAdmin>(
    initialStateAdmin,
    {
        [GET_LIST_USER]: (state, action) => {
            // If state is not immutable, convert it to immutable
            if (!immutable.isImmutable(state)) {
                state = immutable(state);
            }
            return state
                .setIn(['users'], action.payload);
        },
    }
);

export const userUnlockReducer = createReducer<IAppAction, typeof initialUpdateState>(
    initialUpdateState,
    {
        [UNLOCK_USER_RESET]: (state) => {
            if (!immutable.isImmutable(state)) {
                state = immutable(state);
            }
            return state
                .setIn(['loading'], false)
                .setIn(['code'], "")
                .setIn(['error'], "");
        },
        [UNLOCK_USER_REQUEST]: (state) => {
            if (!immutable.isImmutable(state)) {
                state = immutable(state);
            }
            return state
                .setIn(['loading'], true)
                .setIn(['error'], "");
        },
        [UNLOCK_USER_SUCCESS]: (state, action) => {
            if (!immutable.isImmutable(state)) {
                state = immutable(state);
            }
            return state
                .setIn(['loading'], false)
                .setIn(['code'], action.payload.code)
                .setIn(['user'], action.payload.data)
                .setIn(['error'], "");
        },

        [UNLOCK_USER_FAILURE]: (state, action) => {
            if (!immutable.isImmutable(state)) {
                state = immutable(state);
            }
            return state
                .setIn(['loading'], false)
                .setIn(['error'], action.payload);
        },
    }
);

export const userLockReducer = createReducer<IAppAction, typeof initialUpdateState>(
    initialUpdateState,
    {
        [LOCK_USER_RESET]: (state) => {
            if (!immutable.isImmutable(state)) {
                state = immutable(state);
            }
            return state
                .setIn(['loading'], false)
                .setIn(['code'], "")
                .setIn(['error'], "");
        },
        [LOCK_USER_REQUEST]: (state) => {
            if (!immutable.isImmutable(state)) {
                state = immutable(state);
            }
            return state
                .setIn(['loading'], true)
                .setIn(['error'], "");
        },
        [LOCK_USER_SUCCESS]: (state, action) => {
            if (!immutable.isImmutable(state)) {
                state = immutable(state);
            }
            return state
                .setIn(['loading'], false)
                .setIn(['code'], action.payload.code)
                .setIn(['user'], action.payload.data)
                .setIn(['error'], "");
        },

        [LOCK_USER_FAILURE]: (state, action) => {
            if (!immutable.isImmutable(state)) {
                state = immutable(state);
            }
            return state
                .setIn(['loading'], false)
                .setIn(['error'], action.payload);
        },
    }
);

export const userUpdateReducer = createReducer<IAppAction, typeof initialUpdateState>(
    initialUpdateState,
    {
        [UPDATE_USER_RESET]: (state) => {
            if (!immutable.isImmutable(state)) {
                state = immutable(state);
            }
            return state
                .setIn(['loading'], false)
                .setIn(['code'], "")
                .setIn(['error'], "");
        },
        [UPDATE_USER_REQUEST]: (state) => {
            if (!immutable.isImmutable(state)) {
                state = immutable(state);
            }
            return state
                .setIn(['loading'], true)
                .setIn(['error'], "");
        },
        [UPDATE_USER_SUCCESS]: (state, action) => {
            if (!immutable.isImmutable(state)) {
                state = immutable(state);
            }
            return state
                .setIn(['loading'], false)
                .setIn(['code'], action.payload.code)
                .setIn(['user'], action.payload.data)
                .setIn(['error'], "");
        },

        [UPDATE_USER_FAILURE]: (state, action) => {
            if (!immutable.isImmutable(state)) {
                state = immutable(state);
            }
            return state
                .setIn(['loading'], false)
                .setIn(['error'], action.payload);
        },
    }
);

export const userReducer = createReducer<IAppAction, typeof initialState>(
    initialState,
    {
        [GET_USER_INFO]: (state, action) => {
            // If state is not immutable, convert it to immutable
            if (!immutable.isImmutable(state)) {
                state = immutable(state);
            }
            return state.setIn(['user'], action.payload);
        },
        [LOGOUT]: () => {
            return initialState;
        },
    }
);
