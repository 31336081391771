import React from 'react';
import "./Input4.scss";
import warning from "@assets/images/icon-warning.png";
import {IonIcon} from "@ionic/react";

interface InputProps {
    name: string;
    type: string;
    label: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    required: boolean;
    icon: any;
    inputMode?: any;
    placeholder: string;
    value: any;
    error: string;
    maxLength?: number;
    minLength?: number;
}

const Input4: React.FC<InputProps> = ({
                                          name,
                                          type,
                                          label,
                                          onChange,
                                          inputMode,
                                          icon,
                                          required,
                                          placeholder,
                                          value,
                                          error,
                                          minLength,
                                          maxLength
                                      }) => {
    return (
        <>
            <div className={`form-group ${error ? 'mb-5' : 'mb-3'} ${value ? 'is-focused' : ''}`}>
                <label htmlFor={name} className="col-md-4 control-label">{label} {required &&
                <span>(<span className="fire_engine_red">*</span>)</span>}</label>
                <div className="col-md-4 inputGroupContainer">
                    <div className="input-group">
                        <span className="input-group-addon">
                            <IonIcon icon={icon}/>
                        </span>
                        <input id={name}
                               type={type}
                               name={name}
                               inputMode={inputMode}
                               onChange={onChange}
                               value={value}
                               placeholder={placeholder}
                               minLength={minLength}
                               maxLength={maxLength}
                               className="form-control"
                               autoComplete="off"/>
                    </div>
                </div>
                {error &&
                <p className="error_show">
                    <img src={warning} alt={"warning"}/>
                    {error}
                </p>
                }
            </div>
        </>
    )
};

export default React.memo(Input4);
