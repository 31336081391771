import React, {useEffect, useState} from 'react';
import './CategoryManagement.scss';
import {map} from "lodash";
import moment from 'moment';
import {useDispatch, useSelector} from "react-redux";
import {IAppState, ISearchItem, ICategoryItem} from "@types";
import {categoryActions} from "@redux/actions/category.actions";
import {Pagination, PaginationProps} from "antd";
import ModalLockOrUnlock from "../../shared/ModalLockOrUnlock/ModalLockOrUnlock";
import ModalCategory from "../../shared/ModalCategory/ModalCategory";
import {FiEdit2} from "react-icons/fi"
import {BsFillTrash3Fill, BsLock, BsUnlock} from "react-icons/bs"
import {AiOutlinePlusCircle} from "react-icons/ai";

const CategoryManagement = () => {
    const dispatch = useDispatch();
    const [isVisibleUpdate, setIsVisibleUpdate] = useState<boolean>(false);
    const [isVisibleLockOrUnlock, setIsVisibleLockOrUnlock] = useState<boolean>(false);
    const [type, setType] = useState<string>("");
    const [id, setId] = useState<string>("");
    const [category, setCategory] = useState<ICategoryItem>({
        code: "",
        createdBy: "",
        createdDate: new Date(),
        deleted: false,
        index: 0,
        description: "",
        id: "",
        name: "",
        updatedBy: "",
        updatedDate: undefined
    });
    const [search, setSearch] = useState<ISearchItem>({itemCode: "", pageNum: 0, pageSize: 10, q: ""})
    const categoryState = useSelector((state: IAppState) => state.categoryList.categoryList);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setSearch(account => ({
            ...account,
            pageNum: 0,
            pageSize: 10,
        }))
        setSearch(account => ({
            ...account,
            [name]: value ? value : ""
        }))
    };
    const onSearch = () => {
        dispatch(categoryActions.searchCategory(search));
    }
    useEffect(() => {
        onSearch();
    }, [search]);
    const loginState = useSelector((state: IAppState) => state.login);
    if (!loginState.isLogin) {
        window.location.replace("/dang-nhap");
        return null;
    }
    const onChange: PaginationProps['onChange'] = (pageNumber: number) => {
        setSearch((prevSearch) => ({
            ...prevSearch,
            pageNum: pageNumber - 1
        }));
    };
    const openModalLockOrUnlock = (id: string, type: string) => {
        setId(id);
        setType(type);
        setIsVisibleLockOrUnlock(true);
    }

    const closeModalLockOrUnlock = () => {
        setIsVisibleLockOrUnlock(false);
        dispatch(categoryActions.searchCategory(search));
    }
    const openModalCreate = (category: ICategoryItem) => {
        setType("create")
        setCategory(category);
        setIsVisibleUpdate(true);
    }

    const openModalUpdate = (category: ICategoryItem) => {
        setType("update")
        setCategory(category);
        setIsVisibleUpdate(true);
    }

    const closeModal = () => {
        setIsVisibleUpdate(false);
        dispatch(categoryActions.searchCategory(search));
    }

    return (
        <>
            <main id="main-container">
                <div id="app" className="content">
                    <div className="block block-rounded">
                        <div className="block-header"><h4 className="block-title">Quản lý danh mục</h4></div>
                        <div className="block-content">
                            <div className="searchcategory">
                                {/*form search categorys*/}
                                <div className="form-search">
                                    <form className="input-group mb-3">
                                        <input className="form-control mr-sm-2" type="search" name="q"
                                               onChange={handleInputChange}
                                               placeholder="Tìm theo mã, tên, mô tả danh mục..."
                                               aria-label="Search"
                                        />
                                    </form>
                                </div>

                                <div className="d-flex justify-content-between">

                                    <div className="btn-add" onClick={() => openModalCreate(category)}>
                                        <span>
                                            <AiOutlinePlusCircle/>&nbsp;Thêm mới</span>
                                    </div>
                                </div>

                                {/*Table show list category*/}
                                <div className="table-responsive">
                                    <table className="table table-bordered table-hover">
                                        <thead>
                                        <tr className="table-primary text-center">
                                            <th scope="col">#</th>
                                            <th scope="col">Mã danh mục</th>
                                            <th scope="col">Tên danh mục</th>
                                            <th scope="col">Thứ tự xuất hiện</th>
                                            <th scope="col">Mô tả</th>
                                            <th scope="col">Ngày tạo</th>
                                            <th scope="col">Chức năng</th>
                                        </tr>
                                        </thead>

                                        <tbody>
                                        {categoryState.content !== undefined && categoryState.content.length &&
                                        (map(categoryState.content, (category, index) => (
                                            <tr className="text-center" key={index}>
                                                <th scope="row">{index + 1}</th>
                                                <td>{category.code}</td>
                                                <td>{category.name}</td>
                                                <td>{category.index}</td>
                                                <td>{category.description}</td>
                                                <td>{moment(category.createdDate).format('DD/MM/YYYY HH:mm:ss')}</td>
                                                <td>
                                                    <div
                                                        className="align-items-center justify-content-center list-user-action">
                                                        {!category.deleted &&
                                                        <a onClick={() => openModalUpdate(category)}
                                                           className="mb-1 mr-1 iq-bg-primary"
                                                           title="Cập nhật danh mục">
                                                            <FiEdit2/>
                                                        </a>}
                                                        {!category.deleted &&
                                                        <a style={{color: "red"}}
                                                           onClick={() => openModalLockOrUnlock(category.id, "lockCategory")}
                                                           title="Khoá danh mục">
                                                            <BsLock/>
                                                        </a>}
                                                        {category.deleted &&
                                                        <a className="mb-1 mr-1 iq-bg-primary"
                                                           onClick={() => openModalLockOrUnlock(category.id, "unlockCategory")}
                                                           title="Mở khoá danh mục">
                                                            <BsUnlock/>
                                                        </a>}
                                                        {category.deleted &&
                                                        <a style={{color: "red"}}
                                                           onClick={() => openModalLockOrUnlock(category.id, "removeCategory")}
                                                           title="Xoá khoá danh mục">
                                                            <BsFillTrash3Fill/>
                                                        </a>}
                                                    </div>
                                                </td>
                                            </tr>
                                        )))}
                                        </tbody>
                                    </table>
                                </div>


                            </div>
                        </div>
                        <div style={{display: "flex", justifyContent: "center"}} className="block-header">
                            <nav aria-label="Page navigation example">
                                <Pagination
                                    onChange={onChange}
                                    current={search.pageNum + 1}
                                    total={categoryState.totalElements}
                                />
                            </nav>
                        </div>
                    </div>
                </div>
            </main>
            {isVisibleUpdate &&
            <ModalCategory closeModal={closeModal} category={category} type={type}/>}
            {isVisibleLockOrUnlock &&
            <ModalLockOrUnlock closeModal={closeModalLockOrUnlock} id={id} type={type}/>}
        </>
    )
};

export default React.memo(CategoryManagement);
