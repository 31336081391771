import React, {useEffect, useState} from 'react';
import "./HomePageView.scss";
import btn_desktop from "@assets/images/btn-1-desktop.png"
import arrow_right from "@assets/images/arrow-right.png"
import btn_mobile from "@assets/images/btn-1-mobile.png"
import img7 from "@assets/images/img7.png"
import img from "@assets/images/img.png"
import img4 from "@assets/images/image4.png"
import img3 from "@assets/images/img3.png"
import img15 from "@assets/images/img15.png"
import img16 from "@assets/images/img16.png"
import img17 from "@assets/images/img17.jpeg"
import img18 from "@assets/images/img18.jpeg"
import img6 from "@assets/images/img6.png"
import img5 from "@assets/images/img5.png"
import img5_1 from "@assets/images/img5-1.png"
import btn_min from "@assets/images/bg-min.png"
import combo from "@assets/images/combo.png"
import {Section1, Section2, Section5, Section6, TabBarBottom} from "@components/shared";
import {Modal} from "antd";
import {AutoCenter} from "antd-mobile";
import {useTranslation} from 'react-i18next';

const HomePageView = () => {
    const {t} = useTranslation('global');
    const [width, setWidth] = useState<number>(0);
    const [openCombo, setOpenCombo] = useState(false);
    const [openStrategic, setOpenStrategic] = useState(false);
    const [openMedia, setOpenMedia] = useState(false);
    const [openAds, setOpenAds] = useState(false);
    const [openSeeding, setOpenSeeding] = useState(false);
    const [openBooking, setOpenBooking] = useState(false);
    const [openKol, setOpenKol] = useState(false);
    const [openTMDT, setOpenTMDT] = useState(false);
    const [openFulfillment, setOpenFulfillment] = useState(false);

    useEffect(() => {
        const divElement = document.getElementById('section-vds');
        if (divElement) {
            setWidth(divElement.clientWidth / 2);
        }
        const handleResize = () => {
            if (divElement) {
                setWidth((prevWidth) => (prevWidth > 1 ? divElement.clientWidth / 2 : divElement.clientWidth));
            }
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [])
    return (
        <>
            <div id="home" className="home">
                <div className="bg-desktop">
                    <div
                        className="grid grid-flow-row sm:grid-flow-col grid-rows-2 md:grid-rows-1 sm:grid-cols-2 gap-8 py-6 sm:py-16">
                        <div className="flex flex-col justify-center items-start row-start-2 sm:row-start-1">
                            <div className="text-banner">
                                <h1 className="text-3xl lg:text-4xl xl:text-5xl font-medium text-white-600 leading-normal">{t('h1/home')}</h1>
                                <p className="text-white-500">{t('h1-text/home')}</p>
                            </div>
                        </div>
                        <div className="flex w-full">
                            <img src={btn_min} alt="bg min"/>
                        </div>
                    </div>
                </div>
                <div className="btn-banner">
                    <img className="uk-visible@m" src={btn_desktop} alt="btn"/>
                    <img className="uk-hidden@m" src={btn_mobile} alt="btn"/>
                </div>
                <div className="container">
                    <div className="body-header flex justify-center">
                        <div className="col-12">
                            <div
                                className="grid grid-flow-row sm:grid-flow-col grid-rows-2 md:grid-rows-1 sm:grid-cols-2 gap-8 py-6 sm:py-16">
                                <div
                                    className="flex flex-col justify-center items-start row-start-2 sm:row-start-1"
                                    style={{width: "85%"}}>
                                    <h1>{t('h2-text/home')}</h1>
                                </div>
                                <div className="combo flex w-100 justify-content-end">
                                    <img src={combo} alt="combo"/>
                                    <div className="combo-content">
                                        <div>{t('hot-combo/home')}</div>
                                        <div className="d-grid">
                                            <span>{t('hot-combo-h1/home')}</span>
                                            <span>{t('hot-combo-h2/home')}</span>
                                        </div>
                                        <div className="see-more d-flex align-items-center"
                                             onClick={() => setOpenCombo(true)}>
                                            <span>{t('hot-combo-h3/home')}</span>
                                            <img className="arrow" src={arrow_right} alt="arrow"/>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <hr/>
                <div className="body-content container">
                    <div className="row">
                        <div id="section-1" className="section-wrapper col-12 col-lg-6">
                            <div id="section-vds" className="section-vds">
                                <div className="d-flex justify-content-between">
                                    <div id="section-title" className="section col-lg-5">
                                        <div className="index fw-bold fs-5 mb-2">01.</div>
                                        <div className="d-flex justify-content-between align-items-center"
                                             onClick={() => setOpenStrategic(true)}>
                                            <h2 className="fw-bold">Strategic Planning</h2>
                                            <img className="arrow" src={arrow_right} alt="arrow"/>
                                        </div>
                                    </div>
                                    <div id="section-right" className="col-lg-6 section-right uk-visible@m">
                                        {t('section1-text/home')}
                                    </div>
                                </div>
                                <Section1 img1={img7} img2={img7} img3={img7} radius1="24px" radius2="120px"
                                          radius3="24px" width={width}/>
                                <div className="col-lg-12 mt-5 section-right uk-hidden@m">
                                    {t('section1-text/home')}
                                </div>
                            </div>
                        </div>
                        <hr className="uk-hidden@m mt-5 mb-5"/>
                        <div id="section-2" className="section-wrapper col-12 col-lg-6">
                            <div id="section-vds" className="section-vds">
                                <div className="col-lg-6">
                                    <div id="section-title" className="section">
                                        <div className="index fw-bold fs-5 mb-2">02.</div>
                                        <div className="d-flex justify-content-between align-items-center"
                                             onClick={() => setOpenMedia(true)}>
                                            <h2 className="fw-bold">Media Production</h2>
                                            <img className="arrow" src={arrow_right} alt="arrow"/>
                                        </div>
                                    </div>
                                    <div id="section-right" className="section-right uk-visible@m">
                                        {t('section2-text/home')}
                                    </div>
                                </div>
                                <Section2 img={img4} color="#B8FEF2" className="uk-visible@m"/>

                                <div className="d-flex mt-5 align-items-center uk-hidden@m">
                                    <div className="section-right">
                                        {t('section2-text/home')}
                                    </div>
                                    <Section2 img={img4} color="#B8FEF2"/>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <hr/>
                <div className="body-content container">
                    <div className="row">
                        <div id="section-3" className="section-wrapper col-12 col-lg-6">
                            <div id="section-vds" className="section-vds">
                                <Section2 img={img3} color="#FEF3B8" className="uk-visible@m"/>
                                <div className="col-lg-6">
                                    <div id="section-title" className="section">
                                        <div className="index fw-bold fs-5 mb-2">03.</div>
                                        <div className="d-flex justify-content-between align-items-center"
                                             onClick={() => setOpenAds(true)}>
                                            <h2 className="fw-bold">Performance Ads</h2>
                                            <img className="arrow" src={arrow_right} alt="arrow"/>
                                        </div>
                                    </div>
                                    <div id="section-right col-lg-6" className="section-right uk-visible@m">
                                        {t('section3-text/home')}
                                    </div>
                                </div>

                                <div className="d-flex mt-5 align-items-center uk-hidden@m">
                                    <Section2 img={img3} color="#FEF3B8"/>
                                    <div className="section-right">
                                        {t('section3-text/home')}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr className="uk-hidden@m mt-5 mb-5"/>
                        <div id="section-4" className="section-wrapper col-12 col-lg-6">
                            <div id="section-vds" className="section-vds">
                                <div className="d-flex justify-content-between">
                                    <div id="section-title" className="section col-lg-5 ">
                                        <div className="index fw-bold fs-5 mb-2">04.</div>
                                        <div className="d-flex justify-content-between align-items-center"
                                             onClick={() => setOpenSeeding(true)}>
                                            <h2 className="fw-bold">Seeding</h2>
                                            <img className="arrow" src={arrow_right} alt="arrow"/>
                                        </div>
                                    </div>
                                    <div id="section-right" className="col-lg-6 section-right uk-visible@m">
                                        {t('section4-text/home')}
                                    </div>
                                </div>
                                <Section1 img1={img} img2={img} img3={img} radius1="24px" radius2="24px"
                                          radius3="120px" width={width}/>
                                <div className="col-lg-12 mt-5 section-right uk-hidden@m">
                                    {t('section4-text/home')}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr/>
                <div className="body-content container">
                    <div className="row">
                        <div id="section-5" className="section-wrapper col-12 col-lg-6">
                            <div id="section-vds" className="section-vds">
                                <div className="d-flex justify-content-between">
                                    <div id="section-title" className="section col-lg-5 ">
                                        <div className="index fw-bold fs-5 mb-2">05.</div>
                                        <div className="d-flex justify-content-between align-items-center"
                                             onClick={() => setOpenBooking(true)}>
                                            <h2 className="fw-bold">PR Booking</h2>
                                            <img className="arrow" src={arrow_right} alt="arrow"/>
                                        </div>
                                    </div>
                                    <div id="section-right" className="col-lg-6 section-right uk-visible@m">
                                        {t('section5-text/home')}
                                    </div>
                                </div>
                                <Section5 img1={img5} img2={img5} img3={img5_1} width={width} basis={26}/>
                                <div className="col-lg-12 mt-5 section-right uk-hidden@m">
                                    {t('section5-text/home')}
                                </div>
                            </div>
                        </div>
                        <hr className="uk-hidden@m mt-5 mb-5"/>
                        <div id="section-6" className="section-wrapper col-12 col-lg-6">
                            <div id="section-vds" className="section-vds">
                                <div className="col-lg-6">
                                    <div id="section-title" className="section">
                                        <div className="index fw-bold fs-5 mb-2">06.</div>
                                        <div className="d-flex justify-content-between align-items-center"
                                             onClick={() => setOpenKol(true)}>
                                            <h2 className="fw-bold">KOLs /KOCs Booking</h2>
                                            <img className="arrow" src={arrow_right} alt="arrow"/>
                                        </div>
                                    </div>
                                    <div id="section-right" className="section-right uk-visible@m">
                                        {t('section6-text/home')}
                                    </div>
                                </div>
                                <Section2 img={img6} color="#B8FEF2" className="uk-visible@m"/>

                                <div className="d-flex mt-5 align-items-center uk-hidden@m">
                                    <div className="section-right">
                                        {t('section6-text/home')}
                                    </div>
                                    <Section2 img={img6} color="#B8FEF2"/>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <hr/>
                <div className="body-content container">
                    <div className="row">
                        <div id="section-3" className="section-wrapper col-12 col-lg-6">
                            <div id="section-vds" className="section-vds">
                                <Section2 img={img15} color="#FEF3B8" className="uk-visible@m"/>
                                <div className="col-lg-6">
                                    <div id="section-title" className="section">
                                        <div className="index fw-bold fs-5 mb-2">07.</div>
                                        <div className="d-flex justify-content-between align-items-center"
                                             onClick={() => setOpenTMDT(true)}>
                                            <h2 className="fw-bold">{t('title/modal7')}</h2>
                                            <img className="arrow" src={arrow_right} alt="arrow"/>
                                        </div>
                                    </div>
                                    <div id="section-right col-lg-6" className="section-right uk-visible@m">
                                        {t('section7-text/home')}
                                    </div>
                                </div>

                                <div className="d-flex mt-5 align-items-center uk-hidden@m">
                                    <Section2 img={img15} color="#FEF3B8"/>
                                    <div className="section-right">
                                        {t('section7-text/home')}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr className="uk-hidden@m mt-5 mb-5"/>
                        <div id="section-8" className="section-wrapper col-12 col-lg-6">
                            <div id="section-vds" className="section-vds">
                                <Section2 img={img16} color="#FEF3B8" className="uk-visible@m"/>
                                <div className="col-lg-6">
                                    <div id="section-title" className="section">
                                        <div className="index fw-bold fs-5 mb-2">08.</div>
                                        <div className="d-flex flex justify-content-between align-items-center"
                                             onClick={() => setOpenTMDT(true)}>
                                            <h2 className="fw-bold">{t('title/modal8')}</h2>
                                            <img className="arrow" src={arrow_right} alt="arrow"/>
                                        </div>
                                    </div>
                                    <div id="section-right col-lg-6" className="section-right uk-visible@m">
                                        {t('section8-text/home')}
                                    </div>
                                </div>

                                <div className="d-flex mt-5 align-items-center uk-hidden@m flex-row-reverse">
                                    <Section2 img={img16} color="#FEF3B8"/>
                                    <div className="section-right">
                                        {t('section8-text/home')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr/>
                <div className="body-content container">
                    <div className="row">
                        <div id="section-9" className="section-wrapper col-12 col-lg-10">
                            <div id="section-vds" className="section-vds">
                                <div className="col-lg-5">
                                    <div id="section-title" className="section">
                                        <div className="index fw-bold fs-5 mb-2">09.</div>
                                        <div className="d-flex justify-content-between align-items-center"
                                             onClick={() => setOpenFulfillment(true)}>
                                            <h2 className="fw-bold">{t('title/modal9')}</h2>
                                            <img className="arrow" src={arrow_right} alt="arrow"/>
                                        </div>
                                    </div>
                                    <div id="section-right" className="section-right uk-visible@m">
                                        {t('section9-text/home')}
                                    </div>
                                </div>
                                <Section6 img={img18} img1={img17}/>

                                <div className="d-flex mt-5 align-items-center uk-hidden@m">
                                    <div className="section-right">
                                        {t('section9-text/home')}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <TabBarBottom/>
            </div>
            <Modal
                title=""
                centered
                open={openCombo}
                onOk={() => setOpenCombo(false)}
                onCancel={() => setOpenCombo(false)}
                width={"90vw"}
                footer={null}
            >
                <AutoCenter className="fw-bold fs-6"
                            style={{color: "#EE8802", textTransform: "uppercase"}}>{t('hot-combo/home')}</AutoCenter>
                <AutoCenter
                    className="fw-bold fs-5 text-center mb-2">{t('hot-combo-h1/home')} - {t('hot-combo-h2/home')}</AutoCenter>
                <hr className="mt-4 mb-3"/>
                <div className="mb-3" style={{fontWeight: "700"}}>{t('section0-modal-h1/home')}
                </div>
                <table className="table table-striped">
                    <tbody>
                    <tr>
                        <th>{t('section0-modal-h1-text1/home')}</th>
                        <td>{t('section0-modal-h1-text2/home')}
                        </td>
                    </tr>
                    <tr>
                        <th>{t('section0-modal-h1-text3/home')}</th>
                        <td>{t('section0-modal-h1-text4/home')}
                        </td>
                    </tr>
                    <tr>
                        <th>{t('section0-modal-h1-text5/home')}</th>
                        <td>{t('section0-modal-h1-text6/home')}
                        </td>
                    </tr>
                    <tr>
                        <th>{t('section0-modal-h1-text7/home')}</th>
                        <td>{t('section0-modal-h1-text8/home')}
                        </td>
                    </tr>
                    </tbody>
                </table>
                <hr className="mt-4 mb-4"/>
                <div className="mb-3" style={{fontWeight: "700"}}>{t('section0-modal-h2/home')}
                </div>
                <table className="table table-striped">
                    <tbody>
                    <tr>
                        <th>{t('section0-modal-h2-text1/home')}</th>
                        <td>{t('section0-modal-h2-text2/home')}
                        </td>
                    </tr>

                    </tbody>
                </table>
                <hr className="mt-4 mb-4"/>
                <AutoCenter>
                    <a href="/#"
                       className="uk-visible@ header__bottom__btn uk-button uk-border-pill">{t('btn/contact')}</a>
                </AutoCenter>
            </Modal>
            <Modal
                title=""
                centered
                className="strategic-modal"
                open={openStrategic}
                onOk={() => setOpenStrategic(false)}
                onCancel={() => setOpenStrategic(false)}
                width={"90vw"}
                footer={null}
            >
                <AutoCenter className="fw-bold fs-5 text-center mb-2">Strategic Planning</AutoCenter>
                <hr className="mt-4 mb-3"/>
                <table className="table table-striped">
                    <tbody>
                    <tr>
                        <th>{t('section1-modal-h1/home')}</th>
                        <td>{t('section1-modal-h1-text1/home')}</td>
                        <td>{t('section1-modal-h1-text4/home')}</td>
                    </tr>
                    <tr>
                        <th/>
                        <td>{t('section1-modal-h1-text2/home')}</td>
                        <td>{t('section1-modal-h1-text5/home')}</td>
                    </tr>
                    <tr>
                        <th/>
                        <td>{t('section1-modal-h1-text3/home')}</td>
                        <td>{t('section1-modal-h1-text6/home')}</td>
                    </tr>
                    <tr>
                        <th colSpan={3}>
                            <hr/>
                        </th>
                    </tr>
                    <tr>
                        <th>{t('section1-modal-h2/home')}</th>
                        <td>{t('section1-modal-h2-text1/home')}</td>
                        <td>{t('section1-modal-h2-text7/home')}</td>
                    </tr>
                    <tr>
                        <th/>
                        <td>{t('section1-modal-h2-text2/home')}</td>
                        <td>{t('section1-modal-h2-text8/home')}</td>
                    </tr>
                    <tr>
                        <th/>
                        <td>{t('section1-modal-h2-text3/home')}</td>
                        <td>{t('section1-modal-h2-text9/home')}</td>
                    </tr>
                    <tr>
                        <th/>
                        <td>{t('section1-modal-h2-text4/home')}</td>
                        <td>{t('section1-modal-h2-text10/home')}</td>
                    </tr>
                    <tr>
                        <th/>
                        <td>{t('section1-modal-h2-text5/home')}</td>
                        <td>{t('section1-modal-h2-text11/home')}</td>
                    </tr>
                    <tr>
                        <th/>
                        <td>{t('section1-modal-h2-text6/home')}</td>
                        <td>{t('section1-modal-h2-text12/home')}</td>
                    </tr>
                    </tbody>
                </table>
            </Modal>
            <Modal
                title=""
                centered
                className="media-modal"
                open={openMedia}
                onOk={() => setOpenMedia(false)}
                onCancel={() => setOpenMedia(false)}
                width={"90vw"}
                footer={null}
            >
                <AutoCenter className="fw-bold fs-5 text-center mb-2">Media Production</AutoCenter>
                <hr className="mt-4 mb-3"/>
                <table className="table table-striped">
                    <tbody>
                    <tr>
                        <th>{t('section2-modal-h1/home')}</th>
                        <td>
                            <p className="fw-bold">{t('section2-modal-h1-text1/home')}</p>
                            <p>{t('section2-modal-h1-text2/home')}<br/>
                                {t('section2-modal-h1-text3/home')}<br/>
                                {t('section2-modal-h1-text4/home')}<br/>
                                {t('section2-modal-h1-text5/home')}
                            </p>
                            <p style={{color: "red"}}>
                                * {t('section2-modal-h1-text6/home')}<br/>
                                {t('section2-modal-h1-text7/home')}<br/>
                                {t('section2-modal-h1-text8/home')}<br/>
                                {t('section2-modal-h1-text9/home')}<br/>
                            </p>
                        </td>
                        <td>{t('section2-modal-h1-text10/home')}</td>
                    </tr>
                    <tr>
                        <th/>
                        <td>
                            <p className="fw-bold">{t('section2-modal-h1-text11/home')}</p>
                            <p>{t('section2-modal-h1-text12/home')}<br/>
                                {t('section2-modal-h1-text13/home')}<br/>
                                {t('section2-modal-h1-text14/home')}<br/>
                                {t('section2-modal-h1-text15/home')}
                            </p>
                            <p style={{color: "red"}}>
                                * {t('section2-modal-h1-text16/home')}<br/>
                                {t('section2-modal-h1-text17/home')}<br/>
                                {t('section2-modal-h1-text18/home')}<br/>
                                {t('section2-modal-h1-text19/home')}
                            </p>
                        </td>
                        <td>{t('section2-modal-h1-text20/home')}</td>
                    </tr>
                    <tr>
                        <th/>
                        <td>
                            <p className="fw-bold">{t('section2-modal-h1-text21/home')}</p>
                            <p>{t('section2-modal-h1-text22/home')}<br/>
                                {t('section2-modal-h1-text23/home')}<br/>
                                {t('section2-modal-h1-text24/home')}<br/>
                                {t('section2-modal-h1-text25/home')}<br/>
                                {t('section2-modal-h1-text26/home')}
                            </p>
                            <p style={{color: "red"}}>
                                * {t('section2-modal-h1-text27/home')}<br/>
                                {t('section2-modal-h1-text28/home')}<br/>
                                {t('section2-modal-h1-text29/home')}<br/>
                                {t('section2-modal-h1-text30/home')}
                            </p>
                        </td>
                        <td>{t('section2-modal-h1-text31/home')}</td>
                    </tr>
                    <tr>
                        <th colSpan={3}>
                            <hr/>
                        </th>
                    </tr>
                    <tr>
                        <th>{t('section2-modal-h2/home')}</th>
                        <td>
                            <p className="fw-bold">{t('section2-modal-h2-text1/home')}</p>
                            <p>{t('section2-modal-h2-text2/home')}<br/>
                                {t('section2-modal-h2-text3/home')}<br/>
                                {t('section2-modal-h2-text4/home')}<br/>
                                {t('section2-modal-h2-text5/home')}<br/>
                                {t('section2-modal-h2-text6/home')}
                            </p>
                            <p style={{color: "red"}}>
                                * {t('section2-modal-h2-text7/home')}<br/>
                                {t('section2-modal-h2-text8/home')}<br/>
                                {t('section2-modal-h2-text9/home')}<br/>
                                {t('section2-modal-h2-text10/home')}
                            </p>
                        </td>
                        <td>{t('section2-modal-h2-text11/home')}</td>
                    </tr>
                    <tr>
                        <th/>
                        <td>
                            <p className="fw-bold">{t('section2-modal-h3-text1/home')}</p>
                            <p>{t('section2-modal-h3-text2/home')}<br/>
                                {t('section2-modal-h3-text3/home')}<br/>
                                {t('section2-modal-h3-text4/home')}<br/>
                                {t('section2-modal-h3-text5/home')}<br/>
                                {t('section2-modal-h3-text6/home')}
                            </p>
                        </td>
                        <td>{t('section2-modal-h3-text7/home')}</td>
                    </tr>
                    </tbody>
                </table>
            </Modal>
            <Modal
                title=""
                centered
                className="ads-modal"
                open={openAds}
                onOk={() => setOpenAds(false)}
                onCancel={() => setOpenAds(false)}
                width={"90vw"}
                footer={null}
            >
                <AutoCenter className="fw-bold fs-5 text-center mb-2">Performance Ads</AutoCenter>
                <hr className="mt-4 mb-3"/>
                <table className="table table-striped">
                    <tbody>
                    <tr>
                        <th rowSpan={4}>{t('section3-modal-h1/home')}<br/>
                            - {t('section3-modal-h1-text1/home')}<br/>
                            - {t('section3-modal-h1-text2/home')}<br/>
                            - {t('section3-modal-h1-text3/home')}
                        </th>
                        <td>{t('section3-modal-h1-text4/home')}</td>
                        <td/>
                    </tr>
                    <tr>
                        <td>{t('section3-modal-h1-text5/home')}</td>
                        <td>3.000 THB</td>
                    </tr>
                    <tr>
                        <td>20.000 - 100.000</td>
                        <td>{t('section3-modal-h1-text12/home')}</td>
                    </tr>
                    <tr>
                        <td>100.000 - 200.000</td>
                        <td>{t('section3-modal-h1-text13/home')}</td>
                    </tr>
                    <tr>
                        <th/>
                        <td>200.000 - 1.000.000</td>
                        <td>{t('section3-modal-h1-text14/home')}</td>
                    </tr>
                    <tr>
                        <th/>
                        <td>1.000.000 - 1.500.000</td>
                        <td>{t('section3-modal-h1-text15/home')}</td>
                    </tr>
                    <tr>
                        <th/>
                        <td>1.500.000 - 3.000.000</td>
                        <td>{t('section3-modal-h1-text16/home')}</td>
                    </tr>
                    </tbody>
                </table>
            </Modal>
            <Modal
                title=""
                centered
                className="seeding-modal"
                open={openSeeding}
                onOk={() => setOpenSeeding(false)}
                onCancel={() => setOpenSeeding(false)}
                width={"90vw"}
                footer={null}
            >
                <AutoCenter className="fw-bold fs-5 text-center mb-2">Seeding</AutoCenter>
                <hr className="mt-4 mb-3"/>
                <table className="table table-striped">
                    <tbody>
                    <tr>
                        <th colSpan={2}>{t('section4-modal-h1/home')}</th>
                        <td>{t('section4-modal-h1-text1/home')}</td>
                    </tr>
                    <tr>
                        <th colSpan={3}>
                            <hr/>
                        </th>
                    </tr>
                    <tr>
                        <th>{t('section4-modal-h2/home')}</th>
                        <td>{t('section4-modal-h2-text1/home')}</td>
                        <td>{t('section4-modal-h2-text4/home')}</td>
                    </tr>
                    <tr>
                        <th/>
                        <td>{t('section4-modal-h2-text2/home')}</td>
                        <td>{t('section4-modal-h2-text5/home')}</td>
                    </tr>

                    <tr>
                        <th/>
                        <td>{t('section4-modal-h2-text3/home')}</td>
                        <td>{t('section4-modal-h2-text6/home')}</td>
                    </tr>
                    <tr>
                        <th colSpan={3}>
                            <hr/>
                        </th>
                    </tr>
                    <tr>
                        <th colSpan={2}>{t('section4-modal-h3/home')}</th>
                        <td>{t('section4-modal-h3-text1/home')}</td>
                    </tr>
                    </tbody>
                </table>
            </Modal>
            <Modal
                title=""
                centered
                className="booking-modal"
                open={openBooking}
                onOk={() => setOpenBooking(false)}
                onCancel={() => setOpenBooking(false)}
                width={"90vw"}
                footer={null}
            >
                <AutoCenter className="fw-bold fs-5 text-center">PR Booking</AutoCenter>
                <AutoCenter className="fs-6 text-center mb-2">{t('section5-modal-h1/home')}</AutoCenter>
                <hr className="mt-4 mb-3"/>
                <table className="table table-striped">
                    <tbody>
                    <tr>
                        <td>{t('section5-modal-h2/home')}</td>
                        <td><input type="text" placeholder={t('placeholder/input')}/></td>
                    </tr>
                    <tr>
                        <td>{t('section5-modal-h3/home')}<br/>
                            <p style={{color: "red"}}>
                                * {t('section5-modal-h4/home')}
                            </p>
                        </td>
                        <td><input type="text" placeholder={t('placeholder/input')}/></td>
                    </tr>
                    <tr>
                        <td>{t('section5-modal-h5/home')}
                        </td>
                        <td><input type="text" placeholder={t('placeholder/input')}/></td>
                    </tr>
                    </tbody>
                </table>
            </Modal>
            <Modal
                title=""
                centered
                className="kol-modal"
                open={openKol}
                onOk={() => setOpenKol(false)}
                onCancel={() => setOpenKol(false)}
                width={"90vw"}
                footer={null}
            >
                <AutoCenter className="fw-bold fs-5 text-center">KOLs/KOCs Booking</AutoCenter>
                <AutoCenter className="fs-6 text-center mb-2">{t('section6-modal-h1/home')}</AutoCenter>
                <hr className="mt-4 mb-3"/>
                <table className="table table-striped">
                    <tbody>
                    <tr>
                        <td>{t('section6-modal-h2/home')}</td>
                        <td><input type="text" placeholder={t('placeholder/input')}/></td>
                    </tr>
                    <tr>
                        <td>{t('section6-modal-h3/home')}<br/>
                            <p style={{color: "red"}}>
                                * {t('section6-modal-h4/home')}
                            </p>
                        </td>
                        <td><input type="text" placeholder={t('placeholder/input')}/></td>
                    </tr>
                    <tr>
                        <td>{t('section6-modal-h5/home')}
                        </td>
                        <td><input type="text" placeholder={t('placeholder/input')}/></td>
                    </tr>
                    </tbody>
                </table>
            </Modal>
            <Modal
                title=""
                centered
                className="tmdt-modal"
                open={openTMDT}
                onOk={() => setOpenTMDT(false)}
                onCancel={() => setOpenTMDT(false)}
                width={"90vw"}
                footer={null}
            >
                <AutoCenter className="fw-bold fs-5 text-center mb-2">{t('header/modal7')}</AutoCenter>
                <hr className="mt-4 mb-3"/>
                <table className="table table-striped">
                    <tbody>
                    <tr>
                        <th>{t('section7-modal-h1/home')}</th>
                        <td>
                            <p className="fw-bold">{t('section7-modal-h1-text1/home')}</p>
                            <p>{t('section7-modal-h1-text2/home')}</p>
                        </td>
                        <td>{t('section7-modal-h1-text3/home')}</td>
                    </tr>
                    <tr>
                        <th/>
                        <td>
                            <p className="fw-bold">{t('section7-modal-h1-text4/home')}</p>
                            <p>{t('section7-modal-h1-text5/home')}</p>
                        </td>
                        <td>{t('section7-modal-h1-text6/home')}</td>
                    </tr>
                    <tr>
                        <th colSpan={3}>
                            <hr/>
                        </th>
                    </tr>
                    <tr>
                        <th>{t('section7-modal-h2/home')}</th>
                        <td>
                            <p className="fw-bold">{t('section7-modal-h2-text1/home')}</p>
                            <p>
                                {t('section7-modal-h2-text2/home')}
                            </p>
                        </td>
                        <td>{t('section7-modal-h2-text3/home')}</td>
                    </tr>
                    <tr>
                        <th/>
                        <td>
                            <p className="fw-bold">{t('section7-modal-h2-text4/home')}</p>
                            <p>
                                {t('section7-modal-h2-text5/home')}
                            </p>
                        </td>
                        <td>{t('section7-modal-h2-text6/home')}</td>
                    </tr>

                    <tr>
                        <th/>
                        <td>
                            <p className="fw-bold">{t('section7-modal-h2-text7/home')}</p>
                            <p>
                                {t('section7-modal-h2-text8/home')} <br/>
                                {t('section7-modal-h2-text9/home')}
                            </p>
                        </td>
                        <td>{t('section7-modal-h2-text10/home')}</td>
                    </tr>
                    <tr>
                        <th/>
                        <td>
                            <p className="fw-bold">{t('section7-modal-h2-text11/home')}</p>
                            <p>
                                {t('section7-modal-h2-text12/home')} <br/>
                                {t('section7-modal-h2-text13/home')}
                            </p>
                        </td>
                        <td>{t('section7-modal-h2-text14/home')}</td>
                    </tr>
                    </tbody>
                </table>
            </Modal>
            <Modal
                title=""
                centered
                className="fulfillment-modal"
                open={openFulfillment}
                onOk={() => setOpenFulfillment(false)}
                onCancel={() => setOpenFulfillment(false)}
                width={"90vw"}
                footer={null}
            >
                <AutoCenter className="fw-bold fs-5 text-center mb-2">{t('header/modal9')}</AutoCenter>
                <hr className="mt-4 mb-3"/>
                <table className="table table-striped">
                    <tbody>
                    <tr>
                        <th colSpan={2}/>
                        <td>{t('section9-modal-h1-text1/home')}</td>
                    </tr>
                    <tr>
                        <th>
                            {t('section9-modal-h1-text2/home')} <br/>
                            {t('section9-modal-h1-text3/home')}
                        </th>
                        <td>{t('section9-modal-h1-text4/home')}</td>
                        <td>{t('section9-modal-h1-text5/home')}</td>
                    </tr>
                    <tr>
                        <th/>
                        <td>{t('section9-modal-h1-text6/home')}</td>
                        <td>{t('section9-modal-h1-text7/home')}</td>
                    </tr>
                    <tr>
                        <th colSpan={3}>
                            <hr/>
                        </th>
                    </tr>
                    <tr>
                        <th>{t('section9-modal-h1-text8/home')}</th>
                        <td>{t('section9-modal-h1-text9/home')}</td>
                        <td>{t('section9-modal-h1-text10/home')}</td>
                    </tr>
                    <tr>
                        <th/>
                        <td>{t('section9-modal-h1-text11/home')}</td>
                        <td>{t('section9-modal-h1-text12/home')}</td>
                    </tr>

                    <tr>
                        <th/>
                        <td>{t('section9-modal-h1-text13/home')}</td>
                        <td>{t('section9-modal-h1-text14/home')}</td>
                    </tr>
                    </tbody>
                </table>
            </Modal>
        </>
    );
};

export default React.memo(HomePageView);
