import React from 'react';
import './Navbar.scss';
import {NavBar} from "antd-mobile";

const Navbar = ({title}: any) => {
    const back = () => {
        window.history.go(-1);
    };
    return (
        <>
            <NavBar onBack={back}>{title}</NavBar>
        </>
    )
};

export default React.memo(Navbar);
